import React, { useEffect, useLayoutEffect, useState } from "react";
import { ExclamationCircleFilled } from '@ant-design/icons';
import TrashIcon from "@/assets/images/trash-red-icon.svg";
import EditIcon from "@/assets/images/edit-blue-icon.svg";
import BlueCheckIcon from "@/assets/images/blue-check-icon.svg";
import RedCrossIcon from "@/assets/images/red-x-icon.svg";

import ArchieveIcon from "@/assets/images/archive-icon.svg";
import UnarchieveIcon from "@/assets/images/unarchive-icon.svg";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ApiEndPoint } from "@/services/ApiEndpoint";
import {
  SearchInput,
  Checkbox,
  ExcelDownloadBtn,
  PdfDownloadBtn,
} from "@/components";
import { DatePicker, Modal, Select, Pagination, Empty, Spin } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import { openNotificationWithIcon } from "../../../../services/toast";
import { useDispatch, useSelector } from "react-redux";
import { archiveBuilding, changePage, closeBuildingResourceModal, deleteBuilding, getBuildings, getBuildingsRecord, openBuildingResourceModal, setBuildingsNull, unarchiveBuilding } from "../../../../redux/slice/buildingSlice";
import BuildingModal from "../../../../components/modals/BuildingModal";
import { handleExcelDownload, handlePdfDownload } from "../../../../services/exportHandlers";
import { downloadFile, getFirstPathSegment } from "../../../../services/Utils";
const { confirm } = Modal;
const headers = [
  ["Building ID", "Building Name", "Full Survey", "Demolished", "Certified",
    "Friable", "Description"]
];


function Buildings({ isArchieved }) {
  const location = useLocation()
  const pathName = getFirstPathSegment(location?.pathname)
  // const pathName = location?.pathname?.split('-').at(-1)
  // const category = pathName ? pathName.charAt(0).toUpperCase() + pathName.slice(1) : pathName
  const dispatch = useDispatch()
  const Navigate = useNavigate()
  const { search: locationSearch } = useLocation()
  const { RangePicker } = DatePicker;

  // const [allSelected, setAllSelected] = useState(false);
  const [buildingId, setBuildingId] = useState(null)
  const [search, setSearch] = useState("")
  const [getAll, setGetAll] = useState(locationSearch ? true : false)



  let { currentPage, buildings, isLoading, totalBuildings, totalArchievedBuildings, archievedBuildings, buildingsRecord } = useSelector((state) => state.building)

  const [_buildings, _setBuildings] = useState("")
  const [_totalBuildings, _setTotalBuildings] = useState("")
  const onChangeFrom = (date, dateString) => {
    console.log(date, dateString);
  };

  function handleDelete(id) {
    confirm({
      title: 'Are you sure you want to delete this building?',
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteBuildingFunc(id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  async function handleUpdate(id) {
    Navigate(`/${pathName}/buildings/update/${id}`)
  }

  async function archiveBuildingFunc(id) {

    await dispatch(archiveBuilding(id))
  }
  async function unarchiveBuildingFunc(id, isArchieved) {
    await dispatch(unarchiveBuilding(id, isArchieved))
  }

  const handlePageChange = (page) => {
    dispatch(changePage(page))
  }

  async function deleteBuildingFunc(id) {
    dispatch(deleteBuilding(id, isArchieved))
  }


  function createBuildingData(buildings) {
    console.log(buildings, "bild")
    return buildings?.map(building => ([
      building.BUILDING_ID,
      building.BUILDING_NAME,
      building.has_full_survey ? "Yes" : "No",
      Number(building.DEMOLISHED) ? "Yes" : "No",
      Number(building.CERTIFIED) ? "Yes" : "No",
      building.has_friable_damage ? "Yes" : "No",
      building.DESCRIPTION,
    ]));
  }

  async function handleExcel() {

    const buildings = await dispatch(getBuildingsRecord('all'))

    const dataWithHeaders = [headers, ...createBuildingData(buildings)];
    handleExcelDownload(dataWithHeaders, "Buildings");
  }

  async function handlePdf() {

    const buildings = await dispatch(getBuildingsRecord('all'))

    const data = createBuildingData(buildings);
    handlePdfDownload(headers, data, "Buildings");
  }
  async function handleFile(file) {
    let type = file === 'xlsx' ? 'excel' : 'pdf'
    let url = `v1/buildings/record?type=all&format=${type}`
    downloadFile(file, url, 'Buildings')
  }


  function handleSearchSubmit() {

    if (isArchieved) {
      dispatch(getBuildings("all", 1, search))
    } else {

      dispatch(getBuildings("all", 0, search))
    }
  }
  useEffect(() => {
    if (search === "") {
      if (isArchieved) {
        dispatch(getBuildings("all", 1, search))
      } else {

        dispatch(getBuildings("all", 0, search))
      }
    }
  }, [search]);


  useEffect(() => {

    return () => {
      dispatch(changePage(1));
      dispatch(setBuildingsNull())

    };
  }, []);
  useEffect(() => {
    getBuildingsFunc();
  }, [isArchieved, currentPage]);

  useEffect(() => {
    dispatch(changePage(1));
  }, [isArchieved])

  const getBuildingsFunc = async () => {
    if (isArchieved) {
      dispatch(getBuildings("all", 1))
    } else {
      dispatch(getBuildings("all"))
    }

  }
  useEffect(() => {
    if (isArchieved) {
      _setBuildings(buildings);
      _setTotalBuildings(totalArchievedBuildings);
    } else {
      _setBuildings(buildings);
      _setTotalBuildings(totalBuildings);
    }
  }, [totalArchievedBuildings, buildings, totalBuildings, isArchieved]);
  return (
    <div className="all-buildings">
      <div className="table-wrapper">
        <div className="table-outer-header">
          <div className="header-filters">
            <div className="calender">
              <RangePicker
                // disabled={true}
                style={{ width: 352 }}
                placeholder={["From", "To"]}
                suffixIcon={<i className="fi fi-rr-calendar-lines"></i>}
                separator={<i className="fi fi-rr-calendar-lines"></i>}
                onChange={onChangeFrom}
              />
            </div>
            <Select
              style={{ width: 152 }}
              placeholder="Type"
              disabled={true}
              suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
              // onChange={}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
              ]}
            />
            {/* <Search
              style={{

                width: "400"
              }}
              // icon="end"
              placeholder="Search by building no, name, description..."
              className="user-search-input"
              onPressEnter={(e) => handleSearchSubmit(e.target.value)}
              onSearch={handleSearchSubmit}
            /> */}
            <SearchInput
              width={300}
              icon="end"
              placeholder="Search by building name"
              className="user-search-input"
              onChange={(e) => setSearch(e.target.value)}
              // onClear={handleClearSearch}
              onClick={handleSearchSubmit}
            />

            {/* <SearchInput
                icon="end"
                placeholder="Search by building no, name, description..."
                className="user-search-input"
                onChange={(e)=>setSearch(e?.target?.value)}
                onClick= {handleSearch}
              /> */}

          </div>
          <div className="header-buttons">
            <ExcelDownloadBtn onClick={() => handleFile('xlsx')} />
            <PdfDownloadBtn onClick={() => handleFile('pdf')} />
            {console.log(pathName, "path")}
            <Link
              // to={`/asbestos/buildings/create`}
              to={`/${pathName}/buildings/create`}
              className="button yellow-button"
            >
              + Add New Building
            </Link>
          </div>
        </div>
        <div className="table-overflow-wrapper container p-0">

          <table>
            <thead>
              <tr>

                <th>Building</th>
                <th style={{ width: "278px" }}>Building Name</th>
                <th>Full Survey</th>
                <th>Demolished</th>
                <th>Certified</th>
                <th>Friable</th>
                <th>Description</th>
                {
                  getAll ?
                    <>
                      <th>STRUCTURE NAME</th>
                      <th>YEAR CONSTRUCTED</th>
                      <th>ADDRESS</th>
                      <th>CITY</th>
                      <th>STATE</th>
                      <th>ZIP CODE</th>
                      <th>BUILDING SIZE</th>
                      <th>LEVELS</th>
                    </>
                    : null
                }
                <th>Actions</th>
              </tr>
            </thead>
            {
              isLoading ?
                <Spin delay={20} fullscreen size="large" />
                // <Skeleton count={5} />
                // <Skeleton active style={{ fontSize: '20px', lineHeight: '10' }} />
                :
                <>

                  {_buildings?.length ? _buildings?.map((building) => (
                    <tbody key={building.id}>
                      <tr >

                        <td>{building?.BUILDING_ID}</td>
                        <td className="link" onClick={() => {
                          setBuildingId(building?.id)
                          dispatch(openBuildingResourceModal(Number(building.id)))
                        }}>
                          {/* <td className="link" onClick={() => setModalOpen(true)}> */}
                          {building?.BUILDING_NAME}
                        </td>
                        <td>
                          <img src={building?.has_full_survey ? BlueCheckIcon : RedCrossIcon} alt="" />
                        </td>
                        <td>
                          {/* {building.DEMOLISHED} */}
                          <img src={Number(building?.DEMOLISHED) ? BlueCheckIcon : RedCrossIcon} alt="" />
                        </td>
                        <td>
                          <img src={Number(building?.CERTIFIED) ? BlueCheckIcon : RedCrossIcon} alt="" />
                        </td>
                        <td>
                          <img src={Number(building?.has_friable_damage) ? BlueCheckIcon : RedCrossIcon} alt="" />
                        </td>

                        <td>{building?.DESCRIPTION}</td>
                        {
                          getAll ?
                            <>
                              <td>{building?.STRUCTURE_NAME}</td>
                              <td>{building?.YEAR_CONST}</td>
                              <td>{building?.ADDRESS}</td>
                              <td>{building?.CITY}</td>
                              <td>{building?.STATE}</td>
                              <td>{building?.ZIP}</td>
                              <td>{building?.BUILDING_SIZE}</td>
                              <td>{building?.NO_LEVELS}</td>
                            </>
                            : null
                        }
                        <td>
                          <div className="action-btn">
                            <img className="edit-icon" src={EditIcon} onClick={() => handleUpdate(building.id)} alt="" />
                            {
                              isArchieved &&
                              <img className="trash-icon" onClick={() => handleDelete(building.id)} src={TrashIcon} alt="" />
                            }
                            {
                              !isArchieved && building?.isArchived == 0 ?
                                <img className="archieve-icon" src={ArchieveIcon} onClick={() => archiveBuildingFunc(building.id)} alt="" />
                                :
                                <img className="archieve-icon" src={UnarchieveIcon} onClick={() => unarchiveBuildingFunc(building.id, isArchieved)} alt="" />
                            }
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )) :
                    null}
                </>
            }
          </table>
        </div>
        {
          _buildings?.length < 1 ?

            <div className="empty">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
            : null}
      </div>

      <BuildingModal buildingId={buildingId} />

      {
        !isLoading && _buildings?.length > 0 && _totalBuildings > 10 &&
        <div className="pagination">
          <Pagination defaultCurrent={1} defaultPageSize={10} current={currentPage} total={_totalBuildings} onChange={handlePageChange} />
        </div>
      }
    </div>
  );
}

export default Buildings;

import React from "react";

function HeaderText({ children }) {
  return (
    <div className="header-text">
      <h1>{children}</h1>
    </div>
  );
}

export default HeaderText;

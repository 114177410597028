import React from "react";
import {
  ExcelDownloadBtn,
  GoBack,
  HeaderText,
  MainLayout,
  PdfDownloadBtn,
  SearchInput,
} from "@/components";
import { Select } from "antd";
import "./tables.scss";

function AsbestosTableTwo() {
  return (
    <MainLayout>
      <div className="table-one-wrapper tables-page-wrapper">
        <HeaderText>
          <GoBack />
          Table 2 · <span className="header-light-text">FPK 1166</span>
        </HeaderText>
        <div className="table-wrapper">
          <div className="table-outer-header">
            <div className="header-filters">
              <SearchInput
                width={300}
                icon="start"
                placeholder="Search by building no, name, description..."
                className="user-search-input"
              />
              <Select
                style={{ width: 152 }}
                placeholder="Type"
                suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                // onChange={}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                ]}
              />
            </div>
            <div className="header-buttons">
              <ExcelDownloadBtn />
              <PdfDownloadBtn />
            </div>
          </div>
          <div className="table-overflow-wrapper">
            <table>
              <thead>
                <tr>
                  <th>ASB_ID</th>
                  <th>
                    <div className="d-flex align-items-center gap-1">
                      HGA <i class="fi fi-sr-sort"></i>
                    </div>
                  </th>
                  <th>SAMPLE NUMBER</th>
                  <th>MATERIAL DESCRIPTION</th>
                  <th>layer</th>
                  <th>location of sample</th>
                  <th>% asbestos</th>
                  <th>type of asbestos</th>
                  <th>found in</th>
                  <th>friable</th>
                  <th>removed?</th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4].map((i) => (
                  <tr key={i}>
                    <td>16884</td>
                    <td style={{ width: "250px" }}>
                      FT, 9” F/T, Brown W/White Stripes W/Mastic
                    </td>
                    <td style={{ width: "150px" }}>101-FT-13-1-GA</td>
                    <td style={{ width: "100px" }}>GA</td>
                    <td>1</td>
                    <td style={{ width: "200px" }}>
                      9” F/T, Brown W/White Stripes W/Mastic
                    </td>
                    <td>.00</td>
                    <td style={{ width: "100px" }}>Cellulose</td>
                    <td>--</td>
                    <td>No </td>
                    <td>-- </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default AsbestosTableTwo;

import React from "react";

function SampleCard({ header, children, cardBodyStyle }) {
  return (
    <div className="sample-create-card">
      <div className="card-header">{header}</div>
      <div className={`card-body ${cardBodyStyle}`}>{children}</div>
    </div>
  );
}

export default SampleCard;

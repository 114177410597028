import { createSlice } from "@reduxjs/toolkit";
const globalSlice = createSlice({
  name: "globalVariable",
  initialState: {
    showForgotPassword: false,
    showOTPmodalOpen: false,
    forgotEmail: "",
    path:""
  },
  reducers: {
    setGlobalState: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setGlobalState } = globalSlice.actions;
export default globalSlice.reducer;

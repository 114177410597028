import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom/dist";
import { DatePicker, spin } from 'antd';
import { MainLayout, GoBack, HeaderText, InputText } from "@/components";
import "./all-buildings.scss";
import moment from "moment";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from "antd";
import { openNotificationWithIcon } from "../../../services/toast";
import { useDispatch, useSelector } from "react-redux";
import { addBuilding, addBuildingDetails, closeBuildingResourceModal, defaultBuilding, deleteBuilding, getBuildingData, setErrors, updateBuilding } from "../../../redux/slice/buildingSlice";
import TrashIcon from "@/assets/images/trash-red-icon.svg";
import { buildingValidationObj, checkEmpty, getFirstPathSegment } from "../../../services/Utils";
const { confirm } = Modal;

function CreateAsbestosBuilding() {
  const dispatch = useDispatch()
  const location = useLocation()
  const pathName = getFirstPathSegment(location?.pathname)
  const { buildingDetails, errors } = useSelector((state) => state.building)
  const [buildingId, setBuildingId] = useState(null)
  const [changedData, setChangedData] = useState({})
  const params = useParams()
  const Navigate = useNavigate()
  useEffect(() => {
    if (params.id) {
      dispatch(closeBuildingResourceModal())
      setBuildingId(Number(params.id))
      getBuildingDataFunc(Number(params.id))
    } else {
      dispatch(defaultBuilding())
    }

    return (() => {
      dispatch(setErrors({}, "fullObj"))
      dispatch(defaultBuilding())
    })
  }, [])




  async function getBuildingDataFunc(id) {
    dispatch(getBuildingData(id))
  }


  function handleDelete() {
    confirm({
      title: 'Are you sure you want to delete this building?',
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteBuildingFunc()
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  function deleteBuildingFunc() {
    dispatch(deleteBuilding(buildingId, false))
    Navigate(`/${pathName}/buildings`)
  }

  const handleChange = (e, date) => {


    if (date || date == "") {

      dispatch(addBuildingDetails({ YEAR_CONST: date }))
      setChangedData((prev) => ({
        ...prev,
        YEAR_CONST: date
      }))

      dispatch(setErrors({ YEAR_CONST: null }, "..."))
    }

    else {
      const { name, value } = e.target

      const obj = { [name]: value }
      dispatch(addBuildingDetails(obj))
      setChangedData((prev) => ({
        ...prev,
        [name]: value
      }))

      dispatch(setErrors({ [name]: null }, "..."))
    }
  }

  function validateData(buildingDetails) {
    let errors = {}
    let firstErrorField = null
    // { DESCRIPTION,NO_LEVELS,STATE,YEAR_CONST,BUILDING_ID,CERTIFIED, BUILDING_NAME,ADDRESS,ZIP,DEMOLISHED,STRUCTURE_NAME,BUILDING_SIZE,CITY }

    for (const key in buildingDetails) {
      if (key === 'BUILDING_ID' || key === 'DEMOLISHED' || key === 'CERTIFIED' || key === 'NO_LEVELS' || key === 'BUILDING_SIZE') {
        if (buildingDetails[key] === null || buildingDetails[key] === undefined || buildingDetails[key] === '') {
          errors[key] = `${buildingValidationObj[key]} is required`
          firstErrorField = key
        }
      }
    }

    // setErrors(errors)
    dispatch(setErrors(errors, "fullObj"))
    // return Object.keys(errors).length === 0 ? null : "Validation Error";
    return firstErrorField
  }

  const handleSubmit = async () => {
    const validate = validateData(buildingDetails)

    if (validate) {
      let elementsByName = document.getElementsByName(validate)
      if (elementsByName && elementsByName.length > 0) {
        const firstElement = elementsByName[0]
        firstElement.scrollIntoView({ behavior: "smooth", block: "center" })
      }
      return
    } else {
      try {
        if (!buildingId) {
          dispatch(addBuilding())
          Navigate(`/${pathName}/buildings`)
        } else {


          dispatch(updateBuilding(buildingId, changedData))
          Navigate(`/${pathName}/buildings`)
        }
      } catch (error) {
        openNotificationWithIcon("error", error?.response?.data?.message)
        console.log(error?.response?.data?.message)
      }
    }
  }



  return (
    <MainLayout>
      <div className="create-building-layout">
        <HeaderText>
          <GoBack />{
            buildingId ?
              "Update Building"
              : "Add New Building for Installation FJLC"
          }
        </HeaderText>
        <div className="create-form">
          <form action="">
            <div className="row">
              <div className="col-4">
                <div className="forms-column">
                  <InputText label="BUILDING ID" errors={errors.BUILDING_ID} name="BUILDING_ID" onChange={handleChange} value={buildingDetails.BUILDING_ID} />

                  <InputText label="BUILDING NAME" errors={errors.BUILDING_NAME} name="BUILDING_NAME" onChange={handleChange} value={buildingDetails.BUILDING_NAME} />

                  <InputText label="ADDRESS" name="ADDRESS" errors={errors.ADDRESS} onChange={handleChange} value={buildingDetails.ADDRESS} />

                  <InputText label="ZIP CODE" maxLength='6' name="ZIP" errors={errors.ZIP} onChange={handleChange} value={buildingDetails.ZIP} />

                </div>
              </div>
              <div className="col-4">
                <div className="forms-column">
                  <div>
                    <label className="label">DEMOLISHED</label>

                    <div className="radio-buttons">
                      <div className="radio-button">
                        <input
                          type="radio"
                          name="DEMOLISHED"
                          id="demolished_yes"
                          value={1}
                          defaultChecked={!buildingId || checkEmpty(buildingDetails.DEMOLISHED) ? null : Number(buildingDetails.DEMOLISHED) === 1 ? true : false}
                          // defaultChecked={!buildingId ? null : buildingDetails.DEMOLISHED ? true : false}
                          onChange={handleChange}
                        />
                        <label htmlFor="demolished_yes">Yes</label>
                      </div>
                      <div className="radio-button">
                        <input
                          type="radio"
                          name="DEMOLISHED"
                          id="demolished_no"
                          value={0}
                          defaultChecked={!buildingId || checkEmpty(buildingDetails.DEMOLISHED) ? null : Number(buildingDetails.DEMOLISHED) === 0 ? true : false}
                          onChange={handleChange}
                        />
                        <label htmlFor="demolished_no">No</label>
                      </div>
                    </div>
                    {errors.DEMOLISHED && (
                      <small className="error-message">{errors.DEMOLISHED}</small>
                    )}
                  </div>
                  <InputText name="STRUCTURE_NAME" errors={errors.STRUCTURE_NAME} label="STRUCTURE NAME" onChange={handleChange} value={buildingDetails.STRUCTURE_NAME} />

                  <InputText name="CITY" label="CITY" errors={errors.CITY} onChange={handleChange} value={buildingDetails.CITY} />

                  <InputText name="BUILDING_SIZE" errors={errors.BUILDING_SIZE} min={0} type="number" label="BUILDING SIZE" onChange={handleChange} value={buildingDetails.BUILDING_SIZE} />

                </div>
              </div>
              <div className="col-4">
                <div className="forms-column">
                  <div>
                    <label className="label">CERTIFIED</label>
                    <div className="radio-buttons">
                      <div className="radio-button">
                        <input
                          type="radio"
                          name="CERTIFIED"
                          id="certified_yes"
                          value={1}
                          onChange={handleChange}
                          defaultChecked={!buildingId || checkEmpty(buildingDetails.CERTIFIED) ? null : Number(buildingDetails.CERTIFIED) === 1 ? true : false}
                        />
                        <label htmlFor="certified_yes">Yes</label>
                      </div>
                      <div className="radio-button">
                        <input
                          type="radio"
                          name="CERTIFIED"
                          id="certified_no"
                          value={0}
                          defaultChecked={!buildingId || checkEmpty(buildingDetails.CERTIFIED) ? null : Number(buildingDetails.CERTIFIED) === 0 ? true : false}

                          onChange={handleChange}
                        />
                        <label htmlFor="certified_no">No</label>
                      </div>
                    </div>
                    {errors.CERTIFIED && (
                      <small className="error-message">{errors.CERTIFIED}</small>
                    )}
                  </div>
                  <div>

                    {/* <InputText name="YEAR_CONST" label="YEAR CONSTRUCTED" onChange={handleChange} /> */}
                    <label className="label">YEAR CONSTRUCTED</label>
                    {buildingDetails.YEAR_CONST ?
                      <DatePicker
                        name="YEAR_CONST"
                        value={buildingDetails.YEAR_CONST ? moment(buildingDetails.YEAR_CONST, 'YYYY') : null}
                        onChange={handleChange}
                        picker="year"
                      /> :
                      <DatePicker
                        name="YEAR_CONST"
                        onChange={handleChange}
                        picker="year"
                      />
                    }
                    {errors.YEAR_CONST && (
                      <p className="error-message">{errors.YEAR_CONST}</p>
                    )}
                  </div>
                  <InputText name="STATE" label="STATE" errors={errors.STATE} value={buildingDetails.STATE} onChange={handleChange} />

                  <InputText name="NO_LEVELS" errors={errors.NO_LEVELS} type="number" min={0} label="# LEVELS" value={buildingDetails.NO_LEVELS} onChange={handleChange} />

                </div>
              </div>
              <div className="col-12 description">
                <label htmlFor="description" className="label">
                  DESCRIPTION
                </label>
                <textarea
                  name="DESCRIPTION"
                  id="description"
                  rows="3"
                  value={buildingDetails.DESCRIPTION}
                  placeholder="Text here...."
                  onChange={handleChange}
                ></textarea>
                {errors.DESCRIPTION && (
                  <p className="error-message">{errors.DESCRIPTION}</p>
                )}
              </div>
              <div className="col-12  d-flex align-items-center gap-3 justify-content-center action-btn">
                <button className="button primary-button mt-0" type="button" onClick={handleSubmit}>
                  {!buildingId ?
                    "Save New Building" : "Update Building"}
                </button>
                {buildingId ?
                  <button className="button secondary-button" type="button" onClick={handleDelete}>
                    Delete Building
                  </button>
                  : ""}
              </div>
              {/* <div className="col-10 text-center">          
                  {buildingId?
                <button className="button secondary-button" type="button" onClick={handleDelete}>
                  Delete Building
                </button>
                  :""}
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
}

export default CreateAsbestosBuilding;



import React, { useEffect, useState } from "react";
import { GoBack, MainLayout } from "@/components";
import BlueCheckIcon from "@/assets/images/blue-check-icon.svg";
import RedCrossIcon from "@/assets/images/red-x-icon.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../../services/ApiService";
import {
  getCommon,
  getSampleData,
  uploadSamplePicture,
} from "../../../redux/slice/sampleSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  changePage,
  getResultsBySample,
} from "../../../redux/slice/resultsSlice";
import { Empty, Pagination, Spin, Image, Upload, Modal, Carousel } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./sample.scss";
import { openNotificationWithIcon } from "../../../services/toast";
import { ApiEndPoint } from "../../../services/ApiEndpoint";
import { config } from "../../../config/config";
import { getFirstPathSegment } from "../../../services/Utils";

function AsbestosSampleDetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation()
  const pathName = getFirstPathSegment(location?.pathname)
  const building = location?.state?.building

  const categoryType = [
    { value: pathName == 'asbestos' ? "A" : 'LD', label: pathName == 'asbestos' ? "Asbestos" : 'Lead' },
    { value: "ND", label: "Non-Detect" },
    { value: "LD", label: "Lead" },
    { value: "NF", label: "Non-Fibrous" },
    { value: "OF", label: "Other-Fibrous" }]

  const presentKey = pathName === 'lead' ? 'LEAD_PRESENT' : 'ASB_PRESENT'

  const {
    sampleDetails,
    laboratory,
    ASB_HM_MATERIAL,
    exposed_visible,
    matl_condition,
    equip_type,
    const_type,
    acm_access_dif,
    hazard_assesment,
    air_movement_activity,
    damage_potential,
    functional_space,
  } = useSelector((state) => state.sample);

  const { results, totalResults, currentPage, isLoading } = useSelector(
    (state) => state.result
  );
  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [apiImages, setApiImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const contentStyle = {
    margin: 0,
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCommon("laboratory"));
    dispatch(getCommon("ASB_HM_MATERIAL"));
    dispatch(getCommon("functional_space"));
    dispatch(getCommon("matl_condition"));
    dispatch(getCommon("equip_type"));
    dispatch(getCommon("const_type"));
    dispatch(getCommon("damage_potential"));
    dispatch(getCommon("acm_access_dif"));
    dispatch(getCommon("exposed_visible"));
    dispatch(getCommon("hazard_assesment"));
    dispatch(getCommon("air_movement_activity"));
  }, []);
  useEffect(() => {
    if (params.id) {
      dispatch(getSampleData(Number(params.id)));
      dispatch(getResultsBySample(Number(params.id), pathName));
      getUploadedImages();
    }
  }, [currentPage]);

  function handlePageChange(page) {
    dispatch(changePage(page));
  }

  async function getUploadedImages() {
    let ASB_ID = params?.id;
    const {
      data: { data },
    } = await API.get(ApiEndPoint?.uploadSamplePicture + ASB_ID);
    if (data.length) {
      setApiImages(
        data?.map((item) => ({
          image: item?.DESCRIPTION,
          id: item?.id,
        }))
      );
    }
  }

  async function handleImageUpload(e) {
    let data = new FormData();
    // data.append('images', [...e.target.files])
    for (const file of e.target.files) {
      data.append("images", file);
    }
    data.append("ASB_ID", params?.id);
    data.append("SAMPLE_ID", sampleDetails?.SAMPLE_ID);

    const data2 = await API.post(ApiEndPoint?.uploadSamplePicture, data);
    getUploadedImages();
  }

  async function deleteSamplePicture(id) {
    const data2 = await API.delete(`${ApiEndPoint?.uploadSamplePicture}${id}`);
    getUploadedImages();
  }

  const redirectToResultDetails = (id, resultId) => {
    navigate(`/${pathName}/results/view/${id}`, { state: { resultId, building } });
  };


  

  return (
    <MainLayout>
      <div className="sample-view-wrapper">
        <GoBack />
        <div className="details-wrapper">
          <div className="details">
            <div className="header">
              <div className="title">{building}</div>
              <div className="sample-id">{`Sample: ${sampleDetails?.SAMPLE_ID}`}</div>
            </div>
            <div className="content">
              <div>
                <label>Room Number (Optional)</label>
                <p className="text-mute">{sampleDetails?.RM_ID}</p>
              </div>
              <div>
                <label>Material Type</label>
                <p className="text-mute">
                  {
                    ASB_HM_MATERIAL?.find(
                      (material) => material?.id == sampleDetails?.MATL_ID
                    )?.MATL_DESC
                  }
                </p>
              </div>
              <div>
                <label>Exposed & Visible %</label>
                <p className="text-mute">
                  {
                    exposed_visible?.find(
                      (item) => item?.id == sampleDetails?.EXPOSED
                    )?.description
                  }
                </p>
              </div>
              <div>
                <label>Construction Type</label>
                <p className="text-mute">
                  {
                    const_type?.find(
                      (cons) => cons?.id + "" === sampleDetails?.CONST_TYPE_CD
                    )?.CONST_TYPE_DESC
                  }
                </p>
              </div>
              <div>
                <label>Functional Space (Optional)</label>
                <p className="text-mute">
                  {
                    functional_space?.find(
                      (item) => item?.id == sampleDetails?.FUNCTIONAL_SPACE_CD
                    )?.description
                  }
                </p>
              </div>
              <div>
                <label>Material Condition</label>
                <p className="text-mute">
                  {
                    matl_condition?.find(
                      (material) =>
                        material?.id + "" === sampleDetails?.MATL_COND_CD
                    )?.MATL_COND_DESC
                  }
                </p>
              </div>
              <div>
                <label>Equipment Type</label>
                <p className="text-mute">
                  {
                    equip_type?.find(
                      (eqp) => eqp.id + "" === sampleDetails?.EQUIP_TYPE_CD
                    )?.EQUIP_TYPE_DESC
                  }
                </p>
              </div>
              <div>
                <label>Material Measurement</label>
                <p className="text-mute">
                  {sampleDetails?.MATL_SQ_FT ?? "--"} |{" "}
                  {sampleDetails?.MATL_CUB_YD ?? "--"} |{" "}
                  {sampleDetails?.MATL_LN_FT ?? "--"}
                  {/* {sampleDetails?.MATL_SQ_FT ?? '--'} Square feet 
                <br />
                 {sampleDetails?.MATL_CUB_YD ?? '--'} Cubic Yard
                <br />
                   {sampleDetails?.MATL_LN_FT ?? '--'} Linear feet  */}
                </p>
              </div>
              <div>
                <label>General Occupancy( Optional)</label>
                <p className="text-mute">
                  {sampleDetails?.GEN_OCCUPANCY ?? "--"}
                </p>
              </div>
              <div>
                <label>Suspect Description</label>
                <p className="text-mute">
                  {sampleDetails?.SUSPECT_DSCRPTN ?? "--"}
                </p>
              </div>
              <div>
                <label>Access Difficulty</label>
                <p className="text-mute">
                  {
                    acm_access_dif?.find(
                      (ad) => ad.id == sampleDetails?.ACM_ACCESS_DIF_CD
                    )?.ACM_ACCESS_DIF_DESC
                  }
                </p>
              </div>
              <div>
                <label>Area Removed</label>
                <p className="text-mute">
                  {Number(sampleDetails?.REMOVED) == 1 ? "ABATED" : Number(sampleDetails?.REMOVED) == 0 ? "REMOVED" : null}
                </p>
              </div>
            </div>
          </div>
          <div className="media">
            <div
              className={`image-list ${apiImages?.length === 0 ? "no-image" : apiImages?.length >= 1 && apiImages?.length <= 4 ? "one-image" : ""}`}
            >
              {apiImages?.map((img, index) => (
                <div
                  key={index}
                  className={`image ${index > 7 ? "d-none" : ""}`}
                >
                  <img src={img.image} alt="immg" />
                  <div className={`hover-btns ${index === 7 ? "d-none" : ""}`}>
                    <button
                      onClick={() => {
                        setPreviewImage(img.image);
                        setPreviewOpen(true);
                      }}
                    >
                      <i class="fi fi-rr-eye"></i>
                    </button>
                    <button onClick={() => deleteSamplePicture(img.id)}>
                      <i class="fi fi-rr-trash"></i>
                    </button>
                  </div>
                  {previewImage && (
                    <Image
                      wrapperStyle={{ display: "none" }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                          !visible && setPreviewImage(""),
                      }}
                      src={previewImage}
                    />
                  )}
                  <div
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                    className={`more-images ${index === 7 ? "" : "d-none"}`}
                  >
                    +{apiImages?.length - 7} more images
                  </div>
                </div>
              ))}

              <div className="upload">
                <label htmlFor="upload-pic">
                  <span>+</span>
                  <span>upload</span>
                </label>
                <input
                  type="file"
                  name="upload"
                  id="upload-pic"
                  className="d-none"
                  accept="image/*"
                  multiple
                  onChange={handleImageUpload}
                />
              </div>
            </div>
            <Modal
              open={isModalOpen}
              className="img-carousel-modal"
              onCancel={() => {
                setIsModalOpen(false);
              }}
              footer={null}
            >
              {/* apiImages?.length - 7 */}
              <Carousel arrows={true} dots={false} infinite={false}>
                {apiImages
                  .filter((_, index) => index >= 7)
                  ?.map((img, index) =>
                    index <= 7 ? (
                      <div
                        key={index}
                        style={contentStyle}
                        className="carousel-imgg"
                      >
                        <img src={img.image} alt="imgggg" />
                        <div className="delete-btn">
                          <button onClick={() => deleteSamplePicture(img.id)}>
                            <i class="fi fi-rr-trash"></i>
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )}
              </Carousel>
            </Modal>
            <></>
          </div>
        </div>
        <div className="damage-details-wrapper">
          <div className="damage-details">
            <div className="damage-cause-wrapper">
              <div className="title">Cause Of Damage</div>
              <div className="content">
                <div>
                  <label>Physical Damage</label>
                  <p className="text-mute mb-0">
                    {Number(sampleDetails?.PHY_DAMAGE) == 1 ? "YES" : Number(sampleDetails?.PHY_DAMAGE) == 0 ? "NO" : null}
                  </p>
                </div>
                <div>
                  <label>Water Damage</label>
                  <p className="text-mute mb-0">
                    {Number(sampleDetails?.WATER_DAMAGE) == 1 ? "YES" : Number(sampleDetails?.WATER_DAMAGE) == 0 ? "NO" : null}
                  </p>
                </div>
                <div>
                  <label>Deterioration</label>
                  <p className="text-mute mb-0">
                    {Number(sampleDetails?.DET_DAMAGE) == 1 ? "YES" : Number(sampleDetails?.DET_DAMAGE) == 0 ? "NO" : null}
                  </p>
                </div>
              </div>
            </div>
            <div className="divider"></div>
            <div className="disturbance-factor-wrapper">
              <div className="title">Disturbance Factors</div>
              <div className="content">
                <div>
                  <label>Visible</label>
                  <p className="text-mute mb-0">
                    {Number(sampleDetails?.VISIBLE_DIST) == 1 ? "YES" : Number(sampleDetails?.VISIBLE_DIST) == 0 ? "NO" : null}
                  </p>
                </div>
                <div>
                  <label>Accessible</label>
                  <p className="text-mute mb-0">
                    {Number(sampleDetails?.ACCESS_DIST) == 1 ? "YES" : Number(sampleDetails?.ACCESS_DIST) == 0 ? "NO" : null}
                  </p>
                </div>
                <div>
                  <label>Air Movement</label>
                  {/* <p className="text-mute mb-0">{sampleDetails?.AIR_MOVE}</p> */}
                  <p className="text-mute mb-0">{air_movement_activity?.find(
                    (ad) => ad.id == sampleDetails?.AIR_MOVE
                  )?.description}</p>
                </div>
                <div>
                  <label>Activity</label>
                  <p className="text-mute mb-0">{air_movement_activity?.find(
                    (ad) => ad.id == sampleDetails?.ACTIVITY
                  )?.description}</p>
                </div>
                <div>
                  <label>Friable</label>
                  {console.log(Number(sampleDetails?.FRIABLE), "Number(sampleDetails?.FRIABLE)")}
                  <p className="text-mute mb-0">
                    {Number(sampleDetails?.FRIABLE) == 1 ? "YES" : Number(sampleDetails?.FRIABLE) == 0 ? "NO" : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons">
            <button className="first">
              {
                damage_potential?.find(
                  (item) => item?.id == sampleDetails?.DAMAGE_POTENTIAL
                )?.description
              }
            </button>

            <button className="second">
              {
                hazard_assesment?.find(
                  (item) => item?.id == sampleDetails?.HAZ_ASSES_CD
                )?.HAZ_ASSES_DESC
              }
            </button>
          </div>
        </div>
        <div className="table-wrapper">
          <div className="table-outer-header">
            <h3>Results · {totalResults ?? 0}</h3>
            <div className="header-buttons">
              <Link
                to={`/${pathName}/results/create/${Number(params.id)}?id=${Number(sampleDetails?.BUILDING_ID)}`}
                state={{ SAMPLE_ID: sampleDetails?.SAMPLE_ID, building }}
                className="button yellow-button"
              >
                + Add New Result
              </Link>
            </div>
          </div>
          <div className="table-overflow-wrapper container p-0">
            <table>
              <thead>
                <tr>
                  {[
                    "fpla-id",
                    "SAMPLE",
                    "HOMOGENOUS",
                    "ASBESTOS PRESENT",
                    "LAYER",
                    "TYPE",
                    "ASBESTOS TYPE",
                    "FOUND IN",
                    "AMOUNT",
                    "distinguishing features",
                    "Morphology",
                    "sample preperation",
                  ].map((i) => (
                    <th key={i}>{i}</th>
                  ))}
                </tr>
              </thead>

              {isLoading ? (
                <Spin delay={20} fullscreen size="large" />
              ) : (
                <>
                  {results?.length
                    ? results?.map((result) => (
                      <tbody>
                        <tr
                          key={result?.id}
                          className="link"
                          onClick={() =>
                            redirectToResultDetails(
                              Number(params.id),
                              Number(result?.id)
                            )
                          }
                        >
                          <td style={{ width: "200px" }}>
                            {result?.FPLA_ID ?? "--"}
                          </td>
                          <td style={{ width: "200px" }}>
                            {result?.SAMPLE_ID ?? "--"}
                          </td>
                          <td>
                            <img
                              src={
                                result?.HOMOG ? BlueCheckIcon : RedCrossIcon
                              }
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              src={
                                result[presentKey]
                                  ? BlueCheckIcon
                                  : RedCrossIcon
                              }
                              alt=""
                            />
                          </td>
                          <td>{result?.LAYER_ID ?? "--"}</td>

                          <td>{categoryType?.filter((item) => item?.value == result?.CATEGORY)?.[0]?.label ?? "--"}</td>
                          <td>{result?.MATERIAL}</td>
                          <td>{result?.ASB_MATERIAL ?? "--"}</td>
                          <td>
                            {result?.PERCENT_RANGE ?? "--"}{" "}
                            {result?.PERCENT ? "%" : ""}
                          </td>
                          <td>{result?.DIST_FEATURE ?? "--"}</td>
                          <td>{result?.MORPHOLOGY ?? "--"}</td>
                          <td>{result?.SAMP_PREP ?? "--"}</td>
                        </tr>
                      </tbody>
                    ))
                    : null}
                </>
              )}
            </table>
            {results?.length < 1 ? (
              <div className="empty">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            ) : null}
          </div>
          {!isLoading && results?.length > 0 && totalResults > 10 && (
            <div className="pagination">
              <Pagination
                defaultCurrent={1}
                defaultPageSize={10}
                current={currentPage}
                total={totalResults}
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default AsbestosSampleDetails;

import { createSlice } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../../services/ApiEndpoint";
import API from "../../services/ApiService";
import { useDispatch } from "react-redux";
import { openNotificationWithIcon } from "../../services/toast";

const initialState = {
  surveyDetails: {
    BUILDING_ID: "",
    NO_LEVELS: null,
    BUILDING_SIZE: null,
    DESCRIPTION: "",
    ROOF_TYPE: "",
    EXTERIOR_TYPE: "",
    PLUMBING_TYPE: "",
    STRUCTURE_TYPE: "",
    COMMENTS: "",
    COOL_TYPE: "",
    HEATING_TYPE: "",
    SURVEY_DATE: "",
    INSPECTOR: "",
    FIRE_PROTECTION: "",
    INACCESS_AREA: null,
    INACCESS_AREA_DESC: "",
    INACCESS_AREA_ACM: "",
    INACCESS_AREA_ACM_QTY: "",
    INACCESS_AREA_REASON: "",
    FULL_BLDG_SURVEY: null,
    // flags: null
    flags: 1,
  },
  surveys: [],
  exterior_type: [],
  plumbing_type: [],
  roof_type: [],
  structural_frame: [],
  heating_type: [],
  cooling_type: [],
  inspectors: [],
  acm: [],
  totalSurveys: 0,
  currentPage: 1,
  isLoading: false,
  errors: {},
};

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    addSurveyDetails(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = action.payload[key];

      state.surveyDetails[key] = value;
    },
    getCommon(state, action) {
      state[Object.keys(action.payload).toString()] = Object.values(
        action.payload
      ).flat();

      state.isLoading = false;
    },
    addSurvey(state, action) {
      state.isLoading = false;
    },
    startLoading(state, action) {
      state.isLoading = true;
    },
    changePage(state, action) {
      state.currentPage = action.payload;
    },
    getSurveyData(state, action) {
      state.surveyDetails = action.payload;
      state.isLoading = false;
    },
    stopLoading(state, action) {
      state.isLoading = false;
    },
    getSurveys(state, action) {
      if (state.currentPage == 1) {
        state.totalSurveys = action.payload.totalSurveys;
      }
      state.surveys = action.payload.surveys;
      state.isLoading = false;
    },
    getInspectors(state, action) {
      state.inspectors = action.payload;
      state.isLoading = false;
    },

    defaultSurvey(state, action) {
      state.surveyDetails = {
        BUILDING_ID: "",
        NO_LEVELS: null,
        BUILDING_SIZE: null,
        DESCRIPTION: "",
        ROOF_TYPE: "",
        EXTERIOR_TYPE: "",
        PLUMBING_TYPE: "",
        STRUCTURE_TYPE: "",
        COMMENTS: "",
        COOL_TYPE: "",
        HEATING_TYPE: "",
        SURVEY_DATE: "",
        INSPECTOR: "",
        FIRE_PROTECTION: "",
        INACCESS_AREA: null,
        FULL_BLDG_SURVEY: null,
        // flags: null
        flags: 1,
      };
    },
    setErrors: {
      prepare(obj, msg) {
        return {
          payload: {
            obj,
            msg,
          },
        };
      },
      reducer(state, action) {
        if (action.payload.msg === "fullObj") {
          state.errors = action.payload.obj;
        } else {
          const key = Object.keys(action.payload.obj)[0];
          const value = action.payload.obj[key];
          state.errors[key] = value;
        }
      },
    },
  },
});

export const { addSurveyDetails, defaultSurvey, setErrors, changePage } =
  surveySlice.actions;

export function addSurvey() {
  return async function (dispatch, getState) {
    dispatch({ type: "survey/startLoading" });
    try {
      const surveyDetails = getState().survey.surveyDetails;
      const newSurveyDetails = JSON.parse(JSON.stringify(surveyDetails));
      if (newSurveyDetails.INACCESS_AREA == 0) {
      delete newSurveyDetails.INACCESS_AREA_ACM;
      delete newSurveyDetails.INACCESS_AREA_ACM_QTY;
      delete newSurveyDetails.INACCESS_AREA_DESC;
      delete newSurveyDetails.INACCESS_AREA_REASON;
      }
      if (newSurveyDetails.INSPECTOR === "") {
        delete newSurveyDetails.INSPECTOR;
      }
      newSurveyDetails.INSPECTOR = JSON.stringify(newSurveyDetails.INSPECTOR);

      const response = await API.post(ApiEndPoint.survey, newSurveyDetails);
      openNotificationWithIcon("success", "Survey created successfully");
      dispatch({ type: "survey/addSurvey" });
      dispatch({ type: "survey/defaultSurvey" });
    } catch (error) {
      dispatch({ type: "survey/stopLoading" });
      console.log(error, "error");
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}

export function deleteSurvey(id, buildingId) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: "survey/startLoading" });
      const response = await API.delete(`${ApiEndPoint.survey}/${id}`);
      dispatch(getSurveys(buildingId));
      dispatch({ type: "survey/changePage", payload: 1 });
      openNotificationWithIcon("success", "Survey deleted successfully");
    } catch (error) {
      openNotificationWithIcon("error", "Something went wrong");
    } finally {
      dispatch({ type: "survey/stopLoading" });
    }
  };
}

export function getSurveyData(id) {
  return async function (dispatch, getState) {
    dispatch({ type: "survey/startLoading" });
    dispatch({ type: "survey/defaultSurvey" });
    try {
      const response = await API.get(`${ApiEndPoint.survey}/${id}`);
      //
      // const surveyDetailsKeys = getState().survey.surveyDetails

      //

      const surveyData = response.data.data[0];

      const allowedKeys = Object.keys(initialState.surveyDetails);
      // Filter out the additional fields from surveyData
      const filteredSurveyData = Object.keys(surveyData)
        .filter((key) => allowedKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = surveyData[key];
          return obj;
        }, {});
      if (filteredSurveyData.INSPECTOR) {
        filteredSurveyData.INSPECTOR = JSON.parse(filteredSurveyData.INSPECTOR);
      } else {
        filteredSurveyData.INSPECTOR = [];
      }

      dispatch({ type: "survey/getSurveyData", payload: filteredSurveyData });
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "survey/stopLoading" });
    }
  };
}

export function getSurveys(id, search, docs) {
  return async function (dispatch, getState) {
    dispatch({ type: "survey/startLoading" });
    try {
      const surveyState = getState();
      const currentPage = surveyState.survey.currentPage;
      const response = await API.get(
        `${ApiEndPoint.allSurvey}/${id}?skip=${(currentPage - 1) * 10}&limit=10 ${search ? `&search=${search}` : ""} ${docs ? `&docs=${docs}` : ""}`
      );

      const surveys = response.data.data.listing;

      const totalSurveys = response.data.data.count;

      if (docs) {
        return surveys;
      } else {
        dispatch({
          type: "survey/getSurveys",
          payload: { surveys, totalSurveys },
        });
      }
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "survey/stopLoading" });
    }
  };
}

export function updateSurvey(surveyId, changedData) {
  return async function (dispatch, getState) {
    if (changedData.INSPECTOR) {
      changedData.INSPECTOR = JSON.stringify(changedData.INSPECTOR);
    }
    if (changedData.INACCESS_AREA == 0) {
    delete changedData.INACCESS_AREA_ACM;
    delete changedData.INACCESS_AREA_ACM_QTY;
    delete changedData.INACCESS_AREA_DESC;
    delete changedData.INACCESS_AREA_REASON;
    }
    dispatch({ type: "survey/startLoading" });
    try {
      const response = await API.put(
        `${ApiEndPoint.survey}/${surveyId}`,
        changedData
      );
      openNotificationWithIcon("success", "Survey has been updated");
      // dispatch({type:"building/defaultBuilding"})
    } catch (error) {
      dispatch({ type: "survey/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
      console.log(error?.response?.data?.message);
    }
  };
}

// COMMON

export function getCommon(term) {
  return async function (dispatch, getState) {
    dispatch({ type: "survey/startLoading" });
    try {
      // const response = await API.get(ApiEndPoint.inspector);
      const response = await API.get(
        `${ApiEndPoint.common}?type=${term}&status=active`
      );
      // dispatch({ type: "survey/getInspectors", payload: response?.data?.data });
      dispatch({
        type: "survey/getCommon",
        payload: { [term]: response?.data?.data },
      });
    } catch (error) {
      console.log(error, "lab error");
    }
  };
}

export default surveySlice.reducer;

import React, { useState, useRef } from "react";
import "../assets/css/otpInput.scss";
const OtpInput = ({ length, onOtpChange }) => {
  const [otp, setOtp] = useState(Array(length).fill(""));
  const inputRefs = useRef(Array(length).fill(null));

  const handleInputChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }

    onOtpChange(newOtp.join(""));
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      if (index > 0 && otp[index] === "") {
        inputRefs.current[index - 1].focus();
        handleInputChange(index - 1, "");
      } else if (index === 0) {
        handleInputChange(index, "");
      }
    }
  };

  return (
    <div className="otp-inputs">
      <div className="inputs">
        {otp.map((digit, index) => (
          <input
            key={index}
            type="text"
            value={digit}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            maxLength="1"
            ref={(ref) => (inputRefs.current[index] = ref)}
          />
        ))}
      </div>
    </div>
  );
};
export default OtpInput;

import React from "react";
import { useNavigate } from "react-router-dom";

function GoBack() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <button onClick={handleBack} className="go-back-btn">
      <i className="fi fi-rs-angle-small-left"></i>
      <span>BACK</span>
    </button>
  );
}

export default GoBack;

import { createSlice } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../../services/ApiEndpoint";
import API from "../../services/ApiService";
import { useDispatch } from "react-redux";
import { openNotificationWithIcon } from "../../services/toast";
import { config } from "../../config/config";
const initialState = {
  users: [],
  totalUsers: 0,
  currentPage: 1,
  isLoading: false,
  currentUser: {
    profile_picture: "",
    user_name: "",
    email: "",
    id: null,
    first_name: "",
    last_name: "",
    designation: "",
    location: "",
  },
  userDetails: {
    user_name: "",
    first_name: "",
    last_name: "",
    designation: '',
    location: "",
    password: "",
    email: "",
  },
  designation: [],
  error: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUsers(state, action) {
      if (state.currentPage === 1) {
        state.totalUsers = action.payload.totalUsers;
      }
      state.users = action.payload.users;
      state.isLoading = false;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    changePage(state, action) {
      state.currentPage = action.payload;
      state.isLoading = false;
    },
    deleteUser(state, action) {
      state.currentPage = 1;
    },
    stopLoading(state, action) {
      state.isLoading = false;
    },
    addUser(state, action) {
      state.isLoading = false;
    },
    updateUser(state, action) {
      state.isLoading = false;
    },
    updateCurrentUser(state, action) {
      const { currentUser } = state;
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        if (currentUser.hasOwnProperty(key)) {
          // if (key === "profile_picture") {
          //   currentUser[key] = `${config.API_BASE_URL}uploads/${payload[key]}`;
          // } else {
            currentUser[key] = payload[key];
          // }
        }
      });
    },
    defaultUser(state, action) {
      state.userDetails.email = "";
      state.userDetails.first_name = "";
      state.userDetails.last_name = "";
      state.userDetails.designation = '';
      state.userDetails.location = "";
      state.userDetails.password = "";
      state.userDetails.user_name = "";
      state.error = {};
      state.currentPage = 1;
    },
    addUserDetail(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = action.payload[key];
      state.userDetails[key] = value;
    },
    getCommon(state, action) {
      state[Object.keys(action.payload).toString()] = Object.values(
        action.payload
      ).flat();

      state.isLoading = false;
    },

    setErrors: {
      prepare(obj, msg) {
        return {
          payload: {
            obj,
            msg,
          },
        };
      },
      reducer(state, action) {
        if (action.payload.msg === "fullObj") {
          state.error = action.payload.obj;
        } else {
          const key = Object.keys(action.payload.obj)[0];
          const value = action.payload.obj[key];
          state.error[key] = value;
        }
      },
    },
  },
});

export const {
  startLoading,
  changePage,
  addUserDetail,
  updateCurrentUser,
  defaultUser,
  setErrors,
} = userSlice.actions;

export function getUsers(search) {
  return async function (dispatch, getState) {
    dispatch({ type: "user/startLoading" });
    try {
      const userState = getState();
      const currentPage = userState.user.currentPage;
      const response = await API.get(
        `${ApiEndPoint.users}?skip=${(currentPage - 1) * 10}&limit=10${search ? `&search=${search}` : ""}`
      );
      const users = response?.data?.data?.listing;
      const totalUsers = response?.data?.data?.count;
      dispatch({ type: "user/getUsers", payload: { users, totalUsers } });
    } catch (error) {
      dispatch({ type: "user/stopLoading" });
      openNotificationWithIcon("error", "Failed to fetch users");
    }
  };
}

export function getCommon(term) {
  return async function (dispatch, getState) {
    dispatch({ type: "user/startLoading" });
    try {
      const response = await API.get(
        `${ApiEndPoint.common}?type=${term}&status=active`
      );
      dispatch({
        type: "user/getCommon",
        payload: { [term]: response?.data?.data },
      });
    } catch (error) {
      console.log(error, `${term} error`);
    }
  };
}
export function addUser() {
  return async function (dispatch, getState) {
    try {
      const userDetails = getState().user.userDetails;
      const response = await API.post(ApiEndPoint.signup, userDetails);
      openNotificationWithIcon("success", "User created successfully");
      dispatch({ type: "user/addUser" });
      //   dispatch({ type: "user/getUsers" });
      dispatch({ type: "user/defaultUser" });
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}
export function updateUser(userDetails, id) {
  return async function (dispatch, getState) {
    try {
      const response = await API.put(`${ApiEndPoint.user}/${id}`, userDetails);
      openNotificationWithIcon("success", "User updated successfully");
      dispatch({ type: "user/updateUser" });
      dispatch(getUsers());
      //   dispatch({ type: "user/defaultUser" });
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}

export function deleteUser(id) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: "user/startLoading" });
      const response = await API.delete(`${ApiEndPoint.users}/${id}`);
      dispatch(changePage(1));
      dispatch(getUsers());
    } catch (error) {
      openNotificationWithIcon("error", "Something went wrong");
    }
  };
}

// export function getCurrentUser(){
//   return async function(dispatch,getState){
//     try {

//     } catch (error) {
//       openNotificationWithIcon("error", "Something went wrong");

//     }
//   }
// }

export default userSlice.reducer;

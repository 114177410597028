import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./all-buildings.scss";
import { MainLayout, GoBack } from "@/components";
import { Archieve, Buildings } from "./sub-component";
import { useSelector } from "react-redux";

function AsbestosBuildings() {
  const {totalBuildings,totalArchievedBuildings} = useSelector((state)=>state.building)
  const [isArchieved,setIsArchived] = useState(false)
  const [activeTab,setActiveTab] = useState("all_building")
  
  function handleSelect(k){
    
    setActiveTab(k)
    if(k === "archive"){
      setIsArchived(true);
    }else{
      setIsArchived(false)
    }
  }
  return (
    <>
      <MainLayout buildingBgImage={true}>
        <div className="all-buildings-wrapper">
          <GoBack />
          <Tabs
          activeKey={activeTab}
            defaultActiveKey="all_building"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={handleSelect}
          >
            <Tab eventKey="all_building" title={`All Buildings (${totalBuildings})`}>
              <Buildings />
            </Tab>
            <Tab eventKey="archive" title={`Archive (${totalArchievedBuildings})`} onSelect={()=>setIsArchived(true)}>
              {/* <Archieve /> */}
              <Buildings isArchieved={isArchieved} setIsArchived={setIsArchived}/>
            </Tab>
          </Tabs>
        </div>
      </MainLayout>
    </>
  );
}

export default AsbestosBuildings;

import React, { useEffect, useState } from "react";
import "./survey.scss";
import {
  GoBack,
  HeaderText,
  MainLayout,
  SearchInput,
  ExcelDownloadBtn,
  PdfDownloadBtn,
} from "@/components";
import { ExclamationCircleFilled } from "@ant-design/icons";

import TrashIcon from "@/assets/images/trash-red-icon.svg";
import EditIcon from "@/assets/images/edit-blue-icon.svg";
import BlueCheckIcon from "@/assets/images/blue-check-icon.svg";
import RedCrossIcon from "@/assets/images/red-x-icon.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Empty, Pagination, Select, Spin, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  changePage,
  deleteSurvey,
  getSurveys,
} from "../../../redux/slice/surveySlice";
import { handleExcelDownload, handlePdfDownload } from "../../../services/exportHandlers";
import { downloadFile, getFirstPathSegment } from "../../../services/Utils";
import { ApiEndPoint } from "../../../services/ApiEndpoint";

function AsbestosSurveys() {
  const location = useLocation();
  const pathName = getFirstPathSegment(location?.pathname)
  const { confirm } = Modal;
  const params = useParams();
  const Navigate = useNavigate();
  const [search, setSearch] = useState("")
  const { surveys, totalSurveys, isLoading, currentPage } = useSelector(
    (state) => state.survey
  );

  const searchParams = new URLSearchParams(location.search);
  const buildingId = searchParams.get("id");
  const building = location?.state?.building;
  const dispatch = useDispatch();
  useEffect(() => {
    getSurveysFunc();
  }, [currentPage]);

  useEffect(() => {
    return () => {
      dispatch(changePage(1));
    };
  }, []);
  function getSurveysFunc() {
    dispatch(getSurveys(buildingId));
  }
  function handlePageChange(page) {
    dispatch(changePage(page));
  }


  function handleUpdate(id) {
    Navigate(`/${pathName}/survey/update/${id}?id=${buildingId}`, {
      state: {
        buildingId,
        building,
      },
    });
  }
  function handleDelete(id) {
    confirm({
      title: "Are you sure you want to delete this Survey?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteSurveyFunc(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }
  function deleteSurveyFunc(id) {
    dispatch(deleteSurvey(id, buildingId));
  }

  function handleSearchSubmit() {
    dispatch(getSurveys(buildingId, search));

  }
  // async function handleExcel() {
  //   const surveysRecord = await dispatch(getSurveys(buildingId, null, 1));
  //   const headers = [
  //     "Building ID", "Full Insp", "Levels", "Size", "Description", "Roof Type", "Exterior Type",
  //     "Interior Type", "Plumbing Type", "Structure Type", "Electric Type",
  //     "Heating Type", "Cool Type", "Instln ID"
  //   ];

  //   const dataWithHeaders = [
  //     headers,
  //     ...surveysRecord?.map(survey => [
  //       survey.BUILDING_ID,
  //       survey.FULL_BLDG_SURVEY ? "Yes" : "No",
  //       survey.NO_LEVELS,
  //       survey.BUILDING_SIZE,
  //       survey.DESCRIPTION,
  //       survey.ROOF_TYPE,
  //       survey.EXTERIOR_TYPE,
  //       survey.INTERIORS_TYPE,
  //       survey.PLUMBING_TYPE,
  //       survey.STRUCTURE_TYPE,
  //       survey.ELEC_TYPE,
  //       survey.HEATING_TYPE,
  //       survey.COOL_TYPE,
  //       survey.INSTLN_ID,
  //     ])
  //   ];

  //   handleExcelDownload(dataWithHeaders, "Surveys")
  // }

  useEffect(() => {
    if (search === "") {
      dispatch(getSurveys(buildingId));
    }
  }, [search]);

  // async function handlePdf() {
  //   const surveysRecord = await dispatch(getSurveys(buildingId, null, 1));
  //   const headers = [["Building ID", "Full Insp", "Levels", "Size", "Description", "Roof Type", "Exterior Type", "Interior Type", "Plumbing Type", "Structure Type", "Electric Type", "Heating Type", "Cool Type", "Instln ID"]];
  //   const data = surveysRecord?.map(survey => ([
  //     survey.BUILDING_ID,
  //     survey.FULL_BLDG_SURVEY ? "Yes" : "No",
  //     survey.NO_LEVELS,
  //     survey.BUILDING_SIZE,
  //     survey.DESCRIPTION,
  //     survey.ROOF_TYPE,
  //     survey.EXTERIOR_TYPE,
  //     survey.INTERIORS_TYPE,
  //     survey.PLUMBING_TYPE,
  //     survey.STRUCTURE_TYPE,
  //     survey.ELEC_TYPE,
  //     survey.HEATING_TYPE,
  //     survey.COOL_TYPE,
  //     survey.INSTLN_ID,
  //   ]));
  //   handlePdfDownload(headers, data, "Survey")
  // }
  async function handleFile(file) {
    let type = file === 'xlsx' ? 'excel' : 'pdf'
    let url = `v1/building/survey/${buildingId}?format=${type}`
    downloadFile(file, url, 'surveys')
  }


  return (
    <MainLayout>
      <div className="survey-wrapper">
        <HeaderText>
          <GoBack />
          Surveys ({totalSurveys}) ·{" "}
          <span className="sample-title">{building}</span>
        </HeaderText>
        <div className="table-wrapper">
          <div className="table-outer-header">
            <div className="header-filters">
              <SearchInput
                width={400}
                icon="end"
                placeholder="Search by survey id"
                className="user-search-input"
                onChange={(e) => setSearch(e.target.value)}
                onClick={handleSearchSubmit}
              />
              <Select
                style={{ width: 170 }}
                placeholder="Material Condition"
                suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                // onChange={}
                disabled
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                ]}
              />
            </div>
            <div className="header-buttons">
              <ExcelDownloadBtn onClick={() => handleFile('xlsx')} />
              <PdfDownloadBtn onClick={() => handleFile('pdf')} />

              <Link
                to={`/${pathName}/survey/create?id=${buildingId}`}
                className="button yellow-button"
                state={{ buildingId, building }}
              >
                + New Survey
              </Link>
            </div>
          </div>
          <div className="table-overflow-wrapper container p-0">
            <table id="survey-table">
              <thead>
                <tr>
                  {[
                    "Full insp",
                    "LEVELS",
                    "size",
                    "desc",
                    "ROOF TYPE",
                    "EXTERIOR TYPE",
                    // "interior TYPE",
                    "plumbing TYPE",
                    "structure TYPE",
                    // "electric type",
                    "heating type",
                    "cool type",
                    "INSTLN_ID",
                    "BUILDING_ID",
                    "Actions",
                  ].map((i) => (
                    <th key={i}>{i}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <Spin fullscreen size="large" />
                ) : (
                  <>
                    {surveys.length
                      ? surveys.map((survey) => (
                        <tr key={survey.id}>
                          <td>
                            {
                              <img
                                src={
                                  Number(survey?.FULL_BLDG_SURVEY)
                                    ? BlueCheckIcon
                                    : RedCrossIcon
                                }
                                alt=""
                              />
                            }
                          </td>
                          <td>{survey.NO_LEVELS}</td>
                          <td>{survey.BUILDING_SIZE}</td>
                          <td>{survey.DESCRIPTION}</td>
                          <td>{survey.ROOF_TYPE}</td>
                          <td>{survey.EXTERIOR_TYPE}</td>
                          {/* <td>{survey.INTERIORS_TYPE}</td> */}
                          <td>{survey.PLUMBING_TYPE}</td>
                          <td>{survey.STRUCTURE_TYPE}</td>
                          {/* <td>{survey.ELEC_TYPE}</td> */}
                          <td>{survey.HEATING_TYPE}</td>
                          <td>{survey.COOL_TYPE}</td>
                          <td>{survey.INSTLN_ID ?? 'FJLC'}</td>
                          <td>{survey.BUILDING_ID}</td>
                          <td>
                            <div className="action-btn">
                              <img
                                className="edit-icon"
                                src={EditIcon}
                                onClick={() => handleUpdate(survey.id)}
                                alt=""
                              />
                              <img
                                className="trash-icon"
                                onClick={() => handleDelete(survey.id)}
                                src={TrashIcon}
                                alt=""
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                      : null}
                  </>
                )}
              </tbody>
            </table>
            {surveys?.length < 1 ? (
              <div className="empty">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            ) : null}
          </div>
          {!isLoading && surveys.length > 0 && totalSurveys > 10 && (
            <div className="pagination">
              <Pagination
                defaultCurrent={1}
                total={totalSurveys}
                current={currentPage}
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default AsbestosSurveys;

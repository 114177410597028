import React, { useEffect, useRef, useState } from "react";
import {
  HeaderText,
  GoBack,
  MainLayout,
  SearchInput,
  ExcelDownloadBtn,
  PdfDownloadBtn,
} from "@/components";
import "./sample.scss";
import BlueCheckIcon from "@/assets/images/blue-check-icon.svg";
import RedCrossIcon from "@/assets/images/red-x-icon.svg";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Empty, Modal, Pagination, Select, Spin } from "antd";
import TrashIcon from "@/assets/images/trash-red-icon.svg";
import EditIcon from "@/assets/images/edit-blue-icon.svg";
import { changePage, deleteSample, getCommon, getSamples, getSamplesRecord } from "../../../redux/slice/sampleSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleExcelDownload, handlePdfDownload } from "../../../services/exportHandlers";
import { InputText } from "../../../components";
import { openNotificationWithIcon } from "../../../services/toast";
import { downloadFile, getFirstPathSegment } from "../../../services/Utils";

function AsbestosSamples() {

  const { confirm } = Modal;
  const deleteReasonRef = useRef("");
  const Navigate = useNavigate()
  const location = useLocation()
  const pathName = getFirstPathSegment(location?.pathname)

  const { samples, totalSamples, isLoading, currentPage, exposed_visible } = useSelector((state) => state.sample)
  const searchParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState("")
  const buildingId = searchParams.get('id');
  const building = location?.state?.building
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCommon('exposed_visible'))
  }, [])

  useEffect(() => {
    getSamplesFunc()
  }, [currentPage])


  useEffect(() => {
    return () => {

      dispatch(changePage(1));
    };
  }, []);
  function getSamplesFunc() {
    dispatch(getSamples(buildingId))
  }
  function handlePageChange(page) {
    dispatch(changePage(page))
  }

  function handleUpdate(id) {
    Navigate(`/${pathName}/samples/update/${id}?id=${buildingId}`,
      {
        state: {
          building
        }
      })
  }


  function handleDelete(id) {
    confirm({
      title: 'Are you sure you want to delete this Sample?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <p>Please provide a reason for deletion:</p>
          <InputText onChange={(e) => deleteReasonRef.current = e?.target?.value} />
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        if (deleteReasonRef?.current === '') {
          openNotificationWithIcon("error", 'Kindly provide reason for deletion');
        } else {
          dispatch(deleteSample(id, deleteReasonRef?.current, buildingId))
          deleteReasonRef.current = ""
        }
      },
      onCancel() {
        deleteReasonRef.current = ""
      },
    });
  }



  // async function handleExcel() {
  //   const samples = await dispatch(getSamplesRecord(buildingId))
  //   const headers = [
  //     "Sample", "Building", "Material Condition", "FRIABLE", "Exposed & visible%",
  //     "Material", "ROOM", "FLOOR", "LAB ID", "Sample DESC", "Hazardous material desc"
  //   ];

  //   const dataWithHeaders = [
  //     headers,
  //     ...samples.map(sample => [
  //       sample.SAMPLE_ID,
  //       sample.BUILDING_ID,
  //       sample.MATL_COND_DESC,
  //       Number(sample.FRIABLE) ? "Yes" : "No",
  //       sample.EXPOSED,
  //       sample.MATL_DESC,
  //       sample.RM_ID,
  //       sample.FLOOR_ID,
  //       sample.LBTRY_ID,
  //       sample.COMMENTS,
  //       sample.HAZ_ASSES_CD
  //     ])
  //   ];

  //   handleExcelDownload(dataWithHeaders, "AsbestosSamples");
  // }
  // async function handlePdf() {
  //   const samples = await dispatch(getSamplesRecord(buildingId))
  //   const headers = [[
  //     "Sample", "Building", "Material Condition", "FRIABLE", "Exposed & visible%",
  //     "Material", "ROOM", "FLOOR", "LAB ID", "Sample DESC", "Hazardous material desc"
  //   ]];

  //   const data = samples?.map(sample => ([
  //     sample.SAMPLE_ID,
  //     sample.BUILDING_ID,
  //     sample.MATL_COND_DESC,
  //     Number(sample.FRIABLE) ? "Yes" : "No",
  //     sample.EXPOSED,
  //     sample.MATL_DESC,
  //     sample.RM_ID,
  //     sample.FLOOR_ID,
  //     sample.LBTRY_ID,
  //     sample.COMMENTS,
  //     sample.HAZ_ASSES_CD
  //   ]));

  //   handlePdfDownload(headers, data, "AsbestosSamples");
  // }


  async function handleFile(file) {
    let type = file === 'xlsx' ? 'excel' : 'pdf'
    let url = `v1/building/sample/${buildingId}/record?format=${type}`
    downloadFile(file, url, 'samples')
  }

  function handleSearchSubmit() {
    dispatch(getSamples(buildingId, search))

  }
  useEffect(() => {
    if (search === "") {
      dispatch(getSamples(buildingId, search))
    }
  }, [search]);

  return (

    <MainLayout>
      <div className="samples-wrapper">
        <HeaderText>
          <GoBack />
          Samples ({totalSamples}) · <span className="sample-title">{building}</span>
        </HeaderText>
        <div className="table-wrapper">
          <div className="table-outer-header">
            <div className="header-filters">
              <SearchInput
                width={400}
                icon="end"
                placeholder="Search by sample name"
                className="user-search-input"
                onChange={(e) => setSearch(e.target.value)}
                onClick={handleSearchSubmit}
              />
              <Select
                style={{ width: 170 }}
                placeholder="Material Condition"
                suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                // onChange={}
                disabled={true}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                ]}
              />
            </div>
            <div className="header-buttons">
              <ExcelDownloadBtn onClick={() => handleFile('xlsx')} />
              <PdfDownloadBtn onClick={() => handleFile('pdf')} />
              {console.log(buildingId, "building")}
              <Link
                to={`/${pathName}/samples/create?id=${buildingId}`}
                className="button yellow-button"
                state={{ buildingId, building }}
              >
                + New Sample
              </Link>
            </div>
          </div>
          <div className="table-overflow-wrapper container p-0">
            <table>
              <thead>
                <tr>
                  {[
                    "Sample",
                    "Building",
                    "Material Condition",
                    "FRIABLE",
                    "Exposed & visible%",
                    "Material",
                    "ROOM",
                    "FLOOR",
                    "LAB ID",
                    "sAMPLE DESC",
                    "Hazardous material desc",
                    "Actions"
                  ].map((i) => (
                    <th key={i}>{i}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ?
                    <Spin fullscreen size="large" />
                    :
                    <>
                      {
                        samples.length ? samples.map((sample) => (
                          <tr key={sample?.id}>
                            <td style={{ width: "200px" }}>{sample?.SAMPLE_ID}</td>
                            <td style={{ width: "70px" }} className="link">
                              <Link to={`/${pathName}/samples/view/${sample?.id}`} state={{ buildingId, building }}>{sample?.BUILDING_ID}</Link>
                            </td>
                            <td style={{ width: "70px" }}>{sample?.MATL_COND_DESC}</td>
                            <td style={{ width: "70px" }}><img src={Number(sample?.FRIABLE) ? BlueCheckIcon : RedCrossIcon} alt="" /></td>
                            {/* <td style={{ width: "120px" }}>{sample?.EXPOSED}</td> */}
                            <td style={{ width: "120px" }}>{exposed_visible?.find((cond) => cond?.id === Number(sample?.EXPOSED))?.description}</td>
                            <td>{sample?.MATL_DESC}</td>
                            <td>{sample?.RM_ID}</td>
                            <td>{sample?.FLOOR_ID}</td>
                            <td>{sample?.LBTRY_ID}</td>
                            <td style={{ width: "300px" }}>
                              {sample?.COMMENTS}
                            </td>
                            <td style={{ width: "300px" }}>
                              {sample?.HAZ_ASSES_DESC}
                            </td>
                            <td>
                              <div className="action-btn">
                                <img className="edit-icon" src={EditIcon}
                                  onClick={() => handleUpdate(sample.id)}
                                  alt="" />
                                <img className="trash-icon"
                                  onClick={() => handleDelete(sample.id)}
                                  src={TrashIcon} alt="" />
                              </div>
                            </td>
                          </tr>
                        ))
                          : null
                      }
                    </>
                }
              </tbody>
            </table>
            {samples?.length < 1 ? (
              <div className="empty">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            ) : null}
          </div>
          {
            !isLoading && samples.length > 0 && totalSamples > 10 && (
              <div className="pagination">
                <Pagination defaultCurrent={1} total={totalSamples} current={currentPage} onChange={handlePageChange} />
              </div>
            )}

        </div>
      </div>
    </MainLayout>
  );
}

export default AsbestosSamples;

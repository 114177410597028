import React from "react";
import PDFIcon from "@/assets/images/pdf-file-icon.png";
function PdfDownloadBtn({onClick}) {
  
  function handleClick(){
    if (typeof onClick === "function") {
      onClick();
  }
}
  return (
    <button className="pdf-icon" onClick={handleClick}>
      <img src={PDFIcon} alt="" />
    </button>
  );
}

export default PdfDownloadBtn;

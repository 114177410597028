import React from "react";
import "./lab-report.scss";
import {
  GoBack,
  HeaderText,
  MainLayout,
  SearchInput,
  ExcelDownloadBtn,
  PdfDownloadBtn,
} from "@/components";

import { Select } from "antd";

function AsbestosLabReport() {
  return (
    <MainLayout>
      <div className="lab-report-wrapper">
        <HeaderText>
          <GoBack />
          Lab Report (93) · <span className="sample-title">FPK 1166</span>
        </HeaderText>
        <div className="table-wrapper">
          <div className="table-outer-header">
            <div className="header-filters">
              <SearchInput
                width={400}
                icon="start"
                placeholder="Search by building no, name, description..."
                className="user-search-input"
              />
              <Select
                style={{ width: 170 }}
                placeholder="Material Condition"
                suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                // onChange={}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                ]}
              />
            </div>
            <div className="header-buttons">
              <ExcelDownloadBtn />
              <PdfDownloadBtn />
            </div>
          </div>
          <div className="table-overflow-wrapper container p-0">
            <table>
              <thead>
                <tr>
                  {[
                    "SAMPLE FPLA ID",
                    "layer",
                    "HOMOGENEOUS",
                    "Morphology",
                    "color",
                    "ASBESTOS",
                    "ASBESTOS TYPE",
                    "abs%",
                    "Disp. colors",
                    "Pleochroism",
                    "BIOF",
                    "EXT. ANGLE",
                    "ELONGATION",
                    "REFR INDEX",
                    "non-asb fibers",
                  ].map((i) => (
                    <th key={i}>{i}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4].map((i) => (
                  <tr key={i}>
                    <td>FPLA015576</td>
                    <td>1</td>
                    <td>Yes</td>
                    <td>No Fibers</td>
                    <td>Yellow</td>
                    <td>ND</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default AsbestosLabReport;

import { Modal, Spin } from 'antd'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import DocsIcon from "@/assets/images/docs-grey-icon.svg";
import FPKIcon from "@/assets/images/fpk-grey-icon.svg";
import MapIcon from "@/assets/images/map-grey-icon.svg";
import { Link } from 'react-router-dom/dist';
import CustomModel from './customModal';

export default function InventoryModal({ openModal, setInventoryModal }) {
    const [customModalOpen, setCustomModalOpen] = useState(false)
    const [modalData, setModalData] = useState('')
    return (
        <>

            {/* {
        isLoading && buildingResourcesModal ?
        
      <Spin delay={20} fullscreen size="large" />
      // <Skeleton count={5} />
      // <Skeleton active style={{ fontSize: '20px', lineHeight: '10' }} />
      :""} */}

            {

                // !isLoading && buildingResourcesModal ?

                <Modal
                    width={1340}
                    className="update-building-modal"
                    centered
                    open={openModal}
                    title="header"
                    onCancel={() => {
                        setInventoryModal(false)
                    }
                    }
                    footer={false}
                >
                    <div className="upper-header">
                        <CustomModel openCustomModal={customModalOpen} setCustomModalOpen={setCustomModalOpen} modalData={modalData} />
                        {/* ID */}
                        {/* <div className="title">{`${buildingDetails?.BUILDING_ID} Building Details`}</div> */}


                        {/* <Link to={`/asbestos/buildings/update/${buildingDetails?.id}`} className="link">Update Building</Link> */}
                    </div>
                    <div className="lower-header">
                        {/* <h3 className="title">{buildingDetails?.BUILDING_NAME}</h3> */}
                        <div className="details">
                            <div className="details-item">
                                {/* <img src={MapIcon} alt="" /> */}
                                {/* <span>{`${buildingDetails?.ADDRESS} ${buildingDetails?.CITY} `}</span> */}
                            </div>
                            <div className="details-item">
                                {/* <img src={DocsIcon} alt="" /> */}
                                {/* <span>{`${buildingDetails?.STATE} ${buildingDetails?.ZIP}`}</span> */}
                            </div>
                            <div className="details-item">
                                {/* <img src={FPKIcon} alt="" /> */}
                                {/* <span>{buildingDetails?.STRUCTURE_NAME}</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="body-content">
                        <div className="button-rows">
                            <label htmlFor="" className="label">
                                Inventory Information
                            </label>
                            <div className="buttons inventory-modal">
                                {[
                                    { name: `INSPECTORS`, key: 'inspectors', fields: ['id', 'INSP_ID', 'INSPECTOR', 'PROJ_ID', 'TEAM_ID', "is_active"] },
                                    { name: `LABORATORY`, key: 'laboratory', fields: ['id', 'LBTRY_ID', 'LBTRY_NAME', 'CONTACT', 'CONTACT_PHONE', 'LOCATION'] },
                                    { name: `ACM`, key: 'acm', fields: ['id', 'description','active'] },
                                    { name: `SAMPLE CONDITION`, key: 'sample_condition', fields: ['id', 'description', 'active'] },
                                    { name: `UNCOMMON TEXTURE_COLOR`, key: 'uncommon_texture_color', fields: ['id', 'description', 'active'] },
                                    { name: `TEXTURE COLOR`, key: 'texture_color', fields: ['id', 'description', 'active'] },
                                    { name: `EQUIPMENT TYPE`, key: 'equip_type', fields: ['id', 'EQUIP_TYPE_DESC', 'EQUIP_TYPE_CD'] },
                                    { name: `CONSTRUCTION TYPE`, key: 'const_type', fields: ['id', 'CONST_TYPE_DESC', 'CONST_TYPE_CD'] },
                                    { name: `HAZARD ASSESMENT`, key: 'hazard_assesment', fields: ['id', 'HAZ_ASSES_DESC', 'HAZ_ASSES_CD'] },
                                    { name: `STRUCTURE FRAME`, key: 'structural_frame', fields: ['id', 'description', 'active'] },
                                    { name: `EXTERIOR TYPE`, key: 'exterior_type', fields: ['id', 'description', 'active'] },
                                    { name: `ROOF TYPE`, key: 'roof_type', fields: ['id', 'description', 'active'] },
                                    { name: `HEATING TYPE`, key: 'heating_type', fields: ['id', 'description', 'active'] },
                                    { name: `COOLING TYPE`, key: 'cooling_type', fields: ['id', 'description', 'active'] },
                                    { name: `PLUMBING TYPE`, key: 'plumbing_type', fields: ['id', 'description', 'active'] },
                                    { name: `MATERIAL CONDITION`, key: 'matl_condition', fields: ['id', 'MATL_COND_DESC', 'MATL_COND_CD'] },
                                    { name: `ACCESS DIFFICUILTY`, key: 'acm_access_dif', fields: ['id', 'ACM_ACCESS_DIF_DESC', 'ACM_ACCESS_DIF_CD'] },
                                    { name: `ASB ONM PLAN`, key: 'asb_onm_plan', fields: ['id', 'ASSESS_LIST_DESC', 'ASSESS_LIST_ID'] },
                                    { name: `EXPOSED AND VISIBLE %`, key: 'exposed_visible', fields: ['id', 'description', 'active'] },
                                    { name: `FUNCTIONAL SPACE`, key: 'functional_space', fields: ['id', 'description', 'active'] },
                                    { name: `DAMAGE POTENTIAL`, key: 'damage_potential', fields: ['id', 'description', 'active'] },
                                    { name: `SUSPECT TYPE`, key: 'suspect_type', fields: ['id', 'description', 'active'] },
                                    { name: `MATERIAL TYPE`, key: 'material_type', fields: ['id', 'description', 'active'] },
                                    { name: `AIR MOVEMNT & ACTIVITY`, key: 'air_movement_activity', fields: ['id', 'description', 'active'] },
                                    { name: `TECH ID`, key: 'tech_id', fields: ['id', 'description', 'active'] },
                                    { name: `NON-FIBROUS`, key: 'non_fibrous_options', fields: ['id', 'description', 'active'] },
                                    { name: `OTHER-FIBROUS`, key: 'other_fibrous_options', fields: ['id', 'description', 'active'] },
                                    { name: `ASBESTOS-TYPE`, key: 'asbestos_type_options', fields: ['id', 'description', 'active'] },
                                    { name: `RESULT-TYPE`, key: 'result_type', fields: ['id', 'description', 'active'] },
                                    { name: `BIOFRINGENCE`, key: 'biofringence', fields: ['id', 'description', 'active'] },
                                    { name: `ELONGATION`, key: 'elongation', fields: ['id', 'description', 'active'] },
                                    { name: `EXTINCTION-ANGLE`, key: 'ext_angle', fields: ['id', 'description', 'active'] },
                                    { name: `INSTALLATION`, key: 'installation', fields: ['id', 'description',] },
                                    { name: `DESIGNATION`, key: 'designation', fields: ['id', 'description', 'active'] },

                                    // { name: `Samples · 0`, src: `/asbestos/samples` },
                                    // { name: `Results . 0`, src: `/asbestos/}` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // { name: `Physical Condition Assesments · 0`, src: `/asbestos/` },
                                    // {
                                    //   name: `Physical Condition Assesments · ${buildingDataCount.assessmentCount || 0}`,
                                    //   src: `/asbestos/assessment?id=${defaultBuildingId}`,
                                    //   state:{buildingId,building:buildingDetails.BUILDING_ID}
                                    // },
                                ].map((i, index) => (
                                    <Link key={index} onClick={() => {
                                        setModalData(i)
                                        setCustomModalOpen(true)
                                    }} state={i.state} >
                                        {i.name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                        {/* <div className="button-rows my-4">
                            <label htmlFor="" className="label">
                                Tables & Reports
                            </label>
                            <div className="buttons">
                                {[
                                    { name: "View Table 1", src: "/asbestos/table-one/FPK-1166" },
                                    { name: "View Table 2", src: "/asbestos/table-two/FPK-1166" },
                                    { name: "View Table 3", src: "/asbestos/table-three/FPK-1166" },
                                    {
                                        name: "View Lab Report",
                                        src: "/asbestos/lab-report/FPK-1166",
                                    },
                                    { name: "Download Notice to Occupants", src: "" },
                                ].map((i) => (
                                    <Link key={i.name} to={i.src}>
                                        {i.name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                        <div className="button-rows">
                            <label htmlFor="" className="label">
                                Attachments
                            </label>
                            <div className="buttons">
                                {[
                                    {
                                        name: "Asbestos Attachment · 0",
                                        src: "/asbestos/asbestos-attachments/:id",
                                    },

                                    {
                                        name: "Mold Attachment · 0",
                                        src: "",
                                    },
                                    {
                                        name: "REC Attachment · 0",
                                        src: "/asbestos/rec-attachments/:id",
                                    },
                                    {
                                        name: "Asbestos Disposal Verfication Form · 0",
                                        src: "/asbestos/advf-attachments/:id",
                                    },
                                ].map((i) => (
                                    <Link key={i.name} to={i.src}>
                                        {i.name}
                                    </Link>
                                ))}
                            </div>
                        </div> */}
                    </div>
                </Modal>
                // : ""
            }
        </>
    )
}

import React from 'react';
import { Modal, notification } from 'antd';



export const openNotificationWithIcon = (type = "error", message = "error") => {
  notification[type]({
    message: message,
  });
};

export const warning = (title, content = "") => {
  Modal.warning({
    title,
    content,
  });
};
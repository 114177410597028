import React, { useEffect, useMemo, useRef, useState } from "react";
import { MainLayout, HeaderText, GoBack, CheckIcon } from "@/components";
import "./result.scss";
import DetailsCard from "./subComponent/detailsCard";
import { ExclamationCircleFilled } from "@ant-design/icons";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { checkEmpty, getFirstPathSegment } from "../../../services/Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultResultsBySample,
  deleteResult,
  getCommon,
  getHistoryByResult,
  getResultData,
  getResultsBySample,
} from "../../../redux/slice/resultsSlice";
import { CrossIcon } from "../../../components";
import { Empty, Modal, Spin } from "antd";
import { InputText } from "@/components";
import { openNotificationWithIcon } from "../../../services/toast";
import TrashIcon from "@/assets/images/trash-red-icon.svg";
import EditIcon from "@/assets/images/edit-blue-icon.svg";
const { confirm } = Modal;
const tHeadOptions = [
  "Rev",
  "Result Date",
  "Entered By",
  "FPLA_ID",
  "Homogenous",
  "Asbestos Present",
  "Layer",
  "Type",
  "Asbestos Type",
  "Analyte",
  "Amount",
  "Distinguishing Features",
  "Morphology",
  "Sample Preparation",
  "Sample Condition",
  "Texture/Color",
  "Update Reason",
  "Tech ID",
];



// const location = useLocation();
// const searchParams = new URLSearchParams(location.search);
// const buildingId = searchParams.get('id');
function AsbestosResultDetails() {
  const deleteReasonRef = useRef("");
  const {
    result_type,
    texture_color,
    uncommon_texture_color,
    sample_condition,
    biofringence,
    ext_angle,
    elongation,
    asbestos_type_options,
    other_fibrous_options,
    non_fibrous_options,
    tech_id,
    isLoading,
    history,
    results,
  } = useSelector((state) => state.result);
  const params = useParams();
  const location = useLocation();
  const building = location?.state?.building;
  const pathName = getFirstPathSegment(location?.pathname);
  const category = pathName ? pathName.charAt(0).toUpperCase() + pathName.slice(1) : pathName
  const [hisoryFields, setHistoryFields] = useState([])
  const [historyData, setHistoryData] = useState([])
  const [modalOpen, setModalOpen] = useState(false);
  const presentKey = pathName === 'lead' ? 'LEAD_PRESENT' : 'ASB_PRESENT'
  const historyObj = {
    EXT_ANGLE: "EXTINCTION ANGLE",
    REF_INDEX: "REFLECTIVE INDEX",
    AVG_REF_INDEX: "AVERAGE REFLECTIVE INDEX",
    DIST_FEATURE: `DISTINGUISHING FEATURE FOR NON-${category} FIBERS`,
    MORPHOLOGY: "MORPHOLOGY",
    FPLA_ID: "FPLA ID",
    TECH_ID: "TECH ID",
    RESULT_DATE: "RESULT DATE",
    HOMOG: "HOMOGENEOUS",
    ASB_PRESENT: "ASBESTOS PRESENT",
    LEAD_PRESENT: "LEAD PRESENT",
    ASB_MATERIAL: `${category} FOUND IN MATERIAL`,
    PLEOCH: "PLEOCHROISM",
    BIOFRING: "BIOFRINGENCE",
    ELONG: "SIGN OF ELONGATION",
    REVS: "REVS",
    RANGE: null,
    MATERIAL: "MATERIAL",
    RES_TYPE: "RESULT TYPE",
    PERCENT: null,
    LAYER_ID: "LAYER",
    CATEGORY: "CATEGORY",
    SAMP_PREP: "SAMPLE PREPARATION",
    TEXTURE: "TEXTURE",
    SAMP_COND: "SAMPLE CONDITION",
    COMMENTS: "COMMENTS",
    update_reason: "UPDATE REASON"
  }

  const dispatch = useDispatch();
  const initialActiveBtn = useMemo(() => {
    const index = results?.findIndex(
      (obj) => obj?.id === location?.state?.resultId
    );
    return index !== -1 ? index : 0;
  });
  const [activeBtn, setActiveBtn] = useState(initialActiveBtn);
  // const [activeBtn, setActiveBtn] = useState(0);
  const navigate = useNavigate();
  const redirectToUpdateResult = (id) => {
    navigate(`/${pathName}/results/edit/${id}`, {
      state: {
        result_type,
        building,
        texture_color,
        uncommon_texture_color,
        sample_condition,
        biofringence,
        ext_angle,
        elongation,
        asbestos_type_options,
        other_fibrous_options,
        non_fibrous_options,
        tech_id,
        results,
        activeBtn,
      },
    });
  };
  function getCommonData() {
    dispatch(getCommon("result_type"));
    dispatch(getCommon("sample_condition"));
    dispatch(getCommon("texture_color"));
    dispatch(getCommon("tech_id"));
    dispatch(getCommon("uncommon_texture_color"));
    dispatch(getCommon("biofringence"));
    dispatch(getCommon("elongation"));
    dispatch(getCommon("ext_angle"));
    dispatch(getCommon("asbestos_type_options"));
    dispatch(getCommon("other_fibrous_options"));
    dispatch(getCommon("non_fibrous_options"));
  }

  useEffect(() => {
    return () => {
      dispatch(defaultResultsBySample());
    };
  }, []);

  function handleDelete(id) {
    confirm({
      title: "Are you sure you want to delete this Result?",
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <p>Please provide a reason for deletion:</p>
          <InputText
            onChange={(e) => (deleteReasonRef.current = e.target.value)}
          />
        </div>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        if (deleteReasonRef?.current === "") {
          openNotificationWithIcon(
            "error",
            "Kindly provide reason for deletion"
          );
        } else {
          dispatch(deleteResult(id, deleteReasonRef?.current));
          deleteReasonRef.current = "";
          navigate(`/${pathName}/buildings`);
        }
      },
      onCancel() {
        console.log("Cancel");
        deleteReasonRef.current = "";
      },
    });
  }
  // function deleteResultFunc(id){
  //   console.log(id,"-=-=",deleteReason)
  // }

  const getUniqueNonEmptyKeys = (arr = []) => {
    const fieldsToIgnore = ['id', 'first_name', 'last_name', 'user_id'];

    // Step 1: Combine all keys
    console.log(arr, "arrrrrrrrrrrrrrr")
    const allKeys = arr?.reduce((keys, obj) => {
      Object.keys(obj).forEach(key => {
        if (!fieldsToIgnore.includes(key)) {
          keys.add(key); // Using Set to ensure unique keys
        }
      });
      return keys;
    }, new Set());

    // Step 2: Filter out non-empty keys
    const uniqueNonEmptyKeys = [...allKeys]?.filter(key => {
      // Check if any object in the array has a non-empty value for this key
      return arr.some(obj => obj[key] !== undefined && obj[key] !== null && obj[key] !== '');
    });

    return uniqueNonEmptyKeys;
  };

  const filterObjects = (arr, fields) => {
    return arr?.map(obj => {
      const filteredObj = {};
      fields.forEach(field => {
        if (obj.hasOwnProperty(field)) {
          filteredObj[field] = obj[field];
        }
      });
      return filteredObj;
    });
  };


  async function handleHistory() {
    const history = await dispatch(getHistoryByResult(results[activeBtn]?.id))
    console.log(history, "-------------------history------------------")
    const data = getUniqueNonEmptyKeys(history)

    setHistoryFields(data)
    const data2 = filterObjects(history, data)
    console.log(data2, "data2")
    setHistoryData(data2)
    setModalOpen(true)
  }
  useEffect(() => {
    // dispatch(getResultsBySample(90))
    // dispatch({ type: "results/startLoading" })
    getCommonData();
    dispatch(getResultsBySample(params.id, pathName));
    // dispatch({ type: "results/stopLoading" })
  }, []);
  return (
    <MainLayout>
      {isLoading ? (
        <Spin fullscreen size="large" />
      ) : (
        <div className="result-details-wrapper">
          <HeaderText>
            <GoBack />
            Results ·{" "}
            <span className="result-title">
              FJLC {building} Sample ID: {results[activeBtn]?.SAMPLE_ID}
            </span>
          </HeaderText>
          <div className="select-buttons">
            {results?.map((item, index) => (
              <button
                key={index}
                onClick={() => setActiveBtn(index)}
                className={`button ${activeBtn === index
                  ? "primary-button"
                  : "primary-outline-btn shadow-none"
                  } `}
              >
                {item.LAYER_ID}
              </button>
            ))}
          </div>
          {results?.length > 0 ? (
            <div className="content-wrapper">
              <div className="row">
                <div className="col-9 left-content">
                  <div className="details-card">
                    <div>
                      <div className="text-label">Building ID</div>
                      <div className="text-mute">
                        {results[activeBtn]?.BUILDING_ID}
                      </div>
                    </div>
                    <div>
                      <div className="text-label">FPLA ID</div>
                      <div className="text-mute">
                        {results[activeBtn]?.FPLA_ID}
                      </div>
                    </div>
                    {/* <div>
                      <div className="text-label">Material Code</div>
                      <div className="text-mute">
                        {results[activeBtn]?.MATERIAL ?? "--"}
                      </div>
                    </div> */}
                    <div>
                      <div className="text-label">Homogeneous</div>
                      <div className="text-mute">
                        {Number(results[activeBtn]?.HOMOG) ? (
                          <CheckIcon />
                        ) : (
                          <CrossIcon />
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="text-label">{category} Present</div>
                      <div className="text-mute">
                        {Number(results[activeBtn][presentKey]) ? (
                          <CheckIcon />
                        ) : (
                          <CrossIcon />
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="text-label">Sample Date</div>
                      <div className="text-mute">
                        {results[activeBtn]?.SAMPLE_DATE}
                      </div>
                    </div>
                    <div>
                      <div className="text-label">Result Date</div>
                      <div className="text-mute">
                        {results[activeBtn]?.RESULT_DATE}
                      </div>
                    </div>
                  </div>
                  <DetailsCard header={`Only If there are no results associated with this sample that are of ${category} Type.`}>
                    <div className="row">
                      <div className="col-6">
                        <div className="text-label">
                          Distinguishing Feature for Non-{category} Fibers
                        </div>
                        <div className="text-mute">
                          {results[activeBtn]?.DIST_FEATURE ?? "--"}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-label">Morphology</div>
                        <div className="text-mute">
                          {results[activeBtn]?.MORPHOLOGY ?? "--"}
                        </div>
                      </div>
                    </div>
                  </DetailsCard>
                  <DetailsCard header="Basic">
                    <div className="row">
                      <div className="col-3">
                        <div className="text-label">Sample Preparation</div>
                        <div className="text-mute">
                          {results[activeBtn]?.SAMP_PREP ?? "--"}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label">Sample Condition</div>
                        <div className="text-mute">
                          {
                            // sample_condition?.find(
                            //   (cond) =>
                            //     cond?.id ===
                            (results[activeBtn]?.SAMP_COND)
                            // )?.description
                          }
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label">Texture/Color</div>
                        <div className="text-mute">
                          {
                            // isNaN(results[activeBtn]?.TEXTURE)
                            //   ? results[activeBtn]?.TEXTURE
                            //   : texture_color.find(
                            //     (color) =>
                            //       color?.id ===
                            (results[activeBtn]?.TEXTURE)
                            // )?.description ?? "--"
                          }
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label">Tech ID</div>
                        <div className="text-mute">
                          {
                            // tech_id?.find(
                            //   (cond) =>
                            //     cond?.id ===
                            (results[activeBtn]?.TECH_ID)
                            // )?.description
                          }
                        </div>
                      </div>
                    </div>
                  </DetailsCard>
                  <DetailsCard header="Layer Data">
                    <div className="row">
                      <div className="col-3">
                        <div className="text-label">Layer</div>
                        <div className="text-mute">
                          {results[activeBtn]?.LAYER_ID ?? "--"}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label">Type</div>
                        <div className="text-mute">
                          {results[activeBtn]?.CATEGORY ?? "--"}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label">{category}</div>
                        <div className="text-mute">
                          {results[activeBtn]?.MATERIAL ?? "--"}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label">Found in Material</div>
                        <div className="text-mute">
                          {results[activeBtn]?.ASB_MATERIAL ?? "--"}
                        </div>
                      </div>
                      <div className="col-3 mt-3">
                        <div className="text-label">Percent</div>
                        <div className="text-mute">
                          {results[activeBtn]?.PERCENT_RANGE ?? "--"}
                        </div>
                      </div>
                      <div className="col-3 mt-3">
                        <div className="text-label">Amount</div>
                        <div className="text-mute">
                          {Number(results[activeBtn]?.TRACE)
                            ? "TRACE"
                            : Number(results[activeBtn]?.RANGE)
                              ? "RANGE"
                              : "PERCENT"}
                        </div>
                      </div>
                      <div className="col-3 mt-3">
                        <div className="text-label">Result Type</div>
                        <div className="text-mute">
                          {
                            // result_type.find(
                            //   (res) =>
                            //     res.id === 
                            (results[activeBtn]?.RES_TYPE)
                            // )?.description
                          }
                        </div>
                      </div>
                    </div>
                  </DetailsCard>
                  <DetailsCard header="Other">
                    <div className="row">
                      <div className="col-3">
                        <div className="text-label">Pleochroism</div>
                        <div className="text-mute">
                          {Number(results[activeBtn]?.PLEOCH) ? (
                            <CheckIcon />
                          ) : (
                            <CrossIcon />
                          )}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label">Biofringence</div>
                        <div className="text-mute">
                          {
                            // biofringence?.find(
                            //   (cond) =>
                            //     cond?.id ===
                            //     Number
                            (results[activeBtn]?.BIOFRING)
                            // )?.description
                          }
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label">
                          Reflective Indices (nm)
                        </div>
                        <div className="text-mute">
                          {results[activeBtn]?.REF_INDEX}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="text-label">
                          Average Reflective Index
                        </div>
                        <div className="text-mute">
                          {results[activeBtn]?.AVG_REF_INDEX}
                        </div>
                      </div>
                      <div className="col-3 mt-3">
                        <div className="text-label">Sign of Elongation</div>
                        <div className="text-mute">
                          {results[activeBtn]?.ELONG}
                        </div>
                        <div className="text-mute">
                          {
                            // elongation?.find(
                            //   (cond) =>
                            //     cond?.id === 
                            (results[activeBtn]?.ELONG)
                            // )?.description
                          }
                        </div>
                      </div>
                      <div className="col-3 mt-3">
                        <div className="text-label">Extinction Angle</div>
                        <div className="text-mute">
                          {
                            // ext_angle?.find(
                            //   (cond) =>
                            //     cond?.id ===
                            (results[activeBtn]?.EXT_ANGLE)
                            // )?.description
                          }
                        </div>
                      </div>
                      {/* <div className="col-3 mt-3">
                        <div className="text-label">
                          Average Reflective Index for y/a
                        </div>
                        <div className="text-mute">--</div>
                      </div> */}
                    </div>
                  </DetailsCard>
                  <div className="description-card">
                    <div className="row">
                      <div className="col-6">
                        <div className="text-label">Updated Reason</div>
                        <div className="text-mute">
                          {results[activeBtn]?.update_reason ?? "--"}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-label">Entered By</div>

                        {/* {console.log(results[activeBtn]?.first_name,results[activeBtn]?.last_name)} */}
                        <div className="text-mute">
                          {results[activeBtn]?.first_name ??
                            "-" + " " + results[activeBtn]?.last_name ??
                            "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3 right-content">
                  <div className="sketch-card">
                    <div className="header">Diagram/Sketch</div>
                    <div className="body"></div>
                  </div>
                  <button
                    onClick={() => redirectToUpdateResult(Number(params.id))}
                    className="button primary-button"
                  >
                    Update Result
                  </button>
                  <button
                    disabled={results[activeBtn]?.UPDATE_COUNT > 1 ? false : true}
                    onClick={() => handleHistory()}
                    className="button yellow-button"
                  >
                    {results[activeBtn]?.UPDATE_COUNT} Historical Revisions
                  </button>
                  <button
                    className="button secondary-button"
                    onClick={(e) => handleDelete(results[activeBtn]?.id)}
                  >
                    Delete Result
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="empty">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </div>
      )}
      <Modal
        className="result-update-history-modal"
        centered
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={false}
        width={1300}
      >
        <div className="table-wrapper">
          <div className="table-overflow-wrapper container p-0">
            <table id="survey-table">
              {/* <thead>
                <tr>
                  {hisoryFields.map((i) => (
                    <th style={{ textWrap: "nowrap" }} key={i}>
                      {i}
                    </th>
                  ))}
                </tr>
              </thead> */}
              {/* <tbody>
                <tr>
                  {historyData?.map((i, indx) => (
                    <tr key={indx}>
                      {Object.values(i).map((j, jIndex) => (
                        <td key={jIndex}>{j ?? '--'}</td>
                      ))}
                    </tr>
                  ))}
                </tr>
              </tbody> */}
              <thead>
                <tr>
                  {hisoryFields?.map((i) => (
                    <th style={{ whiteSpace: "nowrap" }} key={i}>
                      {historyObj[i]}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {historyData?.map((i, indx) => (
                  <tr key={indx}>
                    {Object?.entries(i)?.map(([key, value], index) => (
                      key !== 'REVS' ?
                        <td key={index}>{value == 1 ? 'YES' : value == 0 ? 'NO' : value}</td>
                        :
                        <td key={index}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>

            </table>
            {/* {2 < 1 ? (
              <div className="empty">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            ) : null} */}
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
}

export default AsbestosResultDetails;

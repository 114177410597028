import React from "react";
import ExcelIcon from "@/assets/images/exel-file-icon.png";
function ExcelDownloadBtn({onClick}) {
  function handleClick(){
    if (typeof onClick === "function") {
      onClick();
  }
}
  return (
    <button className="excel-btn" onClick={handleClick}>
      <img src={ExcelIcon} alt="" />
    </button>
  );
}

export default ExcelDownloadBtn;

import React, { useEffect, useState } from "react";
import CheckboxIcon from "@/assets/images/checkbox-icon.svg";
function Checkbox({ onChange = () => {}, checked, id,name,value,disabled,defaultChecked }) {
  const [isChecked, setIsChecked] = useState(true);
  const uniqueId = `input-${Math.random().toString(36).substr(2, 10)}`;
  const handleChange = (e) => {
    e.stopPropagation();
    onChange({isChecked:!isChecked,event:e});
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    setIsChecked(checked ? checked : false);
  }, [checked]);
  return (
    
    <div className="checkbox-field">
      <label
        htmlFor={id ? id : uniqueId}
        className={isChecked ? "checked" : ""}
        onClick={(e)=>{e.stopPropagation()}}
      >
        <img
          className={isChecked ? "d-block" : "d-none"}
          src={CheckboxIcon}
          alt=""
        />
      </label>
      <input
        type="checkbox"
        className="d-none"
        name={name}
        value={value}
        id={id ? id : uniqueId}
        checked={isChecked}
        onChange={handleChange}
        
        onClick={(e) => e.stopPropagation()}
        disabled={disabled?disabled:false}
      />
    </div>
  );
}

export default Checkbox;

import React, { useEffect, useState } from "react";
import { changePage, getBuildings, openBuildingResourceModal } from "../../../../redux/slice/buildingSlice";
import { useDispatch, useSelector } from "react-redux";


import BuildingsTable from "../../../../components/tables/BuildingTable";

function AsbestosCertifiedBuildings() {
  const dispatch = useDispatch()
  const { currentPage } = useSelector((state) => state.building)

  const getBuildingsFunc = async () => {
    dispatch(getBuildings("certified"))
  }


  useEffect(() => {
    return () => {
      // Clean up state when component unmounts
      dispatch(changePage(1));
    };
  }, []);


  useEffect(() => {
    getBuildingsFunc()
  }, [currentPage])
  return (
    <BuildingsTable asbPresent={"unknown"} buildingType={'Certified Buildings'} />
  );
}

export default AsbestosCertifiedBuildings;

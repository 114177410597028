import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const MyChart = (props) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const {data:chartData} = props 
  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    const data = {
      // labels: ['Certified Buildings', 'Buildings with Asbestos', 'Buildings without Asbestos'],
      labels:chartData.labels,
      values: [
        chartData?.values[0] || 1,
        chartData?.values[1] || 0, 
      ],

      // colors: ['#3582D7','#FF0000' , '#D69B04'], // Colors for the doughnut segments
      colors: chartData?.colors, // Colors for the doughnut segments
    };

chartInstance.current = new Chart(ctx, {
  type: 'doughnut',
  data: {
    datasets: [
      {
        label: chartData.labels,
        data: data.values,
        backgroundColor: data.colors,
        hoverOffset: 4,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%', // This increases the hole in the center
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Building Data',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed !== null) {
              label += context.parsed;
            }
            return label;
          },
        },
      },
    },
  },
});


    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartData]);

  return (
    <div style={{ width: '100%', height: '330px' }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default MyChart;

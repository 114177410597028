import React, { useEffect, useState } from "react";
import {
  GoBack,
  HeaderText,
  MainLayout,
  SearchInput,
  ExcelDownloadBtn,
  PdfDownloadBtn,
} from "@/components";

import TrashIcon from "@/assets/images/trash-red-icon.svg";
import EditIcon from "@/assets/images/edit-blue-icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Empty, Modal, Pagination, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  changePage,
  deleteAssessment,
  getAssessments,
  getAssessmentsRecord,
  getSampleAreas,
  getSingleAssementDetail,
} from "../../../redux/slice/assessmentSlice";
import {
  handleExcelDownload,
  handlePdfDownload,
} from "../../../services/exportHandlers";
import { downloadFile, getFirstPathSegment } from "../../../services/Utils";
const { confirm } = Modal;
function AsbestosAssessment() {
  const location = useLocation();
  const Navigate = useNavigate();
  const [search, setSearch] = useState("");
  const pathName = getFirstPathSegment(location?.pathname)

  const { currentPage, assessments, isLoading, totalAssessments, sampleAreas } =
    useSelector((state) => state.assessment);

  const searchParams = new URLSearchParams(location.search);
  const buildingId = searchParams.get("id");
  const building = location?.state?.building;
  const dispatch = useDispatch();
  function getAssessmentFunc() {
    dispatch(getAssessments(buildingId));
  }
  function handlePageChange(page) {
    dispatch(changePage(page));
  }

  useEffect(() => {
    dispatch(getSampleAreas(buildingId));
  }, []);

  useEffect(() => {
    getAssessmentFunc();
  }, [currentPage]);
  useEffect(() => {
    return () => {
      dispatch(changePage(1));
    };
  }, []);
  // AsbestosAssessment.js

  // async function handleExcelAssessments() {
  //   const assessmentsRecord = await dispatch(getAssessmentsRecord(buildingId));

  //   const headers = [
  //     "Area",
  //     "MATERIAL CODE",
  //     "ASSESSMENT DATE",
  //     "DAMAGE ASSESSMENT",
  //     "EXPOSURE ASSESSMENT",
  //     "ASSESSMENT INDEX",
  //     "DESCRIPTION",
  //   ];

  //   const dataWithHeaders = [
  //     headers,
  //     ...assessmentsRecord.map(assessment => [
  //       sampleAreas?.filter(obj => assessment?.AREA?.includes(obj.id)).map(obj => obj.AREA_ID).join(','),
  //       assessment.MATL_DESC, assessment.ASSESS_DATE, assessment.DMG_ASSESS,
  //       assessment.EXP_ASSESS, assessment.ASSESS_ID, assessment.DESCRIPTION
  //     ])
  //   ];

  //   handleExcelDownload(dataWithHeaders, "AsbestosAssessments");
  // }

  // async function handlePdfAssessments() {
  //   const assessmentsRecord = await dispatch(getAssessmentsRecord(buildingId));
  //   const headers = [
  //     [
  //       "Area",
  //       "MATERIAL CODE",
  //       "ASSESSMENT DATE",
  //       "DAMAGE ASSESSMENT",
  //       "EXPOSURE ASSESSMENT",
  //       "ASSESSMENT INDEX",
  //       "DESCRIPTION",
  //     ],
  //   ];

  //   const data = assessmentsRecord.map(assessment => ([
  //     sampleAreas?.filter(obj => assessment?.AREA?.includes(obj.id)).map(obj => obj.AREA_ID).join(','),
  //     assessment?.MATL_DESC, assessment?.ASSESS_DATE, assessment?.DMG_ASSESS,
  //     assessment?.EXP_ASSESS, assessment?.ASSESS_ID, assessment.DESCRIPTION
  //   ]));

  //   handlePdfDownload(headers, data, "AsbestosAssessments");
  // }

  async function handleFile(file) {
    let type = file === 'xlsx' ? 'excel' : 'pdf'
    let url = `v1/building/assessments/${buildingId}/record?format=${type}`
    downloadFile(file, url, 'assessments')
  }

  const handleUpdate = (id) => {
    Navigate(`/${pathName}/assessment/update/${id}?id=${buildingId}`, {
      state: {
        building,
      },
    });
  };
  function navigateView(id) {
    Navigate(`/${pathName}/assessment/view/${id}?id=${buildingId}`,
      {
        state: {
          building,
        },
      })
  }

  function handleSearchSubmit() {
    dispatch(getAssessments(buildingId, search));
  }
  useEffect(() => {
    if (search === "") {
      dispatch(getAssessments(buildingId, search));
    }
  }, [search]);

  function handleDelete(id) {
    confirm({
      title: "Are you sure you want to delete this assessment?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteAssessmentFunc(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  function deleteAssessmentFunc(id) {
    dispatch(deleteAssessment(id, buildingId));
  }

  return (
    <MainLayout>
      <div className="survey-wrapper">
        <HeaderText>
          <GoBack />
          Assessment ({totalAssessments}) ·{" "}
          <span className="sample-title">{building}</span>
        </HeaderText>
        <div className="table-wrapper">
          <div className="table-outer-header">
            <div className="header-filters">
              <SearchInput
                width={400}
                icon="end"
                placeholder="Search by assessment id"
                className="user-search-input"
                onChange={(e) => setSearch(e.target.value)}
                onClick={handleSearchSubmit}
              />
              <Select
                style={{ width: 170 }}
                placeholder="Material Condition"
                suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                // onChange={}
                disabled
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                ]}
              />
            </div>
            <div className="header-buttons">
              <ExcelDownloadBtn onClick={() => handleFile('xlsx')} />
              <PdfDownloadBtn onClick={() => handleFile('pdf')} />
              <Link
                className="button yellow-button"
                to={`/${pathName}/assessment/create?id=${buildingId}`}
                state={{ building }}
              >
                + New Assessment
              </Link>
            </div>
          </div>
          <div className="table-overflow-wrapper container p-0">
            <table>
              <thead>
                <tr>
                  {[
                    "Area",
                    "MATERIAL CODE",
                    "ASSESSMENT DATE",
                    "DAMAGE ASSESSMENT",
                    "EXPOSURE ASSESSMENT",
                    "ASSESSMENT INDEX",
                    "DESCRIPTION",
                    "ACTIONS",
                  ].map((i) => (
                    <th key={i}>{i}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {

                  isLoading ?
                    <Spin delay={20} fullscreen size="large" />
                    :
                    <>
                      {
                        assessments.length ? assessments.map((assessment) => {
                          return (
                            <tr key={assessment?.id} >
                              <td onClick={() => navigateView(assessment?.id)}>{sampleAreas?.filter(obj => assessment?.AREA?.includes(obj.id)).map(obj => obj.AREA_ID).join(',')} </td>
                              <td>{assessment?.MATL_DESC} </td>
                              <td>{assessment?.ASSESS_DATE}</td>
                              <td>{assessment?.DMG_ASSESS}</td>
                              <td>{assessment.EXP_ASSESS}</td>
                              <td>{assessment.ASSESS_ID}</td>
                              <td style={{ maxWidth: "400px" }}>
                                {assessment?.DESCRIPTION}
                              </td>
                              <td>
                                <div className="action-btn">
                                  <img
                                    className="edit-icon"
                                    src={EditIcon}
                                    onClick={() => handleUpdate(assessment?.id)}
                                    alt=""
                                  />
                                  <img
                                    className="trash-icon"
                                    onClick={() => handleDelete(assessment?.id)}
                                    src={TrashIcon}
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                          : null}
                    </>
                }
              </tbody>
            </table>
            {assessments.length < 1 ? (
              <div className="empty">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {!isLoading && assessments.length > 0 && totalAssessments > 10 && (
        <div className="pagination">
          <Pagination
            defaultCurrent={1}
            total={totalAssessments}
            current={currentPage}
            onChange={handlePageChange}
          />
        </div>
      )}
    </MainLayout>
  );
}

export default AsbestosAssessment;

import React, { useEffect, useState } from "react";
import "./result.scss";

import {
  MainLayout,
  CheckIcon,
  CrossIcon,
  ExcelDownloadBtn,
  GoBack,
  HeaderText,
  PdfDownloadBtn,
  SearchInput,
} from "@/components";
import { Empty, Pagination, Select, Spin } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changePage, defaultResultsBySample, getResults, getResultsRecord } from "../../../redux/slice/resultsSlice";
import {
  handleExcelDownload,
  handlePdfDownload,
} from "../../../services/exportHandlers";
import { downloadFile, getFirstPathSegment } from "../../../services/Utils";





function AsbestosResults() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = getFirstPathSegment(location?.pathname)
  const [search, setSearch] = useState("")
  const { currentPage, results, isLoading, totalResults } = useSelector(
    (state) => state.result
  );
  const categoryType = [
    { value: pathName == 'asbestos' ? "A" : 'LD', label: pathName == 'asbestos' ? "Asbestos" : 'Lead' },
    { value: "ND", label: "Non-Detect" },
    { value: "LD", label: "Lead" },
    { value: "NF", label: "Non-Fibrous" },
    { value: "OF", label: "Other-Fibrous" }]
  const redirectToResultDetails = (id) => {
    navigate(`/${pathName}/results/view/${id}`);
  };

  const searchParams = new URLSearchParams(location.search);
  const buildingId = searchParams.get("id");
  const building = location?.state?.building;
  const presentKey = pathName == 'lead' ? 'LEAD_PRESENT' : 'ASB_PRESENT'

  const dispatch = useDispatch();

  function getResultsFunc() {
    dispatch(getResults(buildingId, pathName));
  }
  function handlePageChange(page) {
    dispatch(changePage(page));
  }

  useEffect(() => {
    getResultsFunc();
  }, [currentPage]);


  useEffect(() => {
    return (() => {
      dispatch(changePage(1))
      dispatch(defaultResultsBySample())
    })
  }, [])
  // AsbestosResults.js

  // async function handleExcelResults() {
  //   const resultsRecord = await dispatch(getResultsRecord(buildingId, pathName))

  //   const headers = [
  //     "fpla-id",
  //     "SAMPLE",
  //     "HOMOGENOUS",
  //     pathName === 'asbestos' ? "ASBESTOS PRESENT" : "LEAD PRESENT",
  //     "LAYER",
  //     "TYPE",
  //     "Asbestos TYPE",
  //     "Found in",
  //     "Amount",
  //     "Distinguishing Features",
  //     "Morphology",
  //     "Sample Preparation",
  //   ];

  //   const dataWithHeaders = [
  //     headers,
  //     ...resultsRecord?.map((result) => [
  //       result.FPLA_ID,
  //       result.SAMPLE_ID,
  //       Number(result.HOMOG) ? "Yes" : "No",
  //       Number(result[presentKey]) ? "Yes" : "No",
  //       result.LAYER_ID,
  //       result.TEXTURE,
  //       result.MATERIAL,
  //       result.ASB_MATERIAL,
  //       `${result.TRACE_AMT}%`,
  //       result.DIST_FEATURE,
  //       result.MORPHOLOGY,
  //       result.SAMP_PREP,
  //     ]),
  //   ];

  //   handleExcelDownload(dataWithHeaders, "AsbestosResults");
  // }

  // async function handlePdfResults() {
  //   const resultsRecord = await dispatch(getResultsRecord(buildingId))
  //   const headers = [
  //     [
  //       "fpla-id",
  //       "SAMPLE",
  //       "HOMOGENOUS",
  //       pathName == 'asbestos' ? "ASBESTOS PRESENT" : "LEAD PRESENT",
  //       "LAYER",
  //       "TYPE",
  //       "Asbestos TYPE",
  //       "Found in",
  //       "Amount",
  //       "Distinguishing Features",
  //       "Morphology",
  //       "Sample Preparation",
  //     ],
  //   ];

  //   const data = resultsRecord?.map((result) => [
  //     result.FPLA_ID,
  //     result.SAMPLE_ID,
  //     Number(result.HOMOG) ? "Yes" : "No",
  //     Number(result[presentKey]) ? "Yes" : "No",
  //     result.LAYER_ID,
  //     result.TEXTURE,
  //     result.MATERIAL,
  //     result.ASB_MATERIAL,
  //     `${result.TRACE_AMT}%`,
  //     result.DIST_FEATURE,
  //     result.MORPHOLOGY,
  //     result.SAMP_PREP,
  //   ]);

  //   handlePdfDownload(headers, data, "AsbestosResults");
  // }

  async function handleFile(file) {
    let type = file === 'xlsx' ? 'excel' : 'pdf'
    let url = `v1/building/results/${buildingId}/record?format=${type}&category=${pathName}`
    downloadFile(file, url, 'results')
  }


  function handleSearchSubmit() {
    dispatch(getResults(buildingId, pathName, search));

  }
  useEffect(() => {
    if (search === "") {
      dispatch(getResults(buildingId, pathName, search));

    }
  }, [search]);

  return (
    <MainLayout>
      <div className="result-wrapper">
        <HeaderText>
          <GoBack />
          Results · <span className="result-title">{building}</span>
        </HeaderText>
        <div className="table-wrapper">
          <div className="table-outer-header">
            <div className="header-filters">
              <SearchInput
                width={400}
                icon="end"
                placeholder="Search by result id"
                className="user-search-input"
                onChange={(e) => setSearch(e.target.value)}
                onClick={handleSearchSubmit}
              />
              <Select
                style={{ width: 170 }}
                placeholder="Material Condition"
                suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                // onChange={}
                disabled
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                ]}
              />
            </div>
            <div className="header-buttons">
              <ExcelDownloadBtn onClick={() => handleFile('xlsx')} />
              <PdfDownloadBtn onClick={() => handleFile('pdf')} />
              {/* <Link
                to={`/asbestos/results/create?id=${buildingId}`}
                className="button yellow-button"
              >
                + Add New Result
              </Link> */}
            </div>
          </div>
          <div className="table-overflow-wrapper container p-0">
            <table>
              <thead>
                <tr>
                  {[
                    "FPLA-ID",
                    "SAMPLE",
                    "HOMOGENOUS",
                    pathName == 'asbestos' ? "ASBESTOS PRESENT" : "LEAD PRESENT",
                    "LAYER",
                    "TYPE",
                    "ASBESTOS TYPE",
                    "FOUND IN",
                    "AMOUNT",
                    "DISTINGUISH FEATURES",
                    "MORPHOLOGY",
                    "SAMPLE PREPERATION",
                  ].map((i) => (
                    <th key={i}>{i}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <Spin fullscreen size="large" />
                ) : (
                  <>
                    {results?.length
                      ? results?.map((result) => (
                        // return (
                        <tr
                          key={result.id}
                          onClick={() => redirectToResultDetails(result?.SAMPLE_ID)}
                        >
                          <td>{result.FPLA_ID}</td>
                          <td>{result.SAMPLE_ID}</td>
                          <td>
                            {(Number(result.HOMOG)) ? (
                              <CheckIcon />
                            ) : (
                              <CrossIcon />
                            )}
                          </td>
                          <td>
                            {Number(result[presentKey]) ? (
                              <CheckIcon />
                            ) : (
                              <CrossIcon />
                            )}
                          </td>
                          <td>{result?.LAYER_ID}</td>
                          {/* {console.log(categoryType?.filter((item) => item?.value == result?.CATEGORY)[0]?.label, "categoryType.map((item) => item.value == result?.CATEGORY).label")} */}
                          <td>{categoryType?.filter((item) => item?.value == result?.CATEGORY)[0]?.label}</td>
                          <td>{result?.MATERIAL ?? '--'}</td>
                          <td>{result?.ASB_MATERIAL}</td>
                          <td>{result?.PERCENT_RANGE}%</td>
                          <td>{result?.DIST_FEATURE}</td>
                          <td>{result?.MORPHOLOGY}</td>
                          <td>{result?.SAMP_PREP}</td>
                        </tr>
                      ))
                      : null}
                  </>
                )}
              </tbody>
            </table>
            {results?.length < 1 ? (
              <div className="empty">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            ) : null}
          </div>
          {/* </div> */}
          {!isLoading && results?.length > 0 && totalResults > 10 && (
            <div className="pagination">
              <Pagination
                defaultCurrent={1}
                total={totalResults}
                current={currentPage}
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default AsbestosResults;

import React, { useEffect, useState } from "react";
import {
  Layout,
  //   Menu,
  //   Breadcrumb,
  Form,
  Input,
  Button,
  Tabs,
  Upload,
  Select,
} from "antd";
import Logo from "@/assets/images/logo-white.svg";
import { UploadOutlined } from "@ant-design/icons";
import API from "../../services/ApiService";
import { ApiEndPoint } from "../../services/ApiEndpoint";
import { openNotificationWithIcon } from "../../services/toast";
import UserAvatar from "@/assets/images/user-avatar.png";
import "./profile.scss";
import { InputText, MainLayout } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getCommon, updateCurrentUser } from "../../redux/slice/userSlice";
// import 'antd/dist/antd.css';


const { Content, Sider } = Layout;
const { TabPane } = Tabs;

const Profile = () => {
  const { currentUser, designation } = useSelector((state) => state.user)
  const [profileData, setProfileData] = useState({
    user_name: "",
    email: "",
    id: null,
    first_name: "",
    last_name: "",
    designation: "",
    location: "",
  });
  const dispatch = useDispatch()
  const [changedData, setChangedData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [tempImage, setTempImage] = useState(currentUser?.profile_picture ?? UserAvatar)
  const [formPassword] = Form.useForm();
  const [formGeneral] = Form.useForm();
  const [formEditProfile] = Form.useForm();

  useEffect(() => {
    getProfileData();
    setTempImage(currentUser?.profile_picture ?? UserAvatar)
  }, []);

  useEffect(() => {
    formEditProfile.setFieldsValue(profileData);
  }, [profileData, formEditProfile]);
  const getProfileApi = async () => {
    try {
      setIsLoading(true);
      const response = await API.get(ApiEndPoint.loginDetails);
      return response?.data?.data;
    } catch (error) {
      console.log(error, "------------------");
      openNotificationWithIcon("error", "error in fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getCommon('designation'))
  }, [])
  const setToLocalStorage = async () => {
    const data = await getProfileApi();
    localStorage.setItem("userData", JSON.stringify(data));
  };


  const getProfileData = async () => {
    try {
      const data = await getProfileApi();
      setProfileData((prev) => ({
        ...prev,
        user_name: data?.user_name,
        email: data?.email,
        id: data?.id,
        first_name: data?.first_name,
        last_name: data?.last_name,
        designation: data?.designation || null,
        location: data?.location || null,
      }));
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onFinish = async (values, form) => {
    setIsLoading(true);
    try {
      values.newPassword = values.newPassword.trim();
      if (values.newPassword.length < 4)
        return openNotificationWithIcon("error", "Password is too short ");
      const data = await API.post(ApiEndPoint.changePassword, values);
      form.resetFields();
      openNotificationWithIcon("success", "Password changed successfully");
    } catch (error) {
      openNotificationWithIcon("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  // For uploading a new picture

  const uploadProps = {
    onRemove: (file) => {
      // setFileList((curr) => curr.filter((e) => e.uid !== file.uid));
      setFileList([]);
      setTempImage(currentUser?.profile_picture ?? UserAvatar)
    },
    beforeUpload: (file) => {
      if (!file.type.startsWith('image/')) {
        openNotificationWithIcon("error", "You can only upload image files!");
        return false;
      }
      setTempImage(URL.createObjectURL(file))
      // setFileList((curr) => [...curr, file]);
      setFileList([file]);

      // Prevent upload from happening right away
      return false;
    },
    fileList,
  };

  async function handleGeneralSubmit(values, form) {
    // const { user_name, email, id } = profileData;
    try {
      for (let key in changedData) {
        if (!changedData[key].trim().length) {
          return openNotificationWithIcon("error", `${key} is required`);
        }
      }
      setIsLoading(true);
      const response = await API.put(
        `${ApiEndPoint.user}/${profileData?.id}`,
        changedData
      );
      const userData = response?.data?.data
      dispatch(updateCurrentUser(userData))
      form.resetFields();
      setToLocalStorage();
      openNotificationWithIcon("success", "Profile Updated successfully");
    } catch (error) {
      console.log(error, "---error---");
      openNotificationWithIcon("error", "error");
    } finally {
      setIsLoading(false);
    }
  }

  async function handleChange(e) {
    const { name, value } = e.target;
    console.log(name, value, "-----")
    const trimmedValue = value.trim();


    setProfileData((prev) => ({
      ...prev,
      [name]: trimmedValue,
    }));
    setChangedData((prev) => ({
      ...prev,
      [name]: trimmedValue,
    }));


  }

  async function handleEditProfileSubmit(values) {

    setIsLoading(true);
    console.log(changedData, "adf")
    for (let key in changedData) {
      if (!changedData[key].trim().length) {
        if (key !== 'designation') {
          return openNotificationWithIcon("error", `${key} is required`);

        }
      }
    }
    const formData = new FormData();

    if (fileList.length > 0) {
      formData.append("image", fileList[0]);
    }
    if (changedData.first_name)
      formData.append("first_name", profileData.first_name.trim());
    if (changedData.last_name) formData.append("last_name", profileData.last_name.trim());
    if (changedData.designation)
      formData.append("designation", profileData.designation);
    if (changedData.location) formData.append("location", profileData.location.trim());
    try {
      const response = await API.post(ApiEndPoint.updateProfile, formData);
      console.log(response?.data?.data, "responseeee")
      const userData = response?.data?.data
      dispatch(updateCurrentUser(userData))
      setToLocalStorage();
      openNotificationWithIcon("success", "Profile Updated Successfully");
    } catch (error) {
      console.log(error, "error");
      openNotificationWithIcon("error", "error");
    } finally {
      setChangedData({});
      setIsLoading(false);
    }
  }
  function handleTabChange() {
    setChangedData({});
    getProfileData();
  }

  return (
    <MainLayout>
      <div className="profile-wrapper">
        <div className="user-details-wrapper">
          <div className="user-avatar">
            <img
              src={currentUser?.profile_picture || Logo}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = UserAvatar;
              }}
              alt=""
            />
          </div>
          <div className="user-details">
            <div className="user-title">{currentUser?.first_name} {currentUser?.last_name} / General</div>
            <p>Update your username and manage your account</p>
          </div>
        </div>
        <Tabs
          defaultActiveKey="1"
          tabPosition={"left"}
          onChange={handleTabChange}
        >
          <TabPane tab="General" key="1">
            <Form
              name="general"
              form={formGeneral}
              onFinish={(values) => handleGeneralSubmit(values, formGeneral)}
              layout="vertical"
            >
              <div className="general">
                <div>
                  <InputText
                    label="Username"
                    name="user_name"
                    value={profileData?.user_name}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trim();
                      setChangedData({
                        ...changedData,
                        user_name: trimmedValue,
                      });
                      setProfileData({
                        ...profileData,
                        user_name: trimmedValue,
                      });
                    }}
                  />
                  <p className="input-instructions">
                    {/* Your FJLC url: http://fjlc.com/robertfletcher */}
                  </p>
                </div>
                <InputText
                  label="Email"
                  name="email"
                  value={profileData?.email}
                  onChange={(e) => {
                    const trimmedValue = e.target.value.trim();
                    setChangedData({
                      ...changedData,
                      email: trimmedValue,
                    });
                    setProfileData({
                      ...profileData,
                      email: trimmedValue,
                    });
                  }}
                />
                {/* <Form.Item label="Username">
                <Input
                  name="user_name"
                  placeholder="John"
                  value={profileData?.user_name}
                  onChange={(e) => {
                    setChangedData({
                      ...changedData,
                      user_name: e.target.value,
                    });
                    setProfileData({
                      ...profileData,
                      user_name: e.target.value,
                    });
                  }}
                />
              </Form.Item> */}
                {/* <Form.Item label="Email">
                <Input
                  name="email"
                  placeholder="john@cmail.com"
                  value={profileData?.email}
                  onChange={(e) => {
                    setChangedData({
                      ...changedData,
                      email: e.target.value,
                    });
                    setProfileData({
                      ...profileData,
                      email: e.target.value,
                    });
                  }}
                />
              </Form.Item> */}
                <button className="button primary-button" htmlType="submit">
                  Save Changes
                </button>
              </div>
            </Form>
          </TabPane>
          <TabPane tab="Edit Profile" key="2">
            <Form
              name="editProfile"
              form={formEditProfile}
              onFinish={handleEditProfileSubmit}
              layout="vertical"
            >
              <div className="image-section">
                <div className="upload-image">
                  <img
                    // src={currentUser?.profile_picture}
                    src={tempImage || Logo}
                    // onError={({ currentTarget }) => {
                    //   currentTarget.onerror = null;
                    //   currentTarget.src = UserAvatar;
                    // }}
                    alt=""
                  />
                  <Upload maxCount={1} {...uploadProps}>
                    <Button icon={null}>Click to upload</Button>
                  </Upload>
                </div>

                {/* <Form.Item label="First Name" name="first_name"> */}
                <InputText
                  label="First Name"
                  name="first_name"
                  placeholder="Robert Fletcher"
                  value={profileData?.first_name}
                  onChange={handleChange}
                />
                {/* </Form.Item> */}
                {/* <Form.Item label="Last Name" name="last_name"> */}
                <InputText
                  label="Last Name"
                  placeholder="Robert Fletcher"
                  name="last_name"
                  value={profileData?.last_name}
                  onChange={handleChange}
                />
                {/* </Form.Item> */}
                {/* <Form.Item label="Designation" name="designation"> */}
                {/* <InputText
                  label="Designation"
                  placeholder="Managing Director"
                  name="designation"
                  value={profileData?.designation}
                  onChange={handleChange}
                /> */}
                <label className="label" htmlFor="">
                  Designation
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  options={designation.map((item) => ({
                    value: item?.id,
                    label: item?.description
                  }))}
                  fieldNames={{ name: "Designation" }}
                  onChange={(x, item) => handleChange({ target: { value: item.value, name: "designation" } })}
                  value={profileData?.designation}
                />
                {/* </Form.Item> */}
                {/* <Form.Item label="Location" name="location"> */}
                <InputText
                  label="Location"
                  placeholder="Canada"
                  value={profileData?.location}
                  name="location"
                  onChange={handleChange}
                />
                {/* </Form.Item> */}
                <button className="button primary-button" htmlType="submit">
                  Save Changes
                </button>
              </div>
            </Form>
          </TabPane>
          <TabPane tab="Password" key="3">
            <Form
              form={formPassword}
              name="password"
              onFinish={(values) => onFinish(values, formPassword)}
              layout="vertical"
              className="password-section"
            >
              <Form.Item label="Old Password" name="oldPassword">
                <Input.Password />
              </Form.Item>
              <Form.Item label="New Password" name="newPassword">
                <Input.Password />
              </Form.Item>
              <button className="button primary-button" htmlType="submit">
                Change Password
              </button>
            </Form>
          </TabPane>
        </Tabs>
        <button className="logout-btn">Logout</button>
      </div>
    </MainLayout>
  );
};

export default Profile;

import { Select, Modal } from "antd";
import React, { useEffect, useState } from "react";
import SampleCard from "../samples/subComponent/sampleCard";
import {
  Checkbox,
  GoBack,
  HeaderText,
  InputText,
  MainLayout,
} from "@/components";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addSurvey,
  addSurveyDetails,
  defaultSurvey,
  deleteSurvey,
  getCommon,
  getInspectors,
  getSurveyData,
  setErrors,
  updateSurvey,
} from "../../../redux/slice/surveySlice";
import { openNotificationWithIcon } from "../../../services/toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getFirstPathSegment, surveyValidationObj } from "../../../services/Utils";
const { confirm } = Modal;

function SaveAsbestosSurvey() {
  const params = useParams();

  const location = useLocation();
  const pathName = getFirstPathSegment(location?.pathname);
  const {
    surveyDetails,
    errors,
    inspectors,
    exterior_type,
    roof_type,
    heating_type,
    cooling_type,
    plumbing_type,
    structural_frame,
    acm
  } = useSelector((state) => state.survey);
  const [changedData, setChangedData] = useState({});
  const [surveyId, setSurveyId] = useState(null);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const buildingId = searchParams.get("id");
  const building = location?.state?.building;
  useEffect(() => {
    dispatch(getCommon("inspectors"));
    dispatch(getCommon("exterior_type"));
    dispatch(getCommon("roof_type"));
    dispatch(getCommon("heating_type"));
    dispatch(getCommon("cooling_type"));
    dispatch(getCommon("plumbing_type"));
    dispatch(getCommon("structural_frame"));
    dispatch(getCommon("acm"));
    if (params.id) {
      setSurveyId(Number(params.id));
      getSurveyDataFunc(Number(params.id));
    } else {
      dispatch(defaultSurvey());
    }

    dispatch(defaultSurvey());
    const obj = { BUILDING_ID: location?.state?.buildingId + "" };
    dispatch(addSurveyDetails(obj));
    const obj2 = { SURVEY_DATE: new Date().toISOString().split("T")[0] };
    dispatch(addSurveyDetails(obj2));
    return () => {
      dispatch(defaultSurvey());
      dispatch(setErrors({}, "fullObj"));
      dispatch(addSurveyDetails({ BUILDING_ID: "", SURVEY_DATE: "" }));
    };
  }, []);

  function handleDelete(id) {
    confirm({
      title: "Are you sure you want to delete this Survey?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteSampleFunc();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  function deleteSampleFunc() {
    dispatch(deleteSurvey(surveyId, buildingId));
    Navigate(`/${pathName}/surveys?id=${buildingId}`, {
      state: {
        building,
      },
    });
  }

  const handleChange = (e, check) => {
    let obj;
    if (check) {
      obj = { [check]: e };
      dispatch(addSurveyDetails(obj));
      setChangedData((prev) => ({
        ...prev,
        [check]: e,
      }));
      dispatch(setErrors({ [check]: null }, "..."));
    }
    // my logic
    else if (e.target.name === "INSPECTOR") {
      if (e.target.checked) {
        obj = { INSPECTOR: [...surveyDetails?.INSPECTOR, e?.target?.value] };
      } else {
        const newArr = surveyDetails?.INSPECTOR.filter(
          (data) => data !== e.target.value
        );
        obj = { INSPECTOR: newArr };
      }
      dispatch(addSurveyDetails(obj));
      setChangedData((prev) => ({
        ...prev,
        ...obj,
      }));
      // dispatch(setErrors({[e.target.name]: null},"..."))
    } else {
      const { name, value } = e.target;

      if (name === 'INACCESS_AREA' && value == 0) {
        dispatch(addSurveyDetails({ INACCESS_AREA_ACM_QTY: null }));
        dispatch(addSurveyDetails({ INACCESS_AREA_ACM: '' }));
        dispatch(addSurveyDetails({ INACCESS_AREA_DESC: '' }));
        dispatch(addSurveyDetails({ INACCESS_AREA_REASON: '' }));

      }

      if (name === "NO_LEVELS" || name === "BUILDING_SIZE" || name === 'INACCESS_AREA_ACM_QTY') {
        const numericValue = value === "" ? null : Number(value);
        obj = { [name]: numericValue };
        setChangedData((prev) => ({
          ...prev,
          [name]: numericValue,
        }));
      } else {
        obj = { [name]: value };
        setChangedData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }

      dispatch(addSurveyDetails(obj));

      dispatch(setErrors({ [name]: null }, "..."));
    }
  };

  function getSurveyDataFunc(id) {
    dispatch(getSurveyData(id));
  }

  function validateData(surveyDetails) {
    let errors = {};
    let firstErrorField = null;
    for (const key in surveyDetails) {
      if (key === "BUILDING_ID") {
      } else if (
        surveyDetails[key] === null ||
        surveyDetails[key] === undefined ||
        surveyDetails[key] === ""
      ) {
        if (key == "INSPECTOR" || ((key === 'INACCESS_AREA_ACM' || key === 'INACCESS_AREA_ACM_QTY' || key === 'INACCESS_AREA_DESC' || key === 'INACCESS_AREA_REASON') && surveyDetails['INACCESS_AREA'] == 0)) {
        } else {
          errors[key] = `${surveyValidationObj[key]} is required`;
        }
        if ((key !== "INSPECTOR" && key !== 'INACCESS_AREA_ACM' && key !== 'INACCESS_AREA_ACM_QTY' && key !== 'INACCESS_AREA_DESC' && key !== 'INACCESS_AREA_REASON') && !firstErrorField) {
          firstErrorField = key;
        }
      }
    }
    // setErrors(errors)
    console.log(errors, "Aasd")
    dispatch(setErrors(errors, "fullObj"));
    // return Object.keys(errors).length === 0 ? null : "Validation Error";
    return firstErrorField;
  }


  // function validateData(surveyDetails) {
  //   let errors = {};
  //   let firstErrorField = null;
  //   for (const key in surveyDetails) {
  //     if (key === "BUILDING_ID") {
  //     } else if (
  //       surveyDetails[key] === null ||
  //       surveyDetails[key] === undefined ||
  //       surveyDetails[key] === ""
  //     ) {
  //       if (key == "INSPECTOR" || ((key === 'INACCESS_AREA_ACM' || key === 'INACCESS_AREA_ACM_QTY' || key === 'INACCESS_AREA_DESC' || key === 'INACCESS_AREA_REASON') && (surveyDetails['INACCESS_AREA'] == 0 || surveyDetails['INACCESS_AREA'] == undefined || surveyDetails['INACCESS_AREA'] == null || surveyDetails['INACCESS_AREA'] == ''))) {
  //       } else {
  //         errors[key] = `${surveyValidationObj[key]} is required`;
  //       }
  //       if (key == "INSPECTOR") {
  //         continue
  //       } else if (((key !== 'INACCESS_AREA_ACM' || key !== 'INACCESS_AREA_ACM_QTY' || key !== 'INACCESS_AREA_DESC' || key !== 'INACCESS_AREA_REASON') && surveyDetails['INACCESS_AREA'] == 0)) {
  //         console.log(key, "dsadasp")
  //         firstErrorField = key;
  //       }

  //     }
  //   }
  //   // setErrors(errors)
  //   console.log(errors, "Aasd")
  //   dispatch(setErrors(errors, "fullObj"));
  //   // return Object.keys(errors).length === 0 ? null : "Validation Error";
  //   return firstErrorField;
  // }
  const handleSubmit = async () => {
    const validate = validateData(surveyDetails);
    console.log(validate, "adadsads")
    // const validate = false
    if (validate) {
      const elementsByName = document.getElementsByName(validate);
      if (elementsByName && elementsByName.length > 0) {
        const firstElement = elementsByName[0];
        firstElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      return;
    } else {
      try {
        if (!surveyId) {
          dispatch(addSurvey());
        } else {
          dispatch(updateSurvey(surveyId, changedData));
        }
        Navigate(`/${pathName}/surveys?id=${buildingId}`, {
          state: {
            building,
          },
        });
      } catch (error) {
        openNotificationWithIcon("error", error?.response?.data?.message);
        console.log(error?.response?.data?.message);
      }
    }
  };

  return (
    <MainLayout>
      <div className="survey-create-wrapper">
        <HeaderText>
          <GoBack />
          {!surveyId ? `Add New Survey ` : `Update Survey `}
          <span className="survey-title">{building}</span>
        </HeaderText>
        <div className="create-survey-form-wrapper">
          <SampleCard header="General Building Inventory Survey Form">
            <div className="row">
              <div className="col-4">
                <InputText
                  label="LEVELS"
                  min={0}
                  errors={errors.NO_LEVELS}
                  name="NO_LEVELS"
                  type="number"
                  onChange={handleChange}
                  value={surveyDetails.NO_LEVELS}
                />
              </div>
              <div className="col-4">
                <InputText
                  label="sIZE (IN SQFT)"
                  min={0}
                  errors={errors.BUILDING_SIZE}
                  type="number"
                  name="BUILDING_SIZE"
                  onChange={handleChange}
                  value={surveyDetails.BUILDING_SIZE}
                />
              </div>
              <div className="col-4">
                {/* <label className="label" htmlFor="">
                  DESCRIPTION
                </label> */}
                {/* <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  // onChange={}
                  fieldNames={{ name: 'DESCRIPTION' }}
                  onChange={(e) => handleChange(e, "DESCRIPTION")} value={surveyDetails.DESCRIPTION}
                  options={[
                    { value: "jack", label: "Jack" },
                    { value: "lucy", label: "Lucy" },
                  ]}
                /> */}
                <InputText
                  label="DESCRIPTION"
                  errors={errors.DESCRIPTION}
                  name="DESCRIPTION"
                  onChange={handleChange}
                  value={surveyDetails.DESCRIPTION}
                />
              </div>
            </div>
            <div className="row mt-3">
              <label className="label">INSPECTORS</label>
              <div className="checkboxes">
                <div className="checkbox">
                  {inspectors.map((inspector) => (
                    <div key={inspector.id}>
                      <label>
                        <input
                          type="checkbox"
                          name="INSPECTOR"
                          value={inspector.id}
                          defaultChecked={
                            !surveyId
                              ? null
                              : surveyDetails?.INSPECTOR.includes(
                                inspector?.id + ""
                              )
                          }
                          onChange={handleChange}
                        />{" "}
                        {inspector.INSPECTOR}
                      </label>
                      <br />
                      <br />
                    </div>
                  ))}
                </div>
                <div className="checkbox"></div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-4">
                <div className="radio">
                  <label htmlFor="" className="label">
                    FULL BUILDING SURVEY?
                  </label>
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="FULL_BLDG_SURVEY"
                        id="FULL_BLDG_SURVEY-yes"
                        value={1}
                        defaultChecked={
                          !surveyId
                            ? null
                            : Number(surveyDetails.FULL_BLDG_SURVEY) == 1
                              ? true :
                              Number(surveyDetails.FULL_BLDG_SURVEY) == 0 ? false : null
                        }
                        onChange={handleChange}
                      />
                      <label htmlFor="FULL_BLDG_SURVEY-yes">Yes</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="FULL_BLDG_SURVEY"
                        id="FULL_BLDG_SURVEY-no"
                        value={0}
                        defaultChecked={
                          !surveyId
                            ? null
                            : Number(surveyDetails.FULL_BLDG_SURVEY) == 0
                              ? true :
                              Number(surveyDetails.FULL_BLDG_SURVEY) == 1 ? false : null
                        }
                        onChange={handleChange}
                      />
                      <label htmlFor="FULL_BLDG_SURVEY-no">No</label>
                    </div>
                  </div>
                  {errors.FULL_BLDG_SURVEY && (
                    <small className="error-message">
                      {errors.FULL_BLDG_SURVEY}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-4">
                <div className="radio">
                  <label htmlFor="" className="label">
                    FIRE PROTECTION / SPRINKLER SYSTEM
                  </label>
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="FIRE_PROTECTION"
                        id="FIRE_PROTECTION-yes"
                        value={1}
                        defaultChecked={
                          !surveyId
                            ? null
                            : Number(surveyDetails.FIRE_PROTECTION) == 1
                              ? true : Number(surveyDetails.FIRE_PROTECTION) == 0 ?
                                false : null
                        }
                        onChange={handleChange}
                      />
                      <label htmlFor="FIRE_PROTECTION-yes">Yes</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="FIRE_PROTECTION"
                        id="FIRE_PROTECTION-no"
                        value={0}
                        defaultChecked={
                          !surveyId
                            ? null
                            : Number(surveyDetails.FIRE_PROTECTION) == 0
                              ? true : Number(surveyDetails.FIRE_PROTECTION) == 1 ?
                                false : null
                        }
                        onChange={handleChange}
                      />
                      <label htmlFor="FIRE_PROTECTION-no">No</label>
                    </div>
                  </div>
                  {errors.FIRE_PROTECTION && (
                    <small className="error-message">
                      {errors.FIRE_PROTECTION}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-4">
                <InputText
                  label="COMMENT"
                  errors={errors.COMMENTS}
                  name="COMMENTS"
                  onChange={handleChange}
                  value={surveyDetails.COMMENTS}
                />
              </div>
            </div>
          </SampleCard>
          <SampleCard header="Building Construction">
            <div className="row">
              <div className="col-4">
                <label className="label" htmlFor="">
                  STRUCTURAL FRAME
                </label>

                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  // name="STRUCTURE_TYPE"
                  fieldNames={{ name: "STRUCTURE_TYPE" }}
                  onChange={(e) => handleChange(e, "STRUCTURE_TYPE")}
                  value={surveyDetails.STRUCTURE_TYPE}
                  // options={[
                  //   { value: "Concrete Slab", label: "Concrete Slab" },
                  //   { value: "Fiberglass", label: "Fiberglass" },
                  //   { value: "Fiberglass", label: "Fiberglass" },
                  //   {
                  //     value: "Masonry/Concrete Bearing Wall",
                  //     label: "Masonry/Concrete Bearing Wall",
                  //   },
                  //   { value: "Missing", label: "Missing" },
                  //   { value: "Steel Frame", label: "Steel Frame" },
                  //   {
                  //     value:
                  //       "Steel Frame - Roof & Masonry/Concrete Bearing WALL",
                  //     label:
                  //       "Steel Frame - Roof & Masonry/Concrete Bearing WALL",
                  //   },
                  //   { value: "Wood Frame", label: "Wood Frame" },
                  //   {
                  //     value: "Concrete w/Wood Frame",
                  //     label: "Concrete w/Wood Frame",
                  //   },
                  //   { value: "Other", label: "Other" },
                  // ]}
                  options={structural_frame?.map((item) => ({
                    value: item?.id,
                    label: item?.description,
                  }))}
                />
                {errors.STRUCTURE_TYPE && (
                  <small className="error-message">
                    {errors.STRUCTURE_TYPE}
                  </small>
                )}
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  EXTERIOR TYPE
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  onChange={(e) => handleChange(e, "EXTERIOR_TYPE")}
                  value={surveyDetails.EXTERIOR_TYPE}
                  options={exterior_type.map((item) => ({
                    value: item.id,
                    label: item.description,
                  }))}
                // options={[
                //   { value: "Cinderblock", label: "Cinderblock" },
                //   {
                //     value: "Concrete or Masonry",
                //     label: "Concrete or Masonry",
                //   },
                //   { value: "Fabric", label: "Fabric" },
                //   { value: "Fiberglass", label: "Fiberglass" },
                //   { value: "Metal", label: "Metal" },
                //   { value: "Metal Siding", label: "Metal Siding" },
                //   { value: "Missing", label: "Missing" },
                //   { value: "None", label: "None" },
                //   { value: "Stucco Walls", label: "Stucco Walls" },
                //   { value: "Wood", label: "Wood" },
                //   {
                //     value: "Wood / Metal Fence",
                //     label: "Wood / Metal Fence",
                //   },
                //   {
                //     value: "Wood / Vinyl / Metal Siding",
                //     label: "Wood / Vinyl / Metal Siding",
                //   },
                //   { value: "Other", label: "Other" },
                // ]}
                />
                {errors.EXTERIOR_TYPE && (
                  <small className="error-message">
                    {errors.EXTERIOR_TYPE}
                  </small>
                )}
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  ROOF MATERIALS
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  onChange={(e) => handleChange(e, "ROOF_TYPE")}
                  value={surveyDetails.ROOF_TYPE}
                  options={roof_type.map((item) => ({
                    value: item.id,
                    label: item.description,
                  }))}
                // options={[
                //   { value: "Fabric", label: "Fabric" },
                //   { value: "Fiberglass", label: "Fiberglass" },
                //   { value: "Flat", label: "Flat" },
                //   {
                //     value:
                //       "Flat - Built-Up Composition (Oldest) & Sloped Metal (Newest)",
                //     label:
                //       "Flat - Built-Up Composition (Oldest) & Sloped Metal (Newest)",
                //   },
                //   {
                //     value: "Flat - Built-Up Composition",
                //     label: "Flat - Built-Up Composition",
                //   },
                //   {
                //     value: "Flat - Old & Sloped Updated",
                //     label: "Flat - Old & Sloped Updated",
                //   },
                //   { value: "Grass & Sod", label: "Grass & Sod" },
                //   { value: "Metal Skirted", label: "Metal Skirted" },
                //   { value: "Missing", label: "Missing" },
                //   { value: "None", label: "None" },
                //   { value: "Rounded Metal", label: "Rounded Metal" },
                //   {
                //     value: "Sloped- Built-Up Composition",
                //     label: "Sloped- Built-Up Composition",
                //   },
                //   {
                //     value: "Sloped - Composition Shingles",
                //     label: "Sloped - Composition Shingles",
                //   },
                //   { value: "Sloped - Metal", label: "Sloped - Metal" },
                //   { value: "Sloped - Shingles", label: "Sloped - Shingles" },
                //   {
                //     value: "Steel Rounded Roof",
                //     label: "Steel Rounded Roof",
                //   },
                //   { value: "Unknown", label: "Unknown" },
                // ]}
                />

                {errors.ROOF_TYPE && (
                  <small className="error-message">{errors.ROOF_TYPE}</small>
                )}
              </div>
            </div>
          </SampleCard>
          <SampleCard header="Building System">
            <div className="row">
              <div className="col-4">
                <label className="label" htmlFor="">
                  HEATING TYPE
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  onChange={(e) => handleChange(e, "HEATING_TYPE")}
                  value={surveyDetails.HEATING_TYPE}
                  options={heating_type.map((item) => ({
                    value: item?.id,
                    label: item?.description,
                  }))}
                // options={[
                //   { value: "Air Drying Vent", label: "Air Drying Vent" },
                //   {
                //     value: "Ceiling Gas Heaters",
                //     label: "Ceiling Gas Heaters",
                //   },
                //   { value: "Central Heat", label: "Central Heat" },
                //   {
                //     value: "Ducted Forced Air & H/W Steam, In Building",
                //     label: "Ducted Forced Air & H/W Steam, In Building",
                //   },
                //   { value: "Electric", label: "Electric" },
                //   { value: "Gas Wall Units", label: "Gas Wall Units" },
                //   {
                //     value: "H/W Steam, Central Plant",
                //     label: "H/W Steam, Central Plant",
                //   },
                //   { value: "None", label: "None" },
                //   { value: "Space Heater", label: "Space Heater" },
                //   { value: "Wall Units", label: "Wall Units" },
                //   {
                //     value: "Window Air-Conditioner/Heat Unit",
                //     label: "Window Air-Conditioner/Heat Unit",
                //   },
                // ]}
                />
                {errors.HEATING_TYPE && (
                  <small className="error-message">{errors.HEATING_TYPE}</small>
                )}
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  COOLING TYPE
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  onChange={(e) => handleChange(e, "COOL_TYPE")}
                  value={surveyDetails.COOL_TYPE}
                  options={cooling_type?.map((item) => ({
                    value: item?.id,
                    label: item?.description,
                  }))}
                // options={[
                //   { value: "AC", label: "AC" },
                //   { value: "AC - Office Only", label: "AC - Office Only" },
                //   { value: "AC - Window Unit", label: "AC - Window Unit" },
                //   { value: "Central Cooling", label: "Central Cooling" },
                //   {
                //     value: "Chilled Water System",
                //     label: "Chilled Water System",
                //   },
                //   { value: "Exhaust Fans", label: "Exhaust Fans" },
                //   { value: "Fans", label: "Fans" },
                //   { value: "None", label: "None" },
                // ]}
                />
                {errors.COOL_TYPE && (
                  <small className="error-message">{errors.COOL_TYPE}</small>
                )}
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  PLUMBING TYPE
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  name="PLUMBING_TYPE"
                  onChange={(e) => handleChange(e, "PLUMBING_TYPE")}
                  value={surveyDetails.PLUMBING_TYPE}

                  options={plumbing_type.map((item) => ({
                    value: item?.id,
                    label: item?.description,
                  }))}
                />
                {errors.PLUMBING_TYPE && (
                  <small className="error-message">
                    {errors.PLUMBING_TYPE}
                  </small>
                )}
              </div>
            </div>
          </SampleCard>
          <SampleCard header="Inaccessibility">
            <div className="body-content">
              <div className="radio">
                <label htmlFor="" className="label">
                  Were parts of the building inaccessible?
                </label>
                <div className="radio-inputs">
                  <div className="radio-input">
                    <input
                      type="radio"
                      defaultChecked={
                        !surveyId
                          ? undefined
                          : surveyDetails.INACCESS_AREA
                            ? true
                            : false
                      }
                      name="INACCESS_AREA"
                      id="INACCESS_AREA-yes"
                      onChange={handleChange}
                      value={1}
                    />
                    <label htmlFor="INACCESS_AREA-yes">Yes</label>
                  </div>
                  <div className="radio-input">
                    <input
                      type="radio"
                      defaultChecked={
                        !surveyId
                          ? undefined
                          : surveyDetails.INACCESS_AREA
                            ? false
                            : true
                      }
                      name="INACCESS_AREA"
                      id="INACCESS_AREA-no"
                      onChange={handleChange}
                      value={0}
                    />
                    <label htmlFor="INACCESS_AREA-no">No</label>
                  </div>
                  {/* <p className="error-message">required*</p> */}
                  {errors.INACCESS_AREA && (
                    <small className="error-message">
                      {errors.INACCESS_AREA}
                    </small>
                  )}
                </div>
              </div>

              {surveyDetails?.INACCESS_AREA == "1" ? (
                <div className="row mt-3">
                  <div className="col-4">
                    <InputText
                      label="Inaccessible Area"
                      errors={errors.INACCESS_AREA_DESC}
                      name="INACCESS_AREA_DESC"
                      onChange={handleChange}
                      value={surveyDetails.INACCESS_AREA_DESC}
                    />
                  </div>
                  <div className="col-4" style={{ width: "30%" }}>
                    <label className="label" htmlFor="">
                      Known Potential ACM in Inaccessible Area
                    </label>
                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      name="INACCESS_AREA_ACM"
                      onChange={(e) => handleChange(e, "INACCESS_AREA_ACM")}
                      value={surveyDetails.INACCESS_AREA_ACM}

                      options={acm.map((item) => ({
                        value: item?.id + "",
                        label: item?.description,
                      }))}

                    />
                    {errors.INACCESS_AREA_ACM && (
                      <small className="error-message">{errors.INACCESS_AREA_ACM}</small>
                    )}
                  </div>
                  <div className="col-4" style={{ width: "36%" }}>
                    <InputText
                      label="Estimated Quantity of Potential ACM in inaccessible Area"
                      type='number'
                      min={0}
                      errors={errors.INACCESS_AREA_ACM_QTY}
                      name="INACCESS_AREA_ACM_QTY"
                      onChange={handleChange}
                      value={surveyDetails.INACCESS_AREA_ACM_QTY}
                    />
                  </div>
                  <div className="col-4 mt-3">
                    <InputText
                      label="Why is Area inaccessible"
                      errors={errors.INACCESS_AREA_REASON}
                      name="INACCESS_AREA_REASON"
                      onChange={handleChange}
                      value={surveyDetails.INACCESS_AREA_REASON}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </SampleCard>
          <div className="submit-btns">
            <button className="button primary-button" onClick={handleSubmit}>
              {!surveyId ? "Save New Survey" : "Update Survey"}
            </button>
            {surveyId ? (
              <button
                className="button secondary-button"
                onClick={handleDelete}
              >
                Delete Survey
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default SaveAsbestosSurvey;

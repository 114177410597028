import React from "react";

function Card({ children, ...data }) {
  return (
    <div
      className={`card-layout ${data?.className ? data?.className : ""}`}
      style={{
        backgroundColor: data?.bgColor ? data?.bgColor : "#fff",
        borderColor: data?.borderColor ? data?.borderColor : "#DEDEDE",
      }}
    >
      {children}
    </div>
  );
}

export default Card;

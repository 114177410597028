import { createSlice } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../../services/ApiEndpoint";
import API from "../../services/ApiService";
import { useDispatch } from "react-redux";
import { openNotificationWithIcon } from "../../services/toast";
import { checkEmpty } from "../../services/Utils";

const initialState = {
  sampleDetails: {
    BUILDING_ID: "",
    SAM_ID: "",
    // SAMP_ASB_ID:null,
    // ASB_ID:null,
    // SAMPLE_ID:"",
    RM_ID: "",
    FLOOR_ID: "",
    LBTRY_ID: "",
    SAMPLE_DATE: "",
    COMMENTS: "",
    // REASON_NO_SMPLE_CD:"",
    // DELETE_RECORD:null,
    // DELETE_DATE:(new Date).toISOString(),
    // DELETE_REQ_BY:"",
    // DELETE_REASON:"",
    // LOCKED:null,
    SAMPLE_ID: "",
    AREA_ID: null,
    ASB_TYPE: "",
    FUNCTIONAL_SPACE_CD: "",
    GEN_OCCUPANCY: "",
    SUSPECT_DSCRPTN: "",
    MATL_ID: "",
    MATL_COND_CD: "",
    EXPOSED: "",
    EQUIP_TYPE_CD: "",
    CONST_TYPE_CD: "",
    // MTRL_MSRMNT:{a:null,b:null,c:null},
    MATL_LN_FT: "",
    MATL_SQ_FT: "",
    MATL_CUB_YD: "",
    ACM_ACCESS_DIF_CD: "",
    AREA_ABATED: null,
    PHY_DAMAGE: null,
    WATER_DAMAGE: null,
    VISIBLE_DIST: null,
    ACCESS_DIST: null,
    FRIABLE: null,
    DET_DAMAGE: null,
    AIR_MOVE: "",
    ACTIVITY: null,
    DAMAGE_POTENTIAL: "",
    HAZ_ASSES_CD: null,
    PIPE_DIA_0_2: "",
    PIPE_DIA_3_6: "",
    PIPE_DIA_7_12: "",
    PIPE_DIA_12_PLUS: "",
    PIPE_LINEAR_FT: "",
    FITTINGS: null,
    PIPE_COMMENTS: null,
    ELBOW: "",
  },
  laboratory: [],
  ASB_HM_MATERIAL: [],
  functional_space: [],
  exposed_visible: [],
  damage_potential: [],
  sample_suspect_type: [],
  material_type: [],
  air_movement_activity: [],
  matl_condition: [],
  samples: [],
  equip_type: [],
  const_type: [],
  acm_access_dif: [],
  hazard_assesment: [],
  totalTableData: 0,
  tableData: [],
  totalSamples: 0,
  currentPage: 1,
  noticeData: [],
  isLoading: false,
  errors: {},
};

const sampleSlice = createSlice({
  name: "sample",
  initialState,
  reducers: {
    addSampleDetails(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = action.payload[key];

      state.sampleDetails[key] = value;
    },
    addSample(state, action) {
      state.isLoading = false;
    },
    startLoading(state, action) {
      state.isLoading = true;
    },
    changePage(state, action) {
      state.currentPage = action.payload;
      state.isLoading = false;
    },
    getSampleData(state, action) {
      state.sampleDetails = action.payload;
      state.isLoading = false;
    },
    stopLoading(state, action) {
      state.isLoading = false;
    },
    getSamples(state, action) {
      if (state.currentPage == 1) {
        state.totalSamples = action.payload.totalSamples;
      }
      state.samples = action.payload.samples;
      state.isLoading = false;
    },
    getTable(state, action) {
      if (state.currentPage == 1) {
        state.totalTableData = action.payload.totalTableData;
      }
      state.tableData = action.payload.tableData;
      state.isLoading = false;
    },
    getLaboratory(state, action) {
      state.laboratory = action.payload;
      state.isLoading = false;
    },
    setNoticeData(state, action) {
      state.noticeData = action.payload;
    },
    defaultTable(state, action) {
      state.tableData = [];
      state.totalTableData = 0;
      // state.currentPage = 1;
    },
    getCommon(state, action) {
      state[Object.keys(action.payload).toString()] = Object.values(
        action.payload
      ).flat();

      state.isLoading = false;
    },

    defaultSample(state, action) {
      state.sampleDetails = {
        BUILDING_ID: "",
        // SAMP_ASB_ID:null,
        // ASB_ID:null,
        // SAMPLE_ID:"",
        RM_ID: "",
        FLOOR_ID: "",
        LBTRY_ID: "",
        SAMPLE_DATE: "",
        COMMENTS: "",
        // REASON_NO_SMPLE_CD:"",
        // DELETE_RECORD:null,
        // DELETE_DATE:(new Date).toISOString(),
        // DELETE_REQ_BY:"",
        // DELETE_REASON:"",
        // LOCKED:null,
        SAMPLE_ID: "",
        AREA_ID: "",
        ASB_TYPE: "",
        FUNCTIONAL_SPACE_CD: "",
        GEN_OCCUPANCY: "",
        SUSPECT_DSCRPTN: "",
        MATL_ID: "",
        MATL_COND_CD: "",
        EXPOSED: "",
        EQUIP_TYPE_CD: "",
        CONST_TYPE_CD: "",
        // MTRL_MSRMNT:{a:"",b:"",c:""},
        MATL_LN_FT: "",
        MATL_SQ_FT: "",
        MATL_CUB_YD: "",
        ACM_ACCESS_DIF_CD: "",
        AREA_ABATED: null,
        PHY_DAMAGE: null,
        WATER_DAMAGE: null,
        VISIBLE_DIST: null,
        ACCESS_DIST: null,
        FRIABLE: null,
        DET_DAMAGE: null,
        AIR_MOVE: "",
        ACTIVITY: null,
        DAMAGE_POTENTIAL: "",
        HAZ_ASSES_CD: null,
        PIPE_DIA_0_2: "",
        PIPE_DIA_3_6: "",
        PIPE_DIA_7_12: "",
        PIPE_DIA_12_PLUS: "",
        PIPE_LINEAR_FT: "",
        FITTINGS: null,
        PIPE_COMMENTS: null,
        ELBOW: "",
      };
    },
    setErrors: {
      prepare(obj, msg) {
        return {
          payload: {
            obj,
            msg,
          },
        };
      },
      reducer(state, action) {
        if (action.payload.msg === "fullObj") {
          state.errors = action.payload.obj;
        } else {
          const key = Object.keys(action.payload.obj)[0];
          const value = action.payload.obj[key];
          state.errors[key] = value;
        }
      },
    },
  },
});

export const {
  addSampleDetails,
  defaultSample,
  setErrors,
  startLoading,
  changePage,
  defaultTable,
} = sampleSlice.actions;

export function addSample() {
  return async function (dispatch, getState) {
    dispatch({ type: "sample/startLoading" });
    try {
      const sampleDetails = getState().sample.sampleDetails;
      const newSampleDetails = JSON.parse(JSON.stringify(sampleDetails));
      // if (newSampleDetails.MTRL_MSRMNT){
      //    newSampleDetails.MTRL_MSRMNT=JSON.stringify(newSampleDetails.MTRL_MSRMNT)
      // }
      if (sampleDetails.ASB_TYPE === "HGA") {
        delete newSampleDetails.PIPE_DIA_0_2;
        delete newSampleDetails.PIPE_DIA_3_6;
        delete newSampleDetails.PIPE_DIA_7_12;
        delete newSampleDetails.PIPE_DIA_12_PLUS;
        delete newSampleDetails.PIPE_LINEAR_FT;
        delete newSampleDetails.ELBOW;
        delete newSampleDetails.PIPE_COMMENTS;
        delete newSampleDetails.FITTINGS;
      }

      for (const item in newSampleDetails) {
        if (checkEmpty(newSampleDetails[item])) {
          delete newSampleDetails[item];
        }
      }
      const response = await API.post(ApiEndPoint.sample, newSampleDetails);
      openNotificationWithIcon("success", "Sample created successfully");
      dispatch({ type: "sample/defaultSample" });
    } catch (error) {
      console.log(error, "error");
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "sample/stopLoading" });
    }
  };
}

export function uploadSamplePicture(payload) {
  return async function (dispatch, getState) {
    dispatch({ type: "sample/startLoading" });
    try {
      const response = await API.post(ApiEndPoint.uploadSamplePicture, payload);
      openNotificationWithIcon("success", "Images uploaded successfully");
    } catch (error) {
      console.log(error, "error");
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "sample/stopLoading" });
    }
  };
}

// export function deleteSample(id, buildingId) {
//   return async function (dispatch, getState) {
//     try {
//       dispatch({ type: "sample/startLoading" });
//       const response = await API.delete(`${ApiEndPoint.sample}/${id}`);
//       dispatch(getSamples(buildingId));
//       dispatch({ type: "sample/changePage", payload: 1 });
//       openNotificationWithIcon("success", "Sample deleted successfully");
//     } catch (error) {
//       dispatch({ type: "sample/stopLoading" });
//       openNotificationWithIcon("error", "Something went wrong");
//     }
//   };
// }

export function getSampleData(id) {
  return async function (dispatch, getState) {
    dispatch({ type: "sample/startLoading" });
    dispatch({ type: "sample/defaultSample" });
    try {
      const response = await API.get(`${ApiEndPoint.sample}/${id}`);

      const sampleData = response.data.data[0];

      const allowedKeys = Object.keys(initialState.sampleDetails);
      const filteredSampleData = Object.keys(sampleData)
        .filter((key) => allowedKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = sampleData[key];
          return obj;
        }, {});
      // if (filteredSampleData.MTRL_MSRMNT !== null) {
      // filteredSampleData.MTRL_MSRMNT = JSON.parse(filteredSampleData.MTRL_MSRMNT)
      // }
      dispatch({ type: "sample/getSampleData", payload: filteredSampleData });
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}

export function getSamples(id, search) {
  return async function (dispatch, getState) {
    dispatch({ type: "sample/startLoading" });
    try {
      const sampleState = getState();
      const currentPage = sampleState.sample.currentPage;
      const response = await API.get(
        `${ApiEndPoint.allSample}/${id}?skip=${(currentPage - 1) * 10}&limit=10 ${search ? `&search=${search}` : ""}`
      );
      const samples = response?.data?.data?.listing;

      const totalSamples = response?.data?.data?.count;
      dispatch({
        type: "sample/getSamples",
        payload: { samples, totalSamples },
      });
    } catch (error) {
      dispatch({ type: "sample/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}

export function getSamplesRecord(id) {
  return async function (dispatch, getState) {
    dispatch({ type: "sample/startLoading" });
    try {
      const endPoint = ApiEndPoint.samplesRecord.split("/");
      const suffix = endPoint.pop();
      const response = await API.get(`${endPoint.join("/")}/${id}/${suffix}`);

      const samples = response?.data?.data?.listing;
      return samples;
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "sample/stopLoading" });
    }
  };
}

export function deleteSample(id, DELETE_REASON, buildingId) {
  return async function (dispatch, getState) {
    dispatch({ type: "sample/startLoading" });
    try {
      const response = await API.delete(`${ApiEndPoint.sample}/${id}`, {
        data: { DELETE_REASON },
      });
      dispatch({ type: "sample/changePage", payload: 1 });
      dispatch(getSamples(buildingId));
      openNotificationWithIcon("success", "Sample deleted successfully");
    } catch (error) {
      openNotificationWithIcon("error", "Something went wrong");
    } finally {
      dispatch({ type: "sample/stopLoading" });
    }
  };
}

export function updateSample(sampleId, changedData) {
  return async function (dispatch, getState) {
    dispatch({ type: "sample/startLoading" });
    try {
      const response = await API.put(
        `${ApiEndPoint.sample}/${sampleId}`,
        changedData
      );
      openNotificationWithIcon("success", "Sample has been updated");
      // dispatch({type:"building/defaultBuilding"})
    } catch (error) {
      dispatch({ type: "sample/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
      console.log(error?.response?.data?.message);
    }
  };
}

export function getTable(table, id, category, search) {
  return async function (dispatch, getState) {
    dispatch({ type: "sample/startLoading" });
    try {
      const sampleState = getState();
      const currentPage = sampleState?.sample?.currentPage;
      const response = await API.get(
        `${ApiEndPoint.table}${table}/${id}?skip=${(currentPage - 1) * 10}&limit=10 &category=${category} ${search ? `&search=${search}` : ""}`
      );
      let tableData;
      let totalTableData;
      console.log(response?.data?.data, "response?.data?.data?.length");
      if (!Array.isArray(response?.data?.data)) {
        tableData = response?.data?.data?.groupedResult;
        totalTableData = response?.data?.data?.totalCount;
      } else {
        console.log("asdsa");
        tableData = [];
        totalTableData = 0;
      }
      dispatch({
        type: "sample/getTable",
        payload: { tableData, totalTableData },
      });
    } catch (error) {
      dispatch({ type: "sample/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}
export function getTableRecord(table, id, format, search) {
  return async function (dispatch, getState) {
    dispatch({ type: "sample/startLoading" });
    try {
      // const sampleState = getState();
      // const currentPage = sampleState?.sample?.currentPage;
      const response = await API.get(
        `${ApiEndPoint.table}${table}/${id}?format=${format}`,
        {
          // headers: {
          //   "Content-Type":
          //     format === "pdf"
          //       ? "application/pdf"
          //       : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // },
          responseType: {
            type: "arraybuffer",
          },
        }
      );

      return response.data;

      // let tableData;
      // let totalTableData;

      // if (!Array.isArray(response?.data?.data)) {
      //   tableData = response?.data?.data?.groupedResult;
      //   totalTableData = response?.data?.data?.totalCount;
      // } else {
      //   console.log("asdsa");
      //   tableData = [];
      //   totalTableData = 0;
      // }

      // return tableData;
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "sample/stopLoading" });
    }
  };
}
export function getLabReports(id, search) {
  return async function (dispatch, getState) {
    dispatch({ type: "sample/startLoading" });
    try {
      const sampleState = getState();
      const currentPage = sampleState.sample.currentPage;
      const response = await API.get(
        `${ApiEndPoint.allResults}/${id}?skip=${(currentPage - 1) * 10}&limit=10${search ? `&search=${search}` : ""}`
      );
      const tableData = response?.data?.data?.listing;

      const totalTableData = response?.data?.data?.count;
      dispatch({
        type: "sample/getTable",
        payload: { tableData, totalTableData },
      });
    } catch (error) {
      dispatch({ type: "sample/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}

export function getLabReportsRecord(id, format) {
  return async function (dispatch, getState) {
    dispatch({ type: "sample/startLoading" });
    try {
      const response = await API.get(
        `${ApiEndPoint.allResults}/${id}?format=${format}`
      );
      // const tableData = response?.data?.data?.listing;
      // return tableData;
      console.log(
        response,
        "reeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeesssss"
      );
      return response.data;
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "sample/stopLoading" });
    }
  };
}

//COMMON

// export function getLaboratory(){
//   return async function(dispatch,getState){
//     dispatch({type:"sample/startLoading"})
//     try {
//       const response = await API.get(
//         ApiEndPoint.laboratory);
//         console.log(response?.data?.data,"response?.data")
//         dispatch({type:"sample/getLaboratory",payload:response?.data?.data})
//     } catch (error) {
//       console.log(error,"lab error")
//     }
//   }
// }

export function getCommon(term) {
  return async function (dispatch, getState) {
    dispatch({ type: "sample/startLoading" });
    try {
      // const response = await API.get(ApiEndPoint[term]);
      // const response = await API.get(`${ApiEndPoint.commonGet}?type=${term}`);
      const response = await API.get(
        `${ApiEndPoint.common}?type=${term}&status=active`
      );
      dispatch({
        type: "sample/getCommon",
        payload: { [term]: response?.data?.data },
      });
      return response?.data?.data;
    } catch (error) {
      console.log(error, `${term} error`);
    }
  };
}

export function getSampleDes(id) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: "sample/startLoading" });
      // /v1/building/sample/area/{id}
      const response = await API.get(`${ApiEndPoint.sampleDesByBuilding}${id}`);
      const data = response?.data?.data;
      console.log("aaaaaaaaaaaaaaaaaaaaaaa", data);
      dispatch({ type: "sample/setNoticeData", payload: data });
      return data;
    } catch (error) {
      openNotificationWithIcon(
        "error",
        error?.response?.data?.message ?? "error occured"
      );
    } finally {
      dispatch({ type: "sample/stopLoading" });
    }
  };
}

export default sampleSlice.reducer;

import React from "react";

function DetailsCard({ header, children, cardBodyStyle, className }) {
  return (
    <div className={`result-detail-card ${className ? className : ""}`}>
      <div className="card-header">{header}</div>
      <div className={`card-body ${cardBodyStyle}`}>{children}</div>
    </div>
  );
}

export default DetailsCard;

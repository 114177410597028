import React, { useEffect, useState } from "react";
import {
  MainLayout,
  HeaderText,
  GoBack,
  Checkbox,
  InputText,
} from "@/components";
import "./result.scss";
import DetailsCard from "./subComponent/detailsCard";
import { checkEmpty, getFirstPathSegment, resultValidationObj } from "../../../services/Utils";
import { DatePicker, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { defaultResultsBySample, getCommon, getResultsBySample, setErrors, updateResult } from "../../../redux/slice/resultsSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { openNotificationWithIcon } from "../../../services/toast";

function UpdateAsbestosResult() {
  const location = useLocation();
  const pathName = getFirstPathSegment(location?.pathname)
  const params = useParams()
  const Navigate = useNavigate()
  const category = pathName ? pathName.charAt(0).toUpperCase() + pathName.slice(1) : pathName


  const reduxState = useSelector(state => ({
    result_type: state.result.result_type,
    texture_color: state.result.texture_color,
    uncommon_texture_color: state.result.uncommon_texture_color,
    sample_condition: state.result.sample_condition,
    results: state.result.results,
    biofringence: state.result.biofringence,
    ext_angle: state.result.ext_angle,
    elongation: state.result.elongation,
    tech_id: state.result.elongation,
    resultDetails: state.result.elongation,
    errors: state.result.errors
  }));
  const { errors } = reduxState
  const { result_type, texture_color, uncommon_texture_color, sample_condition, biofringence, ext_angle, elongation, asbestos_type_options, other_fibrous_options, non_fibrous_options, resultDetails, results, tech_id } = location.state || reduxState;

  const building = location?.state?.building
  const { currentUser: { user_name, id } } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [activeBtn, setActiveBtn] = useState(location?.state?.activeBtn || 0);
  const [activeTabId, setActiveId] = useState([])
  const [layerTypeOptions, setlayerTypeOptions] = useState([])

  const presentKey = pathName === 'lead' ? 'LEAD_PRESENT' : 'ASB_PRESENT'
  const notPresentKey = pathName === 'lead' ? 'ASB_PRESENT' : 'LEAD_PRESENT'

  const [resultsLocal, setResultsLocal] = useState([])
  const [updatedData, setUpdatedData] = useState({})

  useEffect(() => {
    if (resultsLocal.length > 0) {
      setActiveId([resultsLocal[location?.state?.activeBtn || 0]]);
    }
  }, [resultsLocal[0]]);

  useEffect(() => {
    return (() => {
      dispatch(defaultResultsBySample())
    })
  }, [])
  function getCommonData() {
    dispatch(getCommon("result_type"));
    dispatch(getCommon("sample_condition"));
    dispatch(getCommon("texture_color"));
    dispatch(getCommon("tech_id"));
    dispatch(getCommon("uncommon_texture_color"));
    dispatch(getCommon("biofringence"));
    dispatch(getCommon("elongation"));
    dispatch(getCommon("ext_angle"));
    dispatch(getCommon("asbestos_type_options"));
    dispatch(getCommon("other_fibrous_options"));
    dispatch(getCommon("non_fibrous_options"));

  }


  useEffect(() => {
    if (!location.state) {
      getCommonData()
      dispatch(getResultsBySample(Number(params.id)))
    } else {
      setResultsLocal(location?.state?.results);
    }
  }, [params?.id, location?.state]);


  useEffect(() => {
    if (!location?.state && reduxState?.results) {
      setResultsLocal(reduxState?.results);
    }
  }, [reduxState])


  function UpdateResultsLocalAndData(key, value, index = activeBtn) {
    setResultsLocal(prev => {
      const newArray = [...prev];
      if (newArray[index]) {
        newArray[index] = { ...newArray[index], [key]: value };
      }
      return newArray;
    });
    setUpdatedData(prev => ({
      ...prev,
      [key]: value
    }));

    if (key === 'MORPHOLOGY' || key === 'DIST_FEATURE') {
      dispatch(setErrors({ 'MORPHOLOGY': null }, "..."));
      dispatch(setErrors({ 'DIST_FEATURE': null }, "..."));
    } else {

      dispatch(setErrors({ [key]: null }, "..."));
    }
  }

  function handleChange(e, date) {
    if (date || date === '') {
      UpdateResultsLocalAndData('RESULT_DATE', date);
    } else {
      const { name, value } = e.target;
      if (name == presentKey) {
        console.log("hiiiiiiiiiiiiiii", presentKey, "ppppppp", notPresentKey, "ppp")
        UpdateResultsLocalAndData(presentKey, value);
        UpdateResultsLocalAndData(notPresentKey, null);
      } else {
        UpdateResultsLocalAndData(name, value);
      }

    }
  }

  function handleBlurValidate(e) {
    const { name, value } = e.target;
    if (name === 'PERCENT_RANGE' && resultsLocal[activeBtn]?.RANGE) {
      const correctedValue = Math.min(10, Math.max(5, value));
      if (value !== correctedValue.toString()) {
        UpdateResultsLocalAndData(name, correctedValue);
      }
    }
  }

  function handleMeasureChange(value) {
    ['TRACE', 'RANGE', 'PERCENT'].forEach(field => {
      const fieldValue = field === value ? 1 : null;
      UpdateResultsLocalAndData(field, fieldValue);
    });

    if (value === 'RANGE' && (resultsLocal[activeBtn]?.PERCENT_RANGE > 10 || resultsLocal[activeBtn]?.PERCENT_RANGE < 5)) {
      UpdateResultsLocalAndData('PERCENT_RANGE', 5);
    } else if (value === 'TRACE') {
      UpdateResultsLocalAndData('PERCENT_RANGE', null);
    }
  }



  const handleActiveTab = (id, e) => {
    console.log(id, e, "--------------------",)
    console.log("child clicked")
    e.event.stopPropagation()
    if (activeTabId?.find((i) => i === id)) {
      const filterId = activeTabId?.filter((i) => i !== id)
      setActiveId(filterId)
    } else {
      setActiveId([...activeTabId, id])

    }

  }
  const handleTabClicked = (e, index) => {
    console.log(index, "parent clicked")
    if (activeBtn !== index) {
      setUpdatedData({})
      if (location.state) {
        setResultsLocal(location?.state?.results);
      } else {
        setResultsLocal(reduxState?.results);
      }
    }
    // e.stopPropagation()
    // console.log(e);
    setActiveBtn(index)
  }

  useEffect(() => {
    if (resultsLocal?.[activeBtn]?.CATEGORY === 'A') {
      setlayerTypeOptions(asbestos_type_options)
    } else if (resultsLocal?.[activeBtn]?.CATEGORY === 'NF') {
      setlayerTypeOptions(non_fibrous_options)
    } else if (resultsLocal?.[activeBtn]?.CATEGORY === 'OF') {
      setlayerTypeOptions(other_fibrous_options)
    }
  }, [resultsLocal?.[activeBtn]?.CATEGORY])


  function validateData() {
    let errors = {};
    let firstErrorField = null;
    for (const key in resultsLocal?.[activeBtn]) {

      if (key === 'TRACE_AMT' || key === 'TRACE' || key === 'RANGE' || key === 'MATERIAL' || key === 'PERCENT' || key === 'update_reason') {
        // break;
      } else {
        console.log(key, 'keyy')
        if (
          resultsLocal?.[activeBtn][key] === null ||
          resultsLocal?.[activeBtn][key] === undefined ||
          resultsLocal?.[activeBtn][key] === ""
        ) {

          if (key === 'MORPHOLOGY' || key === 'DIST_FEATURE') {

            if (checkEmpty(resultsLocal?.[activeBtn]['MORPHOLOGY']) && checkEmpty(resultsLocal?.[activeBtn]['DIST_FEATURE'])) {
              errors[key] = `Plese add atleast one from field from MORPHOLOGY OR DIST FEATURE`;
              firstErrorField = key;
            }
          }

          if (key === 'HOMOG' || key === presentKey || key === 'FPLA_ID' || key === 'RESULT_DATE' || key === 'TECH_ID' || key === 'LAYER_ID' || key === 'CATEGORY' || key === 'ASB_MATERIAL' || key === 'RES_TYPE') {
            errors[key] = `${resultValidationObj[key]} is required`;
            firstErrorField = key;
          }
        }
      }
    }
    console.log(errors, "eroooor")
    dispatch(setErrors(errors, "fullObj"));
    // return Object.keys(errors).length === 0 ? null : "Validation Error";
    return firstErrorField;
  }







  // function validateData() {
  //   let errors = {};
  //   let firstErrorField = null;
  //   for (const key in resultsLocal?.[activeBtn]) {

  //     if (key === 'TRACE_AMT' || key === 'TRACE' || key === 'RANGE' || key === 'MATERIAL' || key === 'PERCENT' || key === 'user_id') {
  //       // break;
  //     } else {
  //       console.log(key, 'keyy')
  //       if (
  //         resultsLocal?.[activeBtn][key] === null ||
  //         resultsLocal?.[activeBtn][key] === undefined ||
  //         resultsLocal?.[activeBtn][key] === ""
  //       ) {
  //         errors[key] = `${key} is required`;
  //         firstErrorField = key;
  //       }
  //     }
  //   }
  //   console.log(errors, "eroooor")
  //   dispatch(setErrors(errors, "fullObj"));
  //   // return Object.keys(errors).length === 0 ? null : "Validation Error";
  //   return firstErrorField;
  // }

  function handleUpdate() {
    const validate = validateData()
    if (activeTabId.length) {
      if (validate) {
        let elementsByName = document.getElementsByName(validate);
        if (elementsByName && elementsByName.length > 0) {
          const firstElement = elementsByName[0];
          firstElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        return;
      } else {

        const ids = activeTabId.map((item) => item.id)
        // console.log(ids,"ids") 
        setUpdatedData((prev) => ({
          ...prev,
          resultIds: ids
        }))
        // dispatch(updateResult(updatedData))
      }
    } else {
      openNotificationWithIcon("error", 'Kindly select any tab to apply changes');
    }

  }
  useEffect(() => {
    if (updatedData.resultIds && updatedData.resultIds.length > 0) {
      console.log("Dispatching update with data:", updatedData);
      dispatch(updateResult(updatedData));
      Navigate(`/${pathName}/results?id=${resultsLocal?.[activeBtn]?.BUILDING_ID}`, {
        // state: { building },
      });
    }
  }, [updatedData]);


  return (
    <MainLayout>
      <div className="result-details-wrapper result-update-wrapper">
        <HeaderText>
          <GoBack />
          Update Result ·{" "}
          <span className="result-title">
            FJLC {building} Sample ID:{results[activeBtn]?.SAMPLE_ID}
          </span>
        </HeaderText>
        <div className="select-buttons">

          {resultsLocal.map((i, index) => (
            <button
              style={activeBtn === index ? { background: '#cbe0f6' } : {}}
              key={i}
              onClick={(e) => { handleTabClicked(e, index); setActiveId([i]) }}
              className="primary-outline-btn button shadow-none"
            >
              <Checkbox key={i} disabled={activeBtn === index ? true : false} checked={activeTabId?.find((item) => item?.id === i?.id) ? true : false}
                onChange={((e) => {
                  handleActiveTab(i, e)
                })} /> {i?.LAYER_ID}
            </button>
          ))}
        </div>
        <div className="content-wrapper">
          <div className="row">
            <div className="col-9 left-content">
              <div className="details-card">
                <InputText label="Building ID" value={resultsLocal?.[activeBtn]?.BUILDING_ID} disabled />
                <InputText label="FPLA ID" errors={errors?.FPLA_ID} name='FPLA_ID' value={resultsLocal?.[activeBtn]?.FPLA_ID} onChange={handleChange} />
                <InputText label="Material Code" disabled value={resultsLocal?.[activeBtn]?.MATERIAL} />

                <div className="homogeneous-select-btn">
                  <div className="text-label">Homogeneous</div>
                  <div className="text-mute">
                    <button
                      onClick={() => handleChange({ target: { name: "HOMOG", value: '1' } })}
                      className={`shadow-none button ${Number(resultsLocal[activeBtn]?.HOMOG == 1) ? "yellow-button" : ""
                        }`}
                    >
                      Yes
                    </button>{" "}
                    <button
                      onClick={() => handleChange({ target: { name: "HOMOG", value: '0' } })}
                      className={`shadow-none button ${Number(resultsLocal[activeBtn]?.HOMOG == 0) ? "yellow-button" : ""
                        }`}
                    >
                      No
                    </button>
                  </div>
                </div>
                <div className="homogeneous-select-btn">
                  <div className="text-label">{category} Present</div>
                  <div className="text-mute">
                    <button
                      onClick={() => handleChange({ target: { name: presentKey, value: '1' } })}
                      className={`shadow-none button ${Number(resultsLocal?.[activeBtn]?.[presentKey] == 1) ? "yellow-button" : ""
                        }`}
                    >
                      Yes
                    </button>{" "}
                    <button
                      onClick={() => handleChange({ target: { name: presentKey, value: '0' } })}
                      className={`shadow-none button ${Number(resultsLocal?.[activeBtn]?.[presentKey] == 0) ? "yellow-button" : ""}`}

                    >
                      No
                    </button>
                  </div>
                </div>
                <div>
                  <div className="text-label">Sample Date</div>
                  <div className="text-mute">
                    <DatePicker
                      className="w-100"
                      suffixIcon={false}
                      disabled={true}
                      value={resultsLocal[activeBtn]?.SAMPLE_DATE
                        ? moment(resultsLocal[activeBtn]?.SAMPLE_DATE, "YYYY-MM-DD")
                        : null
                      }
                    // onChange={onChange}
                    />
                  </div>
                </div>
                <div>
                  <div className="text-label">Result Date</div>
                  <div className="text-mute">
                    <DatePicker
                      className="w-100"
                      // value={resultsLocal[activeBtn]?.RESULT_DATE}
                      value={
                        resultsLocal[activeBtn]?.RESULT_DATE
                          ? moment(resultsLocal[activeBtn]?.RESULT_DATE, "YYYY-MM-DD")
                          : null
                      }
                      suffixIcon={false}
                      onChange={(e, date) => handleChange(null, date)} />
                  </div>
                </div>
              </div>
              <DetailsCard header={`Only If there are no results associated with this sample that are of ${category} Type.`}>
                <div className="row">
                  <div className="col-6">
                    <InputText label={`Distinguishing Feature for Non-${category} Fibers`} name="DIST_FEATURE" errors={errors?.DIST_FEATURE} value={resultsLocal[activeBtn]?.DIST_FEATURE ?? '--'} onChange={handleChange} />
                  </div>
                  <div className="col-6">
                    <InputText label="Morphology" errors={errors?.MORPHOLOGY} name="MORPHOLOGY" onChange={handleChange} value={resultsLocal[activeBtn]?.MORPHOLOGY ?? '--'} />
                  </div>
                </div>
              </DetailsCard>
              <DetailsCard header="Basic">
                <div className="row">
                  <div className="col-3">
                    <InputText label="Sample Preparation" name="SAMP_PREP" errors={errors?.SAMP_PREP} onChange={handleChange} value={resultsLocal[activeBtn]?.SAMP_PREP ?? '--'} />
                  </div>
                  <div className="col-3">
                    <label className="label">Sample Condition</label>
                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      // onChange={}
                      value={(resultsLocal[activeBtn]?.SAMP_COND)}
                      // value= {"jack"}
                      onChange={(e) => handleChange({ target: { name: "SAMP_COND", value: e } })}
                      options={
                        sample_condition.map((samp) => ({ value: (samp?.id), label: samp?.description }))
                      }
                    />
                    {errors.SAMP_COND && (
                      <small className="error-message">{errors.SAMP_COND}</small>
                    )}
                  </div>
                  <div className="col-3">
                    <label className="label">Texture/Color</label>
                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      onChange={((e) => handleChange({ target: { name: "TEXTURE", value: e } }))}
                      value={(resultsLocal[activeBtn]?.TEXTURE) ?? ""}
                      options={
                        [...texture_color, ...uncommon_texture_color].map((color) => (

                          { value: color?.id, label: color?.description }
                        ))
                      }
                    />
                  </div>
                  <div className="col-3">
                    <label className="label">Tech ID</label>
                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      onChange={((e) => handleChange({ target: { name: "TECH_ID", value: e } }))}
                      value={(resultsLocal[activeBtn]?.TECH_ID)}
                      options={
                        tech_id.map((item) => ({
                          label: item?.description,
                          value: item?.id
                        }))
                      }
                    />
                    {errors.TECH_ID && (
                      <small className="error-message">{errors.TECH_ID}</small>
                    )}
                  </div>
                </div>
              </DetailsCard>
              <DetailsCard header="Layer Data">
                <div className="row">
                  <div className="col-3">
                    <InputText label="Layer" name="LAYER_ID" value={resultsLocal[activeBtn]?.LAYER_ID ?? '--'} errors={errors?.LAYER_ID} onChange={handleChange} />
                  </div>
                  <div className="col-3">
                    <label className="label">Type</label>
                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      onChange={((e) => handleChange({ target: { name: "CATEGORY", value: e } }))}
                      value={resultsLocal[activeBtn]?.CATEGORY}
                      options={[
                        { value: pathName == 'asbestos' ? "A" : 'LD', label: pathName == 'asbestos' ? "Asbestos" : 'Lead' },
                        { value: "ND", label: "Non-Detect" },
                        { value: "LD", label: "Lead" },
                        { value: "NF", label: "Non-Fibrous" },
                        { value: "OF", label: "Other-Fibrous" },
                      ]}
                    />
                    {errors.CATEGORY && (
                      <small className="error-message">{errors.CATEGORY}</small>
                    )}
                  </div>
                  <div className="col-3">

                    <label className="label"> {resultsLocal[activeBtn]?.CATEGORY === "A" ? "**Asbestos Type" : resultsLocal[activeBtn]?.CATEGORY === "NF" ? "Non-Fibrous Form" : resultsLocal[activeBtn]?.CATEGORY === "OF" ? "Non-Asbestos fibres" : resultsLocal[activeBtn]?.CATEGORY === "ND" ? "Result Type" : "LEAD Type"}</label>
                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      onChange={((e) => handleChange({ target: { name: "MATERIAL", value: e } }))}
                      disabled={resultsLocal[activeBtn]?.CATEGORY === 'ND'}
                      value={resultsLocal[activeBtn]?.MATERIAL}
                      options={
                        layerTypeOptions.map((layer) => (
                          { value: layer.id, label: layer.description }
                        ))
                      }
                    />
                    {errors.MATERIAL && (
                      <small className="error-message">{errors.MATERIAL}</small>
                    )}
                  </div>
                  {/* <div className="col-3">
                    <label className="label">Found in Material</label>
                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      // onChange={}
                      value={resultsLocal?[activeBtn]?.ASB_MATERIAL}
                      options={[
                        { value: "jack", label: "Jack" },
                        { value: "lucy", label: "Lucy" },
                      ]}
                    />
                  </div> */}
                  <div className="col-3 ">
                    <InputText label="Found in Material" errors={errors?.ASB_MATERIAL} name='ASB_MATERIAL' value={resultsLocal[activeBtn]?.ASB_MATERIAL} onChange={handleChange} />
                  </div>
                  <div className="col-3 mt-3">
                    <label className="label">Amount</label>
                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      onChange={handleMeasureChange}
                      value={Number(resultsLocal[activeBtn]?.TRACE) ? 'TRACE' : Number(resultsLocal[activeBtn]?.RANGE) ? 'RANGE' : 'PERCENT'}
                      options={[
                        { value: "PERCENT", label: "PERCENT" },
                        { value: 'RANGE', label: "RANGE" },
                        { value: 'TRACE', label: "TRACE" },
                      ]}
                    />

                  </div>
                  {
                    resultsLocal[activeBtn]?.RANGE || resultsLocal[activeBtn]?.PERCENT ?
                      <div className="col-3 mt-3">
                        <InputText label={resultsLocal[activeBtn]?.PERCENT ? "PERCENT" : "RANGE(ex 5-10)"} name='PERCENT_RANGE' blurValidate={handleBlurValidate} value={resultsLocal[activeBtn]?.PERCENT_RANGE} onChange={handleChange} />
                      </div>
                      : ""
                  }

                  <div className="col-3 mt-3">
                    <label className="label">Result Type</label>
                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      onChange={((e) => handleChange({ target: { name: "RES_TYPE", value: e } }))}
                      value={resultsLocal[activeBtn]?.RES_TYPE}
                      // value={result_type.find((res) => res.id === Number(resultsLocal[activeBtn]?.RES_TYPE))?.description}
                      options={
                        result_type.map((res) => (
                          { value: res?.id, label: res?.description }
                        ))
                      }
                    />
                    {errors.RES_TYPE && (
                      <small className="error-message">{errors.RES_TYPE}</small>
                    )}
                  </div>
                </div>
              </DetailsCard>
              <DetailsCard header="Other">
                <div className="row">
                  <div className="col-3">
                    <div className="homogeneous-select-btn pleochroism">
                      <div className="text-label">Pleochroism</div>
                      <div className="text-mute">
                        <button
                          onClick={() => handleChange({ target: { name: "PLEOCH", value: '1' } })}
                          className={`shadow-none button ${Number(resultsLocal[activeBtn]?.PLEOCH == 1) ? "yellow-button" : ""
                            }`}
                        >
                          Yes
                        </button>{" "}
                        <button
                          onClick={() => handleChange({ target: { name: "PLEOCH", value: '0' } })}
                          className={`shadow-none button ${Number(resultsLocal[activeBtn]?.PLEOCH == 0) ? "yellow-button" : ""
                            }`}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <label className="label">Biofringence</label>
                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      onChange={(e) => handleChange({ target: { name: 'BIOFRING', value: e } })}
                      value=
                      {resultsLocal[activeBtn]?.BIOFRING}
                      // {biofringence.find((res) => res.id === Number(resultsLocal[acti1veBtn]?.BIOFRING))?.description}

                      options=
                      {biofringence.map((item) => ({
                        label: item?.description,
                        value: item?.id
                      }))}
                    // {[
                    //   { value: "Low", label: "Low" },
                    //   { value: "Mod", label: "Mod" },
                    //   { value: "High", label: "High" },
                    // ]}
                    />
                    {errors.BIOFRING && (
                      <small className="error-message">{errors.BIOFRING}</small>
                    )}
                  </div>
                  <div className="col-3">
                    <InputText label="Reflective Indices (nm)" errors={errors?.REF_INDEX} name='REF_INDEX' value={resultsLocal[activeBtn]?.REF_INDEX} onChange={handleChange} />
                  </div>
                  <div className="col-3">
                    <InputText label="Average Reflective Index" name='AVG_REF_INDEX' errors={errors?.AVG_REF_INDEX} value={resultsLocal[activeBtn]?.AVG_REF_INDEX} onChange={handleChange} />
                  </div>
                  <div className="col-3 mt-3">
                    <label className="label">Sign of Elongation</label>
                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      onChange={(e) => handleChange({ target: { name: 'ELONG', value: e } })}
                      value={resultsLocal[activeBtn]?.ELONG}
                      // {elongation.find((res) => res.id === Number(resultsLocal[activeBtn]?.ELONG))?.description}

                      options=
                      {elongation.map((item) => ({
                        label: item?.description,
                        value: item?.id
                      }))}
                    // {[
                    //   { value: "Positive", label: "Positive" },
                    //   { value: "Negative", label: "Negative" },
                    // ]}
                    />
                    {errors.ELONG && (
                      <small className="error-message">{errors.ELONG}</small>
                    )}
                  </div>
                  <div className="col-3 mt-3">
                    <label className="label">Extinction Angle</label>
                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      onChange={(e) => handleChange({ target: { name: 'EXT_ANGLE', value: e } })}
                      // value={ext_angle.find((res) => res.id === Number(resultsLocal[activeBtn]?.EXT_ANGLE))?.description}
                      value={resultsLocal[activeBtn]?.EXT_ANGLE}

                      options={ext_angle.map((item) => ({
                        label: item?.description,
                        value: item?.id
                      }))}
                    />
                    {errors.EXT_ANGLE && (
                      <small className="error-message">{errors.EXT_ANGLE}</small>
                    )}
                  </div>
                  {/* <div className="col-3 mt-3">
                    <InputText label="Average Reflective Index for y/a" />
                  </div> */}
                </div>
              </DetailsCard>
            </div>
            <div className="col-3 right-content">
              <div className="sidebar">
                <div className="content">
                  <div>
                    <label className="label">Entered by</label>

                    <Select
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      // onChange={}
                      options={[
                        { value: id, label: user_name },
                        // { value: "lucy", label: "Lucy" },
                      ]}
                      value={user_name}
                    />
                  </div>
                  <div>
                    <label className="label">Updated reason</label>
                    <textarea
                      name="update_reason"
                      id="description"
                      rows="3"
                      value={resultsLocal?.[activeBtn]?.update_reason}
                      onChange={handleChange}
                      placeholder="Text here...."
                    ></textarea>
                    {errors.update_reason && (
                      <small className="error-message">{errors.update_reason}</small>
                    )}
                  </div>
                </div>

                <button className="button primary-button" onClick={handleUpdate}>Update Result</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default UpdateAsbestosResult;

import React, { useState, useEffect } from "react";

const InputText = ({ ...data }) => {
  const [error, setError] = useState("");
  const [generatedControlId, setGeneratedControlId] = useState("");

  useEffect(() => {
    if (!data?.controlId) {
      const generatedId = `input-${Math.random().toString(36).substr(2, 10)}`;
      setGeneratedControlId(generatedId);
    }
  }, [data?.controlId]);

  const handleBlur = (e) => {
    if (!e.target.value && data?.required) {
      if (data?.label) {
        setError(`${data?.label} is required`);
      } else {
        setError("This field is required.");
      }
    } else {
      setError("");
    }

    if(data?.blurValidate){
      data?.blurValidate(e)
    }

  };
  const handleChange = (e) => {
    if (typeof data?.onChange === "function") {
      data?.onChange(e);
    }
    if (data?.numbers && isNaN(e.target.value)) {
      setError("Field must be a number");
    } else {
      setError("");
    }
  };

  return (
    <div
      className={`input-text-wrapper ${data?.inputStyle} ${data?.className}`}
    >
      {data?.label ? (
        <label htmlFor={data?.controlId || generatedControlId}>
          {data?.label}
        </label>
      ) : (
        <></>
      )}
      <input
        id={data?.controlId || generatedControlId}
        name={data?.name}
        type={data?.type}
        max={data?.max}
        disabled={data.disabled}
        maxLength={data?.maxLength}
        minLength={data?.minLength}
        min={data?.min}
        value={data?.value}
        defaultValue={data?.defaultValue}
        onChange={handleChange}
        onBlur={(e) => handleBlur(e)}
        placeholder={data?.placeholder}
      />

      {data?.errors ? (
        <small className="form-error error-message">{data?.errors}</small>
      ) : error ? (
        <small className="form-error error-message">{error}</small>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InputText;

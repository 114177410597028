import React, { useState, useEffect } from "react";
import SearchIcon from "../../assets/images/search-icon.svg";
const SearchInput = ({ onChange, ...data }) => {
  // const [error, setError] = useState("");
  const [generatedControlId, setGeneratedControlId] = useState("");

  useEffect(() => {
    if (!data?.controlId) {
      const generatedId = `input-${Math.random().toString(36).substr(2, 10)}`;
      setGeneratedControlId(generatedId);
    }
  }, [data?.controlId]);

  // const handleBlur = (e) => {
  //   if (!e.target.value && data?.required) {
  //     if (data?.label) {
  //       setError(`${data?.label} is required`);
  //     } else {
  //       setError("This field is required.");
  //     }
  //   } else {
  //     setError("");
  //   }
  // };
  const handleChange = (e) => {
    if (typeof onChange === "function") {
      onChange(e);
    }
    if (e.target.value === "") {
      data?.onClear && data.onClear();  
    }
    // if (data?.numbers && isNaN(e.target.value)) {
    //   setError("Field must be a number");
    // } else {
    //   setError("");
    // }
  };

  return (
    <div className={`search-input-wrapper ${data?.className}`}>
      {data?.label ? (
        <label htmlFor={data?.controlId || generatedControlId}>
          {data?.label}
        </label>
      ) : (
        <></>
      )}
      <div className="search-input">
        <img
          className={data?.icon === "start" ? "" : "d-none"}
          src={SearchIcon}
          alt=""
        />
        <input
          id={data?.controlId || generatedControlId}
          name={data?.name}
          type="text"
          value={data?.value}
          disabled={data?.disabled}
          onChange={handleChange}
          // onBlur={(e) => handleBlur(e)}
          placeholder={data?.placeholder}
          className={`${data?.inputStyle ? data?.inputStyle : null}`}
          style={data?.width ? { width: `${data.width}px` } : {}}
        />
        <img
          className={`link ${data?.icon === "end" ? "" : "d-none"}`}
          onClick={data?.onClick}
          src={SearchIcon}
          alt=""
        />
      </div>
    </div>
  );
};

export default SearchInput;

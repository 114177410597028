import React, { useEffect, useState } from "react";
import {
  ExcelDownloadBtn,
  GoBack,
  HeaderText,
  MainLayout,
  PdfDownloadBtn,
  SearchInput,
} from "@/components";
import BlueCheckIcon from "@/assets/images/blue-check-icon.svg";
import RedCrossIcon from "@/assets/images/red-x-icon.svg";
import { Empty, Pagination, Select, Spin } from "antd";
import "./tables.scss";
import { useDispatch, useSelector } from "react-redux";
import { defaultTable, getLabReports, getTable, changePage, getTableRecord, getLabReportsRecord } from "../../../redux/slice/sampleSlice";
import { useLocation, useParams } from "react-router-dom";
import { getCommon } from "../../../redux/slice/sampleSlice";
import { handleExcelDownload, handlePdfDownload } from "../../../services/exportHandlers";
import { downloadFile, getFirstPathSegment } from "../../../services/Utils";
import { ApiEndPoint } from "../../../services/ApiEndpoint";
import { config } from "../../../config/config";


const MATERIAL_TYPE = {
  A: 'Asbestos',
  ND: 'Non-Detect',
  LD: "Lead",
  NF: "Non-Fibrous",
  OF: "Other-Fibrous"
}

function AsbestosTableThree() {
  const location = useLocation()
  const params = useParams()
  const building = location?.state?.building;
  const pathName = getFirstPathSegment(location?.pathname)
  console.log(pathName, "vvsd")
  const url = location.pathname
  const [currentTable, setCurrentTable] = useState(null)
  const [layerTypeOptions, setLayerTypeOptions] = useState({})
  const { tableData, isLoading, totalTableData, currentPage, matl_condition, material_type, exposed_visible } = useSelector((state) => state.sample)
  let dispatch = useDispatch()
  useEffect(() => {
    const parts = url.split("/");
    const table = parts[parts.length - 2];
    console.log(table)


    if (table === 'table-one') {
      setCurrentTable(1)
      dispatch(getTable(1, params?.id, pathName))
    } else if (table === 'table-three') {
      setCurrentTable(3)
      dispatch(getTable(3, params?.id, pathName))
    } else if (table === 'table-two') {
      setCurrentTable(2)
      dispatch(getTable(2, params?.id, pathName))
    } else if (table === 'lab-report') {
      setCurrentTable(4)
      dispatch(getLabReports(params?.id, null, pathName))
    }

  }, [currentPage])


  async function getOptions() {

    let asbestos_type_options = await dispatch(getCommon('asbestos_type_options'))
    let other_fibrous_options = await dispatch(getCommon('other_fibrous_options'))
    let non_fibrous_options = await dispatch(getCommon('non_fibrous_options'))
    setLayerTypeOptions(() => ({
      'A': asbestos_type_options, 'OF': other_fibrous_options, 'NF': non_fibrous_options, 'LD': asbestos_type_options
    }))
  }
  useEffect(() => {
    if (currentTable == 1) {
      // if (tableData?.CATEGORY === 'A') {
      // }
      dispatch(getCommon('material_type'))
      dispatch(getCommon('matl_condition'))
      dispatch(getCommon("exposed_visible"));
      getOptions()
    }
  }, [])

  // const headers = {
  //   1: ["HGA",
  //     "MATERIAL CODE",
  //     "DESCRIPTION/ LOCATION",
  //     "QUANTITY (LF OR SQFT)",
  //     "# OF eib & vivs",
  //     "Photo Frame Number",
  //     "#Results",
  //     "asbestos",
  //     "friable",
  //     "Material Type",
  //     "Condition",
  //     "Damage material exposed & Visible (%)",
  //     "Cause of damage"], 2:
  //     [
  //       'ASB_ID',
  //       'HGA',
  //       'SAMPLE NUMBER',
  //       'MATERIAL DESCRIPTION',
  //       'layer',
  //       'location of sample',
  //       '% asbestos',
  //       'type of asbestos',
  //       'found in',
  //       'friable',
  //       'removed ?'
  //     ],
  //   3: [
  //     "ASB_ID", "SAMPLE NUMBER", "MATERIAL DESCRIPTION", "layer", "location of sample",
  //     "drawing NUMBER", "Percent", "Material", "found in", "reason not sampled", "removed?"
  //   ],
  //   4:
  //     [
  //       "SAMPLE FPLA ID",
  //       "layer",
  //       "HOMOGENEOUS",
  //       "Morphology",
  //       "color",
  //       "ASBESTOS",
  //       "ASBESTOS TYPE",
  //       "abs%",
  //       "Disp. colors",
  //       "Pleochroism",
  //       "BIOF",
  //       "EXT. ANGLE",
  //       "ELONGATION",
  //       "REFR INDEX",
  //       "non-asb fibers"
  //     ]
  // }

  // function createTableData(tables) {
  //   if (currentTable == 1) {
  //     return tables?.map(table => ([
  //       table.ASB_TYPE ?? '--',
  //       table.code ?? '--',
  //       table.SUSPECT_DSCRPTN ?? '--',
  //       table.matlValue ?? '--',
  //       '--',
  //       layerTypeOptions[table?.CATEGORY]?.find((data) => data.id == table?.materials?.MATERIAL)?.description ?? '--',
  //       table?.FRIABLE == 1 ? "YES" : table?.FRIABLE == 0 ? "NO" : "--",
  //       material_type?.find(
  //         (material) => material?.id == table?.MATERIAL_TYPE
  //       )?.description
  //       ??
  //       '--',
  //       matl_condition?.find(
  //         (material) =>
  //           material?.id + "" == table?.MATL_COND_CD
  //       )?.MATL_COND_DESC ?? '--',
  //       exposed_visible?.find(
  //         (data) => data?.id == table?.EXPOSED
  //       )?.description
  //       ?? '--',
  //       table?.DET_DAMAGE == 1 ? 'YES' : table?.DET_DAMAGE == 0 ? "NO" : '--'
  //     ]));
  //   } else if (currentTable == 2) {
  //     return tables?.map(table => ([
  //       table.ASB_ID,
  //       `${table.AREA_ID},${table.SUSPECT_DSCRPTN}` ?? '--',
  //       table.SAMPLE_ID ?? '--',
  //       table.MATL_DESC ?? '--',
  //       table.LAYER_ID ?? '--',
  //       table.SUSPECT_DSCRPTN ?? '--',
  //       table.PERCENT_RANGE ?? '--',
  //       table.MATERIAL ?? '--',
  //       MATERIAL_TYPE[table?.CATEGORY] ?? '--',
  //       Number(table?.FRIABLE == 1) ? "YES" : 'NO',
  //       Number(table?.AREA_ABATED == 1) ? "YES" : 'NO'
  //     ]))

  //   }
  //   else if (currentTable === 3) {
  //     // Flatten the object of arrays into an array of arrays
  //     let flattenedData = [];
  //     Object.keys(tables).forEach(key => {
  //       tables[key].forEach(item => {
  //         flattenedData.push([
  //           key, item?.SAMPLE_ID ?? '--', item?.MATL_DESC ?? '--',
  //           item?.LAYER_ID ?? '--', item?.COMMENTS ?? '--',
  //           '--', item?.PERCENT_RANGE ?? '--', item?.MATERIAL ?? '--',
  //           item?.ASB_MATERIAL ?? '--', '--', '--'
  //         ]);
  //       });
  //     });
  //     return flattenedData;
  //   } else if (currentTable == 4) {
  //     return tables?.map(table => ([
  //       table?.FPLA_ID ?? '--',
  //       table?.LAYER_ID ?? '--',

  //       table?.HOMOG === 'True' ? 'YES' : "NO",

  //       table?.MORPHOLOGY,
  //       table?.TEXTURE,
  //       MATERIAL_TYPE[table?.CATEGORY] ?? '--',
  //       table?.CATEGORY === 'A' ? table?.MATERIAL : '--',
  //       table?.PERCENT_RANGE ?? '--',
  //       table?.PLEOCH ?? '--',
  //       table?.BIOFRING ?? '--',
  //       table?.EXT_ANGLE ?? '--',
  //       table?.ELONG ?? '--',
  //       table?.REF_INDEX ?? '--',
  //       table?.CATEGORY === 'NF' ? table?.MATERIAL : '--'
  //     ]))
  //   }
  // }


  async function handleFile(file) {
    let url
    let type = file === 'xlsx' ? 'excel' : 'pdf'
    if (currentTable == 1 || currentTable == 2 || currentTable == 3) {
      url = `${ApiEndPoint.table}${currentTable}/${params.id}?format=pdf`
    } else if (currentTable == 4) {
      url = `v1/building/results/${params.id}/record?format=${type}&category=${pathName}`
    }
    const fileName = `table-${currentTable !== 4 ? currentTable : 'Lab-reports'}`
    downloadFile(file, url, fileName)
  }





  // async function handleExcel() {
  //   // const localTable = table === 'table-one' ? 1 : table === 'table-two' ? 2 : table === 'table-three' ? 3 : table === 'lab-report' ? 4 : 1
  //   let table
  //   if (currentTable == 1 || currentTable == 2 || currentTable == 3) {
  //     table = await dispatch(getTableRecord(currentTable, params?.id, 'excel'))
  //   } else if (currentTable == 4) {
  //     table = await dispatch(getLabReportsRecord(params?.id, 'excel'))
  //   }

  //   const blob = await table.blob();
  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = "Table.xlsx";
  //   link.click();
  //   // const dataWithHeaders = [headers[currentTable], ...createTableData(table)];
  //   // handleExcelDownload(dataWithHeaders, "Table");
  // }


  useEffect(() => {
    return (() => {
      dispatch(changePage(1));
      dispatch(defaultTable())
    })
  }, [])


  const handlePageChange = (page) => {

    dispatch(changePage(page))

  }

  console.log(tableData, "pop")

  // if (!isLoading) {
  //   return <Spin fullscreen size="large" />
  // }
  // if (tableData?.length < 1) {
  //   <div className="empty">
  //     <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  //   </div>
  // }
  return (
    <MainLayout>
      <div className="table-two-wrapper">
        <HeaderText>
          <GoBack />
          {
            currentTable === 4 ?
              `Lab Report(${totalTableData}) .` :
              `Table ${currentTable} (${totalTableData})·`
          }

          <span className="header-light-text">FJLC {building}</span>
        </HeaderText>
        <div className="table-wrapper">
          <div className="table-outer-header">
            <div className="header-filters">
              <SearchInput
                width={300}
                icon="start"
                placeholder="Search by building no, name, description..."
                className="user-search-input"
              />
              <Select
                style={{ width: 152 }}
                placeholder="Type"
                suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                // onChange={}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                ]}
              />
            </div>
            <div className="header-buttons">
              {/* {
                currentTable !== 3 ?
                  <> */}
              <ExcelDownloadBtn onClick={() => handleFile('xlsx')} />
              <PdfDownloadBtn onClick={() => handleFile('pdf')} />
              {/* </> :
                  ""
              } */}
            </div>
          </div>
          {
            isLoading ?
              <Spin fullscreen size="large" />
              :

              < div className="table-overflow-wrapper">
                {
                  currentTable === 3 ?
                    <table>
                      <thead>
                        <tr>
                          <th style={{ width: "100px" }}>ASB_ID</th>

                          <th style={{ width: "150px" }}>SAMPLE NUMBER</th>
                          <th style={{ width: "100px" }}>MATERIAL DESCRIPTION</th>
                          <th style={{ width: "70px" }}>layer</th>
                          <th style={{ width: "200px" }}>location of sample</th>
                          <th style={{ width: "100px" }}>drawing NUMBER</th>
                          <th style={{ width: "100px" }}>Percent</th>
                          <th style={{ width: "100px" }}>Material</th>
                          <th style={{ width: "100px" }}>found in</th>
                          <th style={{ width: "150px" }}>reason not sampled</th>
                          <th style={{ width: "100px" }}>removed?</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          Object.keys(tableData)?.map((i) => (
                            <tr className="outer-row" key={i}>
                              <td className="outer-cell" colspan="11">
                                <table className="inner-table">
                                  <tbody>
                                    {tableData[i]?.map((j, index) => {
                                      return (
                                        <tr key={index}>
                                          <td style={{ width: "100px" }}>
                                            {index === 0 ? i : ""}
                                          </td>

                                          <td style={{ width: "150px" }}>
                                            {j?.SAMPLE_ID}
                                          </td>
                                          <td style={{ width: "100px" }}>{j?.MATL_DESC}</td>
                                          <td style={{ width: "70px" }}>{j?.LAYER_ID}</td>
                                          <td style={{ width: "200px" }}>

                                            {j?.COMMENTS}
                                          </td>

                                          <td style={{ width: "100px" }}>--</td>
                                          <td style={{ width: "100px" }}>{j?.PERCENT_RANGE ?? '--'}</td>
                                          <td style={{ width: "100px" }}>{j?.MATERIAL ?? '--'}</td>
                                          <td style={{ width: "100px" }}>{j?.ASB_MATERIAL ?? '--'} </td>
                                          <td style={{ width: "150px" }}>-- </td>
                                          <td style={{ width: "100px" }}>-- </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                    : currentTable == 1 ?
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <div className="d-flex align-items-center gap-1">
                                HGA <i class="fi fi-sr-sort"></i>
                              </div>
                            </th>
                            <th>MATERIAL CODE</th>
                            <th>dESCRIPTION/ LOCATION</th>
                            <th>QUANTITY (LF OR SQFT)</th>
                            <th># OF eib & vivs</th>
                            <th>Photo Frame Number</th>
                            <th>#Results</th>
                            <th>asbestos</th>
                            <th>friable</th>
                            <th>Material Type</th>
                            <th>Condition</th>
                            <th>Damage material exposed & Visible (%)</th>
                            <th>Cause of damage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((item) => (
                            <tr key={item}>
                              <td>{item.ASB_TYPE ?? '--'}</td>
                              <td>{item.code ?? '--'}</td>
                              <td style={{ width: "200px" }}>
                                {item?.SUSPECT_DSCRPTN ?? '--'}
                              </td>
                              <td style={{ width: "150px" }}>{item?.matlValue}</td>
                              <td style={{ width: "100px" }}>--</td>
                              <td style={{ width: "150px" }}>{item?.id}</td>
                              <td>{item?.total_result ?? '--'}</td>
                              <td>{layerTypeOptions[item?.CATEGORY]?.find((data) => data.id == item?.materials?.MATERIAL)?.description ?? '--'} {item?.materials?.PERCENT_RANGE ?? '--'}</td>

                              <td>{item?.FRIABLE == 1 ? "YES" : item?.FRIABLE == 0 ? "NO" : "--"} </td>
                              {console.log(material_type, "ASB_HM_MATERIAL")}
                              <td style={{ width: "100px" }}>{
                                material_type?.find(
                                  (material) => material?.id == item?.MATERIAL_TYPE
                                )?.description
                                ??
                                '--'} </td>
                              {/* <td>{item?.MATL_COND_CD ?? '--'}</td> */}

                              <td>{matl_condition?.find(
                                (material) =>
                                  material?.id + "" == item?.MATL_COND_CD
                              )?.MATL_COND_DESC ?? '--'}</td>
                              {console.log(exposed_visible,
                                "ppop"
                              )}
                              <td style={{ width: "200px" }}>{
                                exposed_visible?.find(
                                  (data) => data?.id == item?.EXPOSED
                                )?.description
                                ?? '--'} </td>
                              <td style={{ width: "150px" }}>{item?.DET_DAMAGE == 1 ? 'YES' : item?.DET_DAMAGE == 0 ? "NO" : '--'} </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      : currentTable == 2 ?
                        <table>
                          <thead>
                            <tr>
                              <th>ASB_ID</th>
                              <th>
                                <div className="d-flex align-items-center gap-1">
                                  HGA <i class="fi fi-sr-sort"></i>
                                </div>
                              </th>
                              <th>SAMPLE NUMBER</th>
                              <th>MATERIAL DESCRIPTION</th>
                              <th>layer</th>
                              <th>location of sample</th>
                              <th>% asbestos</th>
                              <th>type of asbestos</th>
                              <th>found in</th>
                              <th>friable</th>
                              <th>removed?</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              tableData?.map((item, i) => (
                                <tr key={i}>
                                  <td>{item?.ASB_ID}</td>
                                  <td style={{ width: "250px" }}>
                                    {item?.AREA_ID},{item?.SUSPECT_DSCRPTN}
                                  </td>
                                  <td style={{ width: "150px" }}>{item?.SAMPLE_ID}</td>
                                  <td style={{ width: "100px" }}>{item?.MATL_DESC}</td>
                                  <td>{item?.LAYER_ID}</td>
                                  <td style={{ width: "200px" }}>
                                    {item?.SUSPECT_DSCRPTN}
                                  </td>
                                  <td>{item?.PERCENT_RANGE ?? '--'}</td>

                                  <td style={{ width: "100px" }}>{item?.MATERIAL ?? '--'}</td>
                                  {/* <td>{MATERIAL_TYPE[item?.CATEGORY]}</td> */}
                                  <td>{item?.ASB_MATERIAL}</td>
                                  <td>

                                    <img src={Number(item?.FRIABLE == 1) ? BlueCheckIcon : RedCrossIcon} alt="" />



                                  </td>

                                  <td>
                                    <img src={(item?.AREA_ABATED) === 'TRUE' ? BlueCheckIcon : RedCrossIcon} alt="" />
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                        : currentTable == 4 ?
                          <>
                            <table>
                              <thead>
                                <tr>
                                  {[
                                    "SAMPLE FPLA ID",
                                    "layer",
                                    "HOMOGENEOUS",
                                    "Morphology",
                                    "color",
                                    "ASBESTOS",
                                    "ASBESTOS TYPE",
                                    "abs%",
                                    "Disp. colors",
                                    "Pleochroism",
                                    "BIOF",
                                    "EXT. ANGLE",
                                    "ELONGATION",
                                    "REFR INDEX",
                                    "non-asb fibers",
                                  ]?.map((i) => (
                                    <th key={i}>{i}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>


                                {
                                  tableData?.length > 1 ?

                                    tableData?.map((item, i) => (
                                      <tr key={i}>
                                        <td>{item?.FPLA_ID ?? '--'}</td>
                                        <td>{item?.LAYER_ID ?? '--'}</td>
                                        <td>
                                          <img src={item?.HOMOG == '1' ? BlueCheckIcon : RedCrossIcon} alt="" />
                                        </td>
                                        <td>{item?.MORPHOLOGY}</td>
                                        <td>{item?.TEXTURE}</td>
                                        <td>{MATERIAL_TYPE[item?.CATEGORY] ?? '--'}</td>
                                        <td>{item?.CATEGORY === 'A' ? item?.MATERIAL : '--'}</td>
                                        <td>{item?.PERCENT_RANGE ?? '--'}</td>
                                        <td>{'--'}</td>
                                        <td>
                                          <img src={item?.PLEOCH == '1' ? BlueCheckIcon : RedCrossIcon} alt="" />
                                        </td>
                                        <td>{item?.BIOFRING ?? '--'}</td>
                                        <td>{item?.EXT_ANGLE ?? '--'}</td>
                                        <td>{item?.ELONG ?? '--'}</td>
                                        <td>{item?.REF_INDEX ?? '--'}</td>
                                        <td>{item?.CATEGORY === 'NF' ? item?.MATERIAL : '--'}</td>
                                      </tr>
                                    ))
                                    :
                                    null
                                }
                              </tbody>
                            </table>

                          </>
                          : null

                }

                {
                  // currentTable == 3 ?
                  // Object.keys(tableData)?.length < 1 :
                  Array.isArray(tableData) && !tableData?.length ?
                    <div className="empty">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                    : Object.keys(tableData)?.length < 1 ?
                      <div className="empty">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                      : ""

                }
              </div>
          }
          {console.log(!isLoading && Array.isArray(tableData) ? (tableData?.length > 0) : (Object.keys(tableData).length > 0) && totalTableData > 10, "----------", "dffdgs")
          }

          {
            !isLoading &&
              //  Array.isArray(tableData) ?
              (tableData?.length > 0)
              // :
              //  (Object.keys(tableData).length > 0)
              && totalTableData > 10
              ? (
                <div className="pagination">
                  <Pagination defaultCurrent={1} total={(totalTableData)} current={currentPage} onChange={handlePageChange} />
                </div>

              )
              : ""}
        </div>
      </div>
    </MainLayout >
  );
}

export default AsbestosTableThree;

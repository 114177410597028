import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePage, getBuildingsAsb, getBuildingsLead } from "../../../../redux/slice/buildingSlice";
import BuildingsTable from "../../../../components/tables/BuildingTable";
import { useLocation } from "react-router-dom";


function BuildingsWithAsbestos() {
  const location = useLocation()
  const pathName = location?.pathname?.split('-').at(-1)
  const category = pathName ? pathName.charAt(0).toUpperCase() + pathName.slice(1) : pathName
  const dispatch = useDispatch()
  const { currentPage } = useSelector((state) => state.building)

  const getBuildingsFunc = async () => {
    // dispatch(getBuildings("certified"))
    if (pathName === 'lead') {
      dispatch(getBuildingsLead(1))
    } else {
      dispatch(getBuildingsAsb(1))
    }
  }
  useEffect(() => {
    return () => {
      // Clean up state when component unmounts
      dispatch(changePage(1));
    };
  }, []);


  useEffect(() => {
    getBuildingsFunc()
  }, [currentPage])



  return (
    <BuildingsTable leadPage={pathName === 'lead' ? 1 : 0} asbPresent={1} buildingType={`Buildings with ${category}`} />
  );
}

export default BuildingsWithAsbestos;

import React, { useEffect, useState } from "react";
import {
  Checkbox,
  GoBack,
  HeaderText,
  InputText,
  MainLayout,
} from "../../../components";
import DetailsCard from "../results/subComponent/detailsCard";
import "./assessment.scss";
import { DatePicker } from "antd";
import CustomModel from "../../../components/modals/customModal";
import { useDispatch, useSelector } from "react-redux";
import { addRemoveAssessmentOnm, getAssessmentOnm, getCommon, getSampleAreas, getSingleAssementDetail } from "../../../redux/slice/assessmentSlice";
import { useLocation, useParams } from "react-router-dom";
import { openNotificationWithIcon } from "../../../services/toast";
import moment from "moment";
function ViewAssessment() {
  let dispatch = useDispatch()
  const location = useLocation()
  const building = location?.state?.building
  const [apiData, setApiData] = useState([])
  const [localAreaId,setLocalAreaId] = useState("")
  const { asb_onm_plan, assessmentDetails, sampleAreas } = useSelector((state) => state.assessment)
  const params = useParams()
  const searchParams = new URLSearchParams(location.search);
  const buildingId = searchParams.get('id');
  function onchangeCheck({ isChecked, event }) {
    let prePayload;
    if (isChecked) {
      prePayload = {
        checked: isChecked,
        ASSESS_LIST_ID: Number(event.target.id),
        ASSESS_ID: params.id
      }
    } else {
      prePayload = {
        checked: isChecked,
        ASSESS_LIST_ID: Number(event.target.id)
      }
    }

    dispatch(addRemoveAssessmentOnm(isChecked, prePayload))
  }
  async function getApiData() {
    let dataa = await dispatch(getAssessmentOnm(params.id))
    setApiData(dataa?.map(item => item?.ASSESS_LIST_ID))
  }

  useEffect(() => {
    dispatch(getCommon('asb_onm_plan'))
    getApiData()

    const assessmentDetailError = dispatch(getSingleAssementDetail(Number(params.id)))

    // if (assessmentDetailError) openNotificationWithIcon("error", assessmentDetailError);

    dispatch(getSampleAreas(buildingId))
    const data = getAreaIDs()
    setLocalAreaId(data)



  }, [])
  function getAreaIDs() {
    if (!sampleAreas || !assessmentDetails?.AREA) {
      return [];
    }
    return sampleAreas
      ?.filter(element => assessmentDetails?.AREA?.includes(element?.id))
      ?.map(element => element?.AREA_ID);

  }
  
  return (
    <MainLayout>
      {/* <CustomModel /> */}
      <div className="assessment-view-wrapper">
        <HeaderText>
          <GoBack />
          Asbestos Attachments · <span className="sample-title">{building}</span>
        </HeaderText>
        <DetailsCard header="General">
          <div className="row">
            <div className="col-4">
              <InputText label="Description" disabled={true} value={assessmentDetails?.DESCRIPTION} />
            </div>
            <div className="col-4">
              <label htmlFor="" className="label">
                Assesment date
              </label>
              <DatePicker size="large" name="INSPECTION_DATE" disabled value={
                assessmentDetails?.INSPECTION_DATE
                  ? moment(assessmentDetails?.INSPECTION_DATE, "YYYY-MM-DD")
                  : null
              } />
            </div>
            {/* <div className="col-4">
              <InputText label="Damage Assesment" value={assessmentDetails?.DAMAGE_TOTAL}/>
            </div> */}
            <div className="col-4 mt-3">
              <InputText label="Damage Assesment" disabled={true} value={assessmentDetails?.DAMAGE_TOTAL} />
            </div>
            <div className="col-4 mt-3">
              <InputText label="Exposure Assesment" disabled={true} value={assessmentDetails?.EXPOSURE_TOTAL} />
            </div>
            <div className="col-4 mt-3">
              <InputText disabled={true} value={localAreaId} label="Area id" />
            </div>
            <div className="col-4 mt-3">
              <InputText disabled={true} label="matl id" />
            </div>
            <div className="col-4 mt-3">
              <InputText disabled={true} label="Assesment index" />
            </div>
          </div>
        </DetailsCard>
        <DetailsCard header="General" className="mt-3">
          <div className="d-flex flex-column gap-2">
            {asb_onm_plan?.map((plan) => (
              <div className="d-flex align-items-center gap-3">
                <Checkbox id={plan?.ASSESS_LIST_ID} onChange={onchangeCheck} checked={apiData?.includes(plan?.ASSESS_LIST_ID)} />{" "}
                <label htmlFor="" className="checkbox-label mb-0">
                  {plan?.ASSESS_LIST_DESC}
                </label>
              </div>
            ))}
          </div>
        </DetailsCard>
      </div>
    </MainLayout>
  );
}

export default ViewAssessment;

import React, { useState } from "react";
import { InputText } from "../../components";
import { setGlobalState } from "../../redux/slice/globalStates";
import { useDispatch } from "react-redux";
import API from "../../services/ApiService";
import { ApiEndPoint } from "../../services/ApiEndpoint";
import { openNotificationWithIcon } from "../../services/toast";


function ResetPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("")

  async function handleForgot() {
    try {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        openNotificationWithIcon("error", "Invalid Email");
        return
      }
      const response = await API.post(ApiEndPoint.forgotPassword, { email });
      dispatch(setGlobalState({ showOTPmodalOpen: true, forgotEmail: email }));
      openNotificationWithIcon("success", 'Password reset email sent. Check your inbox.');

    } catch (error) {
      console.log(error.response.data.message, "error")
      openNotificationWithIcon("error", error?.response?.data?.message);

    }

  }
  return (
    <form className="forgot-password-wrapper" style={{ width: "24rem" }}>
      <h3 className="login-header" style={{ letterSpacing: "1px" }}>
        Forgot Your Password!{" "}
      </h3>
      <p className="login-desc">
        We'll help you reset it and get back on track. Let us know your
        registered Email ID.
      </p>

      <div className="login-input">
        <InputText
          label="Email ID"
          type="email"
          placeholder="Enter your email"
          onChange={(e) => setEmail(e.target.value)}
        />
        {/* {errors.email && <p className="error-message">{errors.email}</p>} */}
      </div>

      {/* <div className="forgot-link">
        <button>Resend Email</button>
      </div> */}
      <div className="login-button">
        <button
          onClick={handleForgot}
          className="primary-button button w-100"
          type="button"
        >
          Send Email
        </button>
      </div>
    </form>
  );
}

export default ResetPassword;

import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    title: {
        fontSize: 20,
        textAlign: 'center',
    },
    section: {
        margin: 10,
        padding: 10,
        fontSize: 12,
    },
    listItem: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginBottom: 5,
    },
    bulletPoint: {
        width: 10,
        fontSize: 12,
    },
    listItemText: {
        fontSize: 12,
        flex: 1,
    },
    debugText: {
        fontSize: 10,
        color: 'red',
        marginBottom: 10,
    },
});

const MyDocument = ({ data }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Text style={styles.title}>NOTICE TO OCCUPANTS</Text>
                <View style={styles.section}>
                    <Text>ASBESTOS CONTAINING MATERIALS (ACM) ARE PRESENT IN THIS BUILDING</Text>

                    {data?.map((item, index) => (
                        <>
                            <View style={styles.listItem} key={index}>
                                <Text style={styles.bulletPoint}>•</Text>
                                <Text style={styles.listItemText}>{item}</Text>
                            </View>
                        </>
                    ))}
                    <Text>UNLESS DISTURBED OR DAMAGED THE ACM WILL NOT RELEASE HARMFUL FIBERS</Text>
                    <Text>ROUTINE HOUSEKEEPING WILL NOT RELEASE HARMFUL FIBERS</Text>
                    <Text>DISTURBANCE OF THESE MATERIALS MAY PRESENT A DANGER BY RELEASING ASBESTOS FIBERS...</Text>
                    <Text>AVOID CREATING ACM DUST</Text>
                    <Text>DO NOT DISTURB ACM</Text>
                    <Text>SUBMIT A DEMAND MAINTENANCE ORDER (DMO) TO REPORT DAMAGED BUILDING COMPONENTS...</Text>
                    <Text>FOR QUESTIONS: CONCERNING ACM CALL 531-6008</Text>
                    <Text>CONCERNING HEALTH CALL 531-4534</Text>
                </View>
            </Page>
        </Document>
    );
};

export default MyDocument;

import React from "react";
import Header from "./header";
import backgroundImg from "@/assets/images/main-layout-bg.svg";
import bottomBackgroundImg from "@/assets/images/bottom-main-layout-img.svg";
import buildingTopBgImg from "@/assets/images/builiding-top-bg-img.svg";
import buildingBottomBgImg from "@/assets/images/building-bottom-bg-img.svg";

function MainLayout({ children, buildingBgImage }) {
  return (
    <>
      <img
        className={`main-layout-background-img ${
          buildingBgImage ? "building-top-bg-img" : ""
        }`}
        src={buildingBgImage ? buildingTopBgImg : backgroundImg}
        alt=""
      />
      <Header />
      <main className="container-fluid">
        <div className="container main-layout">{children}</div>
      </main>
      <img
        className={`bottom-main-layout-background-img ${
          buildingBgImage ? "building-bottom-bg-img" : ""
        }`}
        src={buildingBgImage ? buildingBottomBgImg : bottomBackgroundImg}
        alt=""
      />
    </>
  );
}

export default MainLayout;
